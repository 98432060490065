import React from 'react';
import Header from './Header'
import Header2 from './Header2'
import { ListItems, SecondaryListItems } from './listItems';
import { List } from '@mui/material';
// import { mainListItems,secondaryListItems } from './listItems';

const Reports = () => {
  return (
    <>
      <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 999, marginTop: 0 }}>
        <div style={{ width: '20%' }}>
          <Header />
        </div>
        <div style={{ width: '82%', marginLeft: '18%', textAlign: 'right' }}>
          <Header2 title="Reports" />
        </div>
      </div>
      <div style={{ marginTop: '80px', display: 'flex' }}>
        <div style={{ width: '18%', position: 'fixed' }}>
        {/* {mainListItems} */}
    {/* {secondaryListItems} */}
       <ListItems/>
       <SecondaryListItems/>
      </div>
      <div style={{ width: '82%' }}>
        {/* <Apii /> */}
      </div>
    </div>
    </>
  )
}

export default Reports