import React, { useState, useEffect, useContext } from 'react';
import { config, axios } from '../config/config';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "../assets/css/apii.css";
import { Link } from 'react-router-dom';
import { NetworkContext } from "../Context/NetworkContext";
import { RegionContext } from "../Context/RegionContext";
import { UserContext } from '../Components/UserContext';
import NetworkForm from './NetworkForm';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

function NetworkList() {
  const [key, setKey] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState(null);
  const [id, setId] = useState(null);
   const {t}= useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const { token, updateToken } = useContext(UserContext);
  const networks = useContext(NetworkContext);
  const regions = useContext(RegionContext);

  const handleAddFormClick = (id) => {
    setDialogOpen(true);
    setId(id);
  };

  const handleDataUpdate = (updatedNetworkData) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.userId === updatedNetworkData.userId) {
          return {
            ...item,
            col1: updatedNetworkData.name,
            col2: updatedNetworkData.countryCode,
            col3: updatedNetworkData.countryName,
            col4: updatedNetworkData.regionId,
          };
        }
        return item;
      });

      return updatedData;
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setId(null);
  };

  async function fetchData() {
    try {
      const response = await axios.get('/network?limit=-1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.status === 200) {
        const responseData = response.data;
        console.log(responseData);

        if (Array.isArray(responseData.data)) {
          const mappedData = await Promise.all(responseData.data.map(async (item, index) => {
            console.log("in networklist", responseData.data);

            const userNetwork = regions.find(n => n._id === item.regionId);
            let userNetworkName = userNetwork ? userNetwork.name : "N/a";
            console.log("consoling userNetwoek", userNetworkName);

            const userId = item._id;
            console.log("userID", userId);

            return {
              id: item._id,
              col1: item.name,
              col2: item.countryCode,
              col3: item.countryName,
              col4: userNetworkName,
              userId: userId
            };
          }));

          console.log("consoling mappeddata", mappedData);
          setData(mappedData);
        }
      } else {
        console.error('Error fetching data: Invalid response structure');
      }
    } catch (error) {
      console.log("error fetching user data", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  console.log("consoling data", data);
  const columns = [
  {
    field: 'col1',
    headerName: t("netform.nn"),
    width: 350,
    headerClassName: 'custom-header',
    renderCell: (params) => {
      const id = params.row.id;
      console.log("consoling userlist id", id);

      return (
        <>
        <Link to="#" onClick={() => handleAddFormClick(id)} style={{ textDecoration: 'none', color: 'blue' }}>
        {params.value}
        </Link>
            {/* {dialogOpen && <UserForm onClose={handleDialogClose} id={id} />} */}
        </>
        );
    },
  },
  { field: 'col2', headerName: t("netform.cc"), width: 150, headerClassName: 'custom-header' },
  { field: 'col3', headerName: t("netform.cn"), width: 150, headerClassName: 'custom-header' },
  { field: 'col4', headerName: t("netform.rg"), width: 150, headerClassName: 'custom-header' },
  {
    field: 'actions',
    headerName: t("netform.ac"),
    sortable: false,
    disableExport: true,
    headerClassName: 'custom-header',
    width: 150,
    renderCell: (params) => (
      <>
      <Button
      variant="outlined"
      color="error"
      type='submit'
      onClick={() => handleDeleteRow(params.row.userId)}
      style={{ paddingLeft: '20px', paddingRight: '20px' }}
      >
     {t("netform.dl")}
      </Button>
      </>
      ),
  },
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [transparentContent, setTransparentContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleDeleteRow = async (userId) => {
    setOpenDialog(true);
    setDeleteUserId(userId);
    setTransparentContent(true);
  };

  useEffect(() => {
    const filtered = data.filter((network) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return Object.values(network).some((value) => {
        if (typeof value === 'string') {
          const lowerCaseValue = value.toLowerCase();
          return lowerCaseValue.includes(lowerCaseSearchQuery);
        }
        return false;
      });
    });
    setFilteredData(filtered);
  }, [searchQuery, data]);

  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      try {
        await axios.delete(`/network/${deleteUserId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        const updatedUserList = data.filter((user) => user.userId !== deleteUserId);
        setData(updatedUserList);
      } catch (error) {
        console.error('Error deleting row:', error);
      }
    }
    
    setOpenDialog(false);
    setDeleteUserId(null);
    setTransparentContent(false);
  };
  
  const handleCancelDelete = () => {
    setOpenDialog(false);
    setDeleteUserId(null);
    setTransparentContent(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ allColumns: true }}
          exportCsv
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
    <Box sx={{ mt: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, mr: 1 }}>
        <TextField
          placeholder={t("netform.sn")}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          variant="outlined"
          fullWidth
          size="small"
          sx={{ width: '60%' }}
        />
        <Button type='submit' disableElevation variant="contained" color="primary" onClick={() => handleAddFormClick(null)}>{t("netform.ann")}</Button>
      </Box>

      { dialogOpen && <NetworkForm onClose={handleDialogClose} id={id} updateData={handleDataUpdate} onSuccess={fetchData} />}

      <div style={{ height: "calc(100vh - 200px)"}}>
        <DataGrid key={key} rows={filteredData} columns={columns} 
        slots={{
              toolbar: CustomToolbar,
            }}
        />
      </div>
    </Box>

    <Dialog
    open={openDialog}
    onClose={handleCancelDelete}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">
    Are you sure you want to delete this network?
    </DialogContentText>
    </DialogContent>
    <DialogActions>
    <Button onClick={handleCancelDelete}>No</Button>
    <Button onClick={handleConfirmDelete} autoFocus>
    Yes
    </Button>
    </DialogActions>
    </Dialog>
    </>
    );
}

export default NetworkList