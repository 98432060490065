import React, { useContext, useState, useEffect } from "react";
import { TextField, IconButton, Select, MenuItem, InputAdornment, Radio, RadioGroup, FormControlLabel, InputLabel, Button, Box, Dialog, DialogContent, DialogTitle, Grid, Typography, FormHelperText, FormControl } from "@mui/material";
import { config, axios } from '../config/config';
import Alert from '@mui/material/Alert';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from '@mui/icons-material/Close';
import { NetworkContext } from "../Context/NetworkContext";
import { UserContext } from '../Components/UserContext';
import { RoleContext } from "../Context/RoleContext";
import languagesData from "../languages.json"
import { useTranslation } from 'react-i18next';
import "../assets/css/Form.css";

const UserForm = ({ onClose, id, updateData, onSuccess }) => {
  console.log("consoling id", id);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    lang: "",
    role: "",
    network: ""
  });

  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const languages = languagesData;

  useEffect(() => {
    console.log(id);
    fetchData();
  }, []);

  const { token, updateToken } = useContext(UserContext);
  async function fetchData() {
    const response = await axios.get('/users?limit=-1', {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    try {
      if (response.status === 200) {
        const responseData = response.data;

        if (Array.isArray(responseData.data)) {
          const indexToSet = id - 1;

          if (responseData.data[indexToSet]) {
            const obj = responseData.data[indexToSet];
            console.log("consoling userId", obj.userId);
            console.log("consoling userId", obj.lang);
            setUserId(obj.userId);
            setFormData({
              firstName: obj.firstName,
              lastName: obj.lastName,
              mobile: obj.mobile,
              email: obj.email,
              password: obj.password,
              lang: obj.lang,
              role: obj.role,
              network: obj.network,
              userId: obj.userId
            });
          }
        }
      } else {
        console.error('Error fetching data: Invalid response structure');
      }
    } catch (error) {
      console.log("Error fetching user data", error);
    }
  }


  useEffect(() => {
    fetchData();
  }, []);

  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    lang: "",
    role: "",
    network: ""
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const networks = useContext(NetworkContext);
  const roles = useContext(RoleContext);
  const { t } = useTranslation();
  const [data, setData] = useState(() => fetchData());

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const selectedNetworkId = event.target.value;
    const selectedNetwork = networks.find((network) => network.id === selectedNetworkId);
    console.log(selectedNetwork?.name);
    console.log(selectedNetwork?.id);

    const selectedRoleId = event.target.value;
    const selectedRole = roles.find((role) => role._id === selectedRoleId);
    console.log(selectedRole?.roleName);
    console.log(selectedRole?._id);


    if (!id) {
      if (name === 'firstName') {
        if (!/^[a-zA-Z\s]+$/.test(value)) {
          setErrorMessage((prevState) => ({ ...prevState, [name]: t("userform.error.fstn") }));
        } else {
          setErrorMessage((prevState) => ({ ...prevState, [name]: "" }));
        }
      } else if (name === 'lastName') {
        if (!/^[a-zA-Z\s]+$/.test(value)) {
          setErrorMessage((prevState) => ({ ...prevState, [name]: t("userform.error.lstn") }));
        } else {
          setErrorMessage((prevState) => ({ ...prevState, [name]: "" }));
        }
      } else if (name === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setErrorMessage((prevState) => ({ ...prevState, [name]:t("userform.error.inemail") }));
        } else {
          setErrorMessage((prevState) => ({ ...prevState, [name]: "" }));
        }
      } else if (name === 'mobile') {
        const mobileRegex = /^[0-9]{10}$/;
        if (!mobileRegex.test(value)) {
          setErrorMessage((prevState) => ({ ...prevState, [name]: t("userform.error.imob") }));
        } else {
          setErrorMessage((prevState) => ({ ...prevState, [name]: "" }));
        }
      } else {
        setErrorMessage((prevState) => ({ ...prevState, [name]: "" }));
      }
    } else {
      setErrorMessage((prevState) => ({ ...prevState, [name]: "" }));
    }

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleDialogClose = () => {
    onClose();
  };
  const [selectedRole, setSelectedRole] = useState(null);
  const handleRoleChange = (event) => {
    const selectedRoleId = event.target.value;
    const selectedRole = roles.find((role) => role._id === selectedRoleId);
    setSelectedRole(selectedRole);
    setFormData((prevFormData) => ({
      ...prevFormData,
      role: selectedRole?._id,
      level: selectedRole?.level || 0,
      network: "",
    }));
    setErrorMessage((prevErrorMessage) => ({
      ...prevErrorMessage,
      role: "",
    }));
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = ["firstName", "lastName", "lang", "role", "network"];

    if (!id) {
      requiredFields.push("password");
    }

    let newErrorMessage = { ...errorMessage };
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrorMessage[field] = t("userform.error.frequired")
        // "This field is required";
        hasErrors = true;
      } else {
        newErrorMessage[field] = "";
      }
    });

    if (hasErrors) {
      setErrorMessage(newErrorMessage);
      return;
    }

    let response = null;

    if (id) {
      try {
        response = await axios.put(`/users/${userId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("consoling userId in try catch block", userId);
          console.log("consoling api response", response);
          setSuccess(true);
          setError("");
          setTimeout(() => {
            onClose();
          }, 3000);

          console.log('formData', formData);

          console.log('networks', networks.find((network) => network.id === formData.network));

          let userNetworks = [];
          for(let i=0; i<formData.network.length; i++){
            let nw = networks.find((network) => network.id === formData.network[i]);
            if(nw) {
              userNetworks.push(nw.name);
            }
          }

          updateData({
            name: formData.firstName + ' ' + formData.lastName,
            email: formData.email,
            mobile: formData.mobile,
            network: userNetworks || "N/A",
            lang: formData.lang,
            role: roles.find((role) => role._id === formData.role)?.roleName || "N/A",
            userId
          });
          console.log("updated data in userform", response.data);
        }
      } catch (error) {
        console.log(error);
        console.log("consoling userId in try catch block", userId);
        setError(t("userform.error.seter"));
        setSuccess(false);
        setOpen(true);
      }
    } else {
      try {
        response = await axios.post(`/users`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setOpen(true);
          setError("");
          setSuccess(true);
          setIsSubmitted(true);
          setTimeout(() => {
            onClose();
            onSuccess();
          }, 3000);
          console.log("consoling success", success);
        } else {
          setError(response.data.message);
          setSuccess(false);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
        setError(t("userform.error.seter"));
        setSuccess(false);
        setOpen(true);
      }
    }
  };

  const availableLanguages = ['en', 'pt', 'es',];
  const languageOptions = languages
    .filter(language => availableLanguages.includes(language.code.toLowerCase()))
    .map((language) => (
      <MenuItem key={language.code} value={language.code}>
        {language.name}
      </MenuItem>
    ));

  return (
    <>
      <Dialog maxWidth="sm" open onClose={handleDialogClose}>

        <DialogTitle className="heading">
          {id ? t("userform.uu") :  t("userform.au")}
          <IconButton aria-label="close" onClick={handleDialogClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {error && <Alert severity="error">{error}</Alert>}
        {success && (
          <Alert severity="success">
            {id ? t("userform.uus") : t("userform.uas")}
          </Alert>
        )}
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography className="inputfield" variant="title"> {t("userform.fn")}</Typography>
                <Box marginTop={2}>
                  <TextField
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    fullWidth
                    size="small"
                    className="textfield"
                    error={!!errorMessage.firstName}
                    helperText={errorMessage.firstName}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography className="inputfield" variant="title"> {t("userform.ln")}</Typography>
                <Box marginTop={2}>
                  <TextField
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    // required
                    fullWidth
                    size="small"
                    className="textfield"
                    error={!!errorMessage.lastName}
                    helperText={errorMessage.lastName}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography className="inputfield" variant="title"> {t("userform.em")}</Typography>
                <Box marginTop={2}>
                  <TextField
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    // required
                    fullWidth
                    size="small"
                    className="textfield"
                    autoComplete="username"
                    error={!!errorMessage.email}
                    helperText={errorMessage.email}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography className="inputfield" variant="title"> {t("userform.pwd")}</Typography>
                <Box marginTop={2}>
                  <TextField
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    // required
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    size="small"
                    className="textfield"
                    error={!!errorMessage.password}
                    helperText={errorMessage.password}
                    InputProps={{
                      autoComplete: "current-password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>


              <Grid item xs={10}>
                <Typography className="inputfield" variant="title"> {t("userform.mb")}</Typography>
                <Box marginTop={2}>
                  <TextField
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    // required
                    fullWidth
                    size="small"
                    className="textfield"
                    error={!!errorMessage.mobile}
                    helperText={errorMessage.mobile}
                  />
                </Box>
              </Grid>

              <Grid item xs={10}>
                <Typography className="inputfield" variant="title">
                  {t("userform.lg")}
                </Typography>
                <Box marginTop={2}>
                  <FormControl fullWidth size="small" error={!!errorMessage.lang}>
                    <Select
                      name="lang"
                      value={formData.lang}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="textfield"
                    >
                      {languageOptions}
                    </Select>
                    {!!errorMessage.lang && (
                      <FormHelperText>{errorMessage.lang}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={10}>
                <Typography className="inputfield" variant="title">{t("userform.rl")}</Typography>
                <Box marginTop={2}>
                  <FormControl fullWidth size="small" error={!!errorMessage.role}>
                    <Select
                      name="role"
                      value={formData.role}
                      onChange={handleRoleChange}
                      className="textfield"
                    >
                      <MenuItem value="">Select Role</MenuItem>
                      {roles?.length > 0 && roles.map((role) => (
                        <MenuItem key={role._id} value={role._id}>
                          {role.roleName}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errorMessage.role && (
                      <FormHelperText>{errorMessage.role}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={10}>
                <Box marginTop={2}>
                  <FormControl fullWidth size="small" error={!!errorMessage.network}>
                    <Typography className="inputfield" variant="title" marginBottom={"12px"}>
                    {t("userform.nw")}
                    </Typography>
                    {selectedRole && selectedRole.level >= 30 ? (
                      <Select
                        name="network"
                        value={formData.network}
                        onChange={handleInputChange}
                        size="small"
                        className="textfield"
                      >
                        <MenuItem value="">Select Network</MenuItem>
                        {networks?.length > 0 &&
                          networks.map((network) => (
                            <MenuItem key={network.id} value={network.id}>
                              {network.name}
                            </MenuItem>
                          ))}
                      </Select>
                    ) : (
                      <Select
                        multiple
                        name="network"
                        value={Array.isArray(formData.network) ? formData.network : []}
                        onChange={handleInputChange}
                        className="textfield"
                      >
                        <MenuItem value="">Select Network</MenuItem>
                        {networks?.length > 0 &&
                          networks.map((network) => (
                            <MenuItem key={network.id} value={network.id}>
                              {network.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                    {!!errorMessage.network && (
                      <FormHelperText>{errorMessage.network}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>


            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" marginTop={2}>


                <Button variant="outlined" onClick={handleDialogClose}>
                  {t("userform.cl")}
                </Button>
                <Button variant="contained" type="submit" >
                  {id ? t("userform.up") : t("userform.sub")}
                </Button>
              </Box>
            </Grid>

          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserForm;






