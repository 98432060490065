// import React, { createContext, useState, useEffect } from "react";

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   const storedToken = localStorage.getItem("token"); // Retrieve token from localStorage
//   const [token, setToken] = useState(storedToken); // Set initial token value from localStorage
//   const [userName, setUserName] = useState("");

//   const updateToken = (newToken) => {
//     setToken(newToken);
//     localStorage.setItem("token", newToken); // Store the new token in localStorage
//   };

//   const updateUserName = (name) => {
//     setUserName(name);
//   };

//   useEffect(() => {
//     if (!token) {
//       // Token is null or empty, redirect to login page
//       // window.location.href = "/";
//     }
//   }, [token]);

//   return (
//     <UserContext.Provider value={{ token, updateToken, userName, updateUserName }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const storedToken = localStorage.getItem("token");
  const [token, setToken] = useState(storedToken);
  const [user, setUser] = useState(null); // Initialize user as null

  const updateUser = (newUser) => {
    setUser(newUser);
  };

  const updateToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem("token", newToken);
  };

  useEffect(() => {
    if (!token) {
      // Token is null or empty, redirect to login page
      // window.location.href = "/";
    }
  }, [token]);

  return (
    <UserContext.Provider value={{ token, updateToken, user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};
