import React, { useState, useEffect, useContext } from "react";
import { config, axios } from '../config/config';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Alert from '@mui/material/Alert';
import { UserContext } from '../Components/UserContext';
import { useTranslation } from "react-i18next";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const theme = createTheme();

function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [id, setId] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [validToken, setValidToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { token, updateToken } = useContext(UserContext);
  const [verifiedEmail, setVerifiedEmail] = useState("");
  const [verifiedId, setVerifiedId] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const forgotToken = queryParams.get('forgotToken');
  console.log('forgotToken:', forgotToken);
  const navigate = useNavigate();

  useEffect(() => {
    const errorTimeout = setTimeout(() => {
      setError("");
    }, 3000);

    const successTimeout = setTimeout(() => {
      setSuccess("");
    }, 3000);

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);


  console.log("forgot token,", forgotToken);
  useEffect(() => {
    if (forgotToken) {
      console.log("forgot tokrn", forgotToken)
      handleTokenVerification();
    }
  }, [forgotToken]);

  const handleTokenVerification = () => {
    if (forgotToken) {
      axios.get(`/users/forgot?forgotToken=${forgotToken}`)
        .then((response) => {
          if (response.status === 200) {
            setVerifiedEmail(response.data.email);
            setVerifiedId(response.data._id);
            console.log("token", forgotToken);
            console.log("resppnse", response)
          } else {
            setError("Invalid token");
          }
        })
        .catch((error) => {
          setError("Invalid token");
        });
    } else {
      setError("Forgot token is missing.");
    }
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (forgotToken) {
        const response = await axios.put(`/users/forgot?`, {
          email: verifiedEmail,
          forgotToken: forgotToken,
          password,
          _id: verifiedId
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          setSuccess(`${response.data.message}`);
          setTimeout(() => {
            setSuccess(""); 
            navigate("/"); 
          }, 3000); 
          setTimeout(() => {
            setError("");
          }, 6000);
        }
      } else {
        const response = await axios.post('/users/forgot', { email });
        if (response.status === 200) {
          setSuccess(`${response.data.message}`);
          setTimeout(() => {
            setError("");
          }, 6000);
        }
      }
    } catch (error) {
      console.error("Password reset failed:", error);
      if (error.response && error.response.status === 404) {
        setError("Email not found.");
      } else {
        setError("Email is not valid");
      }
    }
  };
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Header />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {forgotToken ? (
            <Typography component="h1" variant="h5">
              {/* New Password */}
              {t("fp.np")}
            </Typography>
          ) : (
            <Typography component="h1" variant="h5">
              {t("fp.fp")}
            </Typography>
          )}
          {success ? (
            <Alert severity="success">{success}</Alert>
          ) : (
            error && (
              <Alert severity="error">
                {error === 'Email is not Valid' ? 'Email is not Valid' : error}
              </Alert>
            )
          )}
          {forgotToken ? (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="New Password"
                name="password"
                // type="password"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
                InputProps={{
                  autoComplete: "current-password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2, backgroundColor: '#4DA9BD', ':hover': { backgroundColor: 'rgb(70, 172, 194)' } }}
              >
                {/* UPDATE PASSWORD */}
                {t("fp.up")}
              </Button>
            </Box>
          ) : (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                error={!!email && !/^\S+@\S+\.\S+$/.test(email)}
                helperText={!!email && !/^\S+@\S+\.\S+$/.test(email) ? "Invalid email format" : ""}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2, backgroundColor: '#4DA9BD', ':hover': { backgroundColor: 'rgb(70, 172, 194)' } }}
              >
                {t("fp.rl")}
              </Button>
            </Box>
          )}
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/" variant="body2" style={{ textDecoration: 'none' }}>
                {t("fp.bl")}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );

}
export default ForgotPassword