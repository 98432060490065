import React, { useState, useContext, useEffect } from 'react';
import { axios } from '../config/config';
import { useMediaQuery, useTheme } from '@mui/material';
import { IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { ListItems, SecondaryListItems } from './listItems';
import Header2 from './Header2';
import { NetworkContext } from '../Context/NetworkContext';
import { UserContext } from '../Components/UserContext';
import { RoleContext } from '../Context/RoleContext';
import { RegionContext } from '../Context/RegionContext';
import ProfileForm from './ProfileForm';
import { useTranslation } from 'react-i18next';


const Profile = () => {
  const {t}=useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const { token, updateToken } = useContext(UserContext);
  const [networks, setNetworks] = useState(() => fetchNetworkData());
  const [roles, setRoles] = useState(() => fetchRolesData());
  const [regions, setRegions] = useState(() => fetchRegionsData());
  async function fetchNetworkData() {
    try {
      const response = await axios.get('/network?limit=-1', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNetworks(response.data.data);
      console.log("networks", response.data.data)
    } catch (error) {
      console.log('Error in getting networks', error);
    }
  }

  async function fetchRolesData() {
    try {
      const response = await axios.get('/roles?limit=-1', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('API Response:', response);
      setRoles(response.data.data);
      console.log('In roles context', response.data.data);
    } catch (error) {
      console.log('Error in getting roles', error);
    }
  }

  async function fetchRegionsData() {
    try {
      const response = await axios.get('/regions?limit=-1', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("region ", response.data.data)
      setRegions(response.data.data);
      console.log('In region context', response.data.data);
    } catch (error) {
      console.log('Error in getting roles', error);
    }
  }
  console.log("networks", networks)
  console.log("roles api", roles)
  console.log("regions api", regions)
  return (
    <>

      <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 999, marginTop: 0 }}>
        <div style={{ width: '20%' }}>
          <Header />
        </div>
        <div style={{ width: '82%', marginLeft: '18%', textAlign: 'right' }}>
          <Header2 title={t("dropdown.mp")} />
        </div>
      </div>

      <div style={{ marginTop: '80px', display: 'flex' }}>
        <div style={{ width: '18%', position: 'fixed' }}>
          <ListItems />
          <SecondaryListItems />
        </div>
        <div style={{ marginLeft: '18%', flex: 1, overflow: 'auto', height: 'calc(100vh - 130px)' }}>
          <NetworkContext.Provider value={networks}>
            <RoleContext.Provider value={roles}>
              <RegionContext.Provider value={regions}>
                {networks.length > 0 && roles.length > 0 && <ProfileForm />}
              </RegionContext.Provider >
            </RoleContext.Provider >
          </NetworkContext.Provider>
        </div>
      </div>
      <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </>
  )
};

export default Profile;






