import React, { useContext } from 'react';
import { UserContext } from './UserContext';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const { updateToken } = useContext(UserContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        updateToken('');
        navigate('/', { replace: true });
    };
    handleLogout();
    return null;
};

export default Logout;
