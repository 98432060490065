import React from 'react';

const Header = () => {
  return (
    <header>
      <img src="/assets/images/viva_logo.png" alt="Logo" style={{ width: '100px', height: '45px', position: 'absolute', top: '10px', left: '30px' }} />
    </header>
  );
};

export default Header;

