import React, { useContext, useState, useEffect } from "react";
import { TextField, IconButton, Select, MenuItem, Button, Box, Dialog, DialogContent, DialogTitle, Grid, Typography, FormHelperText, FormControl } from "@mui/material";
import { config, axios } from '../config/config';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { NetworkContext } from "../Context/NetworkContext";
import { UserContext } from '../Components/UserContext';
import { RoleContext } from "../Context/RoleContext";
import { RegionContext } from "../Context/RegionContext";
import "../assets/css/Form.css";
import { useTranslation } from 'react-i18next';

const NetworkForm = ({ onClose, id, updateData, onSuccess }) => {
    console.log("consoling id", id);
    const [formData, setFormData] = useState({
        name: "",
        countryCode: "",
        countryName: "",
        regionId: ""
    });

    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        console.log(id);
        fetchData();
    }, []);

    const { token, updateToken } = useContext(UserContext);
    async function fetchData() {
        const response = await axios.get(`/network/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        try {
            if (response.status === 200) {
                const responseData = response.data;

                // if (Array.isArray(responseData.data)) {
                    // const indexToSet = id - 1;

                    if (responseData) {
                        const obj = responseData;
                        console.log("consoling userId", obj._id);
                        setUserId(obj._id);
                        setFormData({
                            name: obj.name,
                            countryCode: obj.countryCode,
                            countryName: obj.countryName,
                            regionId: obj.regionId,
                            userId: obj._id
                        });
                    }
                // }
            } else {
                console.error('Error fetching data: Invalid response structure');
            }
        } catch (error) {
            console.log("Error fetching user data", error);
        }
    }


    useEffect(() => {
        fetchData();
    }, []);

    const [errorMessage, setErrorMessage] = useState({
        name: "",
        countryCode: "",
        countryName: "",
        regionId: ""
    });
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const networks = useContext(NetworkContext);
    const roles = useContext(RoleContext);
    const regions = useContext(RegionContext);
    const [data, setData] = useState(() => fetchData());

    console.log("consoling role context", roles);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        const selectedNetworkId = event.target.value;
        const selectedNetwork = networks.find((network) => network.id === selectedNetworkId);
        console.log(selectedNetwork?.name);
        console.log(selectedNetwork?.id);

        const selectedRoleId = event.target.value;
        const selectedRole = roles.find((role) => role._id === selectedRoleId);
        console.log(selectedRole?.roleName);
        console.log(selectedRole?._id);

        const selectedRegionId = event.target.value;
        const selectedRegion = regions.find((region) => region._id === selectedRegionId);
        console.log(selectedRegion?.name);
        console.log(selectedRegion?._id);
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleDialogClose = () => {
        onClose();
    };

    const [selectedRole, setSelectedRole] = useState(null);
    const handleRoleChange = (event) => {
        const selectedRoleId = event.target.value;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const requiredFields = [
            "name",
            "countryCode",
            "countryName",
            "regionId"
        ];
        const newErrorMessage = { ...errorMessage };
        let hasErrors = false;

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrorMessage[field] = (t("netform.error.frequired"));
                hasErrors = true;
            } else {
                newErrorMessage[field] = "";
            }
        });

        if (hasErrors) {
            setErrorMessage(newErrorMessage);
            return;
        }
        let response = null;

        if (id) {
            try {
                response = await axios.put(
                    `/network/${id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log("userId in try-catch block:", userId);
                console.log("API response:", response);
                if (response.status === 200) {
                    setOpen(true);
                    setError("");
                    // setSuccess("Network Updated successfully");
                    setSuccess(t("netform.error.nus"));

                    setTimeout(() => {
                        setSuccess("");
                        onClose();
                    }, 2000);
                    updateData({
                        name: formData.name,
                        countryCode: formData.countryCode,
                        countryName: formData.countryName,
                        regionId: regions.find((region) => region._id === formData.regionId)?.name || "N/A",
                        userId
                    });
                    console.log("updated data in networkform", response.data);

                    console.log("consoling success", success);
                }
            } catch (error) {
                console.log(error);
                console.log("consoling userId in try catch block", userId);
                setError(t("netform.error.seter"));
                setSuccess(false);
                setOpen(true);
            }
        } else {
            try {
                response = await axios.post(
                    `/network`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (response.status === 200) {
                    setOpen(true);
                    setError("");
                    // setSuccess("Network created successfully");
                    setSuccess(t("netform.error.ncs"));

                    setTimeout(() => {
                        setSuccess("");
                        onSuccess();
                        onClose();
                    }, 2000);

                    console.log("consoling success", success);
                } else {
                    setError(response.data.message);
                    setSuccess(false);
                    setOpen(true);
                }
            } catch (error) {
                console.log(error);
                setError(t("netform.error.seter"));
                setSuccess(false);
                setOpen(true);
            }
        }
    };


    return (
        <>
            <Dialog maxWidth="md" open onClose={handleDialogClose}>

                <DialogTitle className="heading">
                    {id ? t("netform.un") : t("netform.an")}
                    <IconButton aria-label="close" onClick={handleDialogClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                {!id && Object.values(errorMessage).some((error) => error !== "") && (
                    <Box marginTop={2}>
                        <Alert severity="error"> {t("netform.error.rf")}</Alert>
                    </Box>
                )}
                {error && <Alert severity="error">{error}</Alert>}
                {success && (
                    <Alert severity="success">
                        {id ? t("netform.nus") : t("netform.nas")}
                    </Alert>
                )}
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Typography className="inputfield" variant="title"> {t("netform.nn")}</Typography>
                                <Box marginTop={2}>
                                    <TextField
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        fullWidth
                                        size="small"
                                        className="textfield"
                                        error={!!errorMessage.name}
                                        helperText={errorMessage.name}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className="inputfield" variant="title"> {t("netform.cn")}</Typography>
                                <Box marginTop={2}>
                                    <TextField
                                        name="countryName"
                                        value={formData.countryName}
                                        onChange={handleInputChange}
                                        // required
                                        fullWidth
                                        size="small"
                                        className="textfield"
                                        error={!!errorMessage.countryName}
                                        helperText={errorMessage.countryName}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className="inputfield" variant="title"> {t("netform.cc")}</Typography>
                                <Box marginTop={2}>
                                    <TextField
                                        name="countryCode"
                                        value={formData.countryCode}
                                        onChange={handleInputChange}
                                        // required
                                        fullWidth
                                        size="small"
                                        className="textfield"
                                        error={!!errorMessage.countryCode}
                                        helperText={errorMessage.countryCode}
                                    />
                                </Box>
                            </Grid>



                            <Grid item xs={8}>
                                <Typography className="inputfield" variant="title"> {t("netform.rg")}</Typography>
                                <Box marginTop={2}>
                                    <FormControl fullWidth size="small" error={!!errorMessage.regionId}>
                                        <Select
                                            name="regionId"
                                            value={formData.regionId}
                                            // onChange={handleRoleChange}
                                            onChange={handleInputChange}
                                            className="textfield"
                                        >
                                            <MenuItem value="">Select Region</MenuItem>
                                            {regions?.length > 0 && regions.map((region) => (
                                                <MenuItem key={region._id} value={region._id}>
                                                    {region.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {!!errorMessage.regionId && (
                                            <FormHelperText>{errorMessage.regionId}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                            </Grid>

                        </Grid>

                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" marginTop={2}>


                                <Button variant="outlined" onClick={handleDialogClose}>
                                    {t("netform.cl")}
                                </Button>
                                <Button variant="contained" type="submit" >
                                    {id ? t("netform.up") : t("netform.sub")}
                                </Button>
                            </Box>
                        </Grid>

                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default NetworkForm