import React, { useState, useContext, useEffect } from "react";
import { config, axios } from '../config/config';
import {
  CssBaseline,
  Typography,
  Alert,
  Container,
  Card,
  CardContent,
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Link,
  FormControl,
  Grid,
  InputAdornment, 
  IconButton
} from '@mui/material';

import Header from "../Components/Header";
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Languageoption from '../Components/Language';
import { useTranslation } from 'react-i18next'
import i18next from "i18next"
import { UserContext } from '../Components/UserContext';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Login() {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [idError, setIdError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [idErrorText, setIdErrorText] = useState("");
  const [pwdErrorText, setPwdErrorText] = useState("");
  const [initIdentifier, setInitIdentifier] = useState(false);
  const [initPassword, setInitPassword] = useState(false);
  const navigate = useNavigate();
  const { token, updateToken } = useContext(UserContext);
  const [error, setError] = useState("");
  const emailRegex = /^\S+@\S+\.\S+$/;
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const { t } = useTranslation();

  const handleIdentifierChange = async (e) => {
    setInitIdentifier(false);
    await setIdentifier(e.target.value);
    await handleEmailError();
    await setIdErrorText(displayEmailErrorText());
  }

  const handleEmailError = async () => {
    if (!initIdentifier && (identifier === "" || !emailRegex.test(identifier))) {
      await setIdError(true);
    }
    else {
      if (password) {
        console.log('why here too')
        setDisabled(false);
      }
      await setIdError(false);
    }
    console.log('initIdentifier', initIdentifier);
    console.log('identifier', identifier);
    console.log('regEx test', emailRegex.test(identifier));
    console.log('idError', idError);
  };



  const displayEmailErrorText = () => {
    console.log('d initIdentifier', initIdentifier, ' identifier ', identifier);
    if (!initIdentifier && identifier == '') {
      return t("login.error.email_required");
    }
    else if (!initIdentifier && !emailRegex.test(identifier)) {
      return t("login.error.invalid_email")
    }
    else {
      return "";
    }
  }

  const handlePasswordChange = async (e) => {
    setInitPassword(false);
    await setPassword(e.target.value);
    await handleEmailError();
    await setPwdErrorText(displayPasswordErrorText());
  }

  const handlePasswordError = async () => {
    if (!initPassword && password == '') {
      await setPwdError(true);
    }
    else {
      if (identifier && emailRegex.test(identifier)) {
        console.log("why here!!");
        setDisabled(false);
      }
      await setPwdError(false);
    }
    console.log('pwdError', pwdError);
  };

  const displayPasswordErrorText = () => {
    if (!initPassword && password == '') {
      return t("login.error.password_required")
    }
    else {
      return "";
    }
  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!identifier || !password) {
      return;
    }

    try {
      const response = await axios.post('/auth/login', { identifier, password }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        const token = response.data.token;
        updateToken(token);
        localStorage.setItem("token", response.data.token);
        const userResponse = await axios.get('/auth/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log("userresponse", userResponse.data)
        const preferredLanguage = userResponse.data.lang;
        console.log("prefee lang", preferredLanguage);

        i18next.changeLanguage(preferredLanguage.toLowerCase());
        localStorage.setItem("preferredLanguage", preferredLanguage.toLowerCase());
        const isMobile=window.matchMedia('(max-width: 600px)').matches;
        console.log("is mobile", isMobile);
        if (isMobile) {
          navigate("/m"); 
        } else {
          navigate("/home"); 
        }
      }
    }

    catch (error) {
      console.error("Login failed:", error);
      if (error.response && error.response.status === 401) {
        setError(`Error ${error.response.data.message}`);
        setTimeout(() => {
          setError("");
        }, 6000);
      }
    }
  }
  useEffect(() => {
    const storedLanguage = localStorage.getItem("preferredLanguage");
    if (storedLanguage) {
      i18next.changeLanguage(storedLanguage.toLowerCase());
    }
  }, []);


  return (

    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Header />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Card variant="outlined" sx={{ minWidth: 350 }}>
            <CardContent>

              <Typography component="h1" variant="h5" align="center">
                {t("login.login")}
              </Typography>
              {error && <Alert severity="error">{error}</Alert>}

              <Box sx={{ mt: 1 }}>

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("login.email_address")}
                  name="email"
                  autoComplete="email"
                  value={identifier}
                  onChange={(e) => handleIdentifierChange(e)}
                  onBlur={(e) => handleIdentifierChange(e)}
                  autoFocus
                  error={idError} 
                  helperText={idErrorText} 

                />

                <TextField
                  margin="normal"
                  fullWidth
                  required
                  name="password"
                  label={t("login.password")}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => { handlePasswordChange(e) }}
                  onBlur={(e) => { handlePasswordChange(e) }}
                  error={pwdError}
                  helperText={pwdErrorText}
                  InputProps={{
                    autoComplete: "current-password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={t("login.remember_me")}
                />

                <Button
                  type="submit"
                  disableElevation
                  color="primary"
                  disabled={disabled}
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t("login.login")}
                </Button>
                <Grid container columns={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
                  <Grid item xs align="right">
                    <Link component={RouterLink} to="/forgot" variant="body1" style={{ textDecoration: 'none' }} >
                      {t("login.forgot_password")}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>

        </Box>
      </Container>
      <Footer />
    </>

  );
}

export default Login;