import React from 'react';
import ReactDOM from 'react-dom/client';
import { red, blue } from '@mui/material/colors';
import './assets/css/index.css';
// import theme from './config/theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./i18n";
import App from './App';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3e75a6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    viva: {
      main: "#4DA9BD",
    }
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
