import './assets/css/App.css';
import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Components/Home';
import Login from './Components/Login';
import ForgotPassword from './Components/forgotPassword';
import Activities from './Components/activities';
import Health from './Components/Health';
import { PrivateRoute } from "./Components/PrivateRoute";
import Reports from './Components/Reports';
import Users from './Components/Users';
import { UserProvider } from './Components/UserContext';
import Regions from './Components/Regions';
import Networks from './Components/Networks';
import Profile from './Components/Profile';
import Layout from './Components/Layout';
import Logout from './Components/Logout';

function App() {
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 600px)').matches);

  console.log("Is Mobile:", isMobile);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 600px)').matches);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Suspense fallback={null}>
        <UserProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/home" element={<PrivateRoute isMobile={isMobile}><Home /> </PrivateRoute>} />
              <Route path="/dashboard" element={<PrivateRoute isMobile={isMobile}><Home /> </PrivateRoute>} />
              <Route path="/activities" element={<PrivateRoute isMobile={isMobile}><Activities /></PrivateRoute>} />
              <Route path="/health" element={<PrivateRoute isMobile={isMobile}><Health /></PrivateRoute>} />
              <Route path="/reports" element={<PrivateRoute isMobile={isMobile}><Reports /></PrivateRoute>} />
              <Route path="/users" element={<PrivateRoute isMobile={isMobile}><Users /></PrivateRoute>} />
              <Route path="/regions" element={<PrivateRoute isMobile={isMobile}><Regions /></PrivateRoute>} />
              <Route path="/networks" element={<PrivateRoute isMobile={isMobile}><Networks /></PrivateRoute>} />
              <Route path="/myprofile" element={<PrivateRoute isMobile={isMobile}><Profile /></PrivateRoute>} />
              <Route path="/m" element={<PrivateRoute><Layout/></PrivateRoute>} />
              <Route path="/logout" element={<PrivateRoute><Logout/></PrivateRoute>} />
            </Routes>
          </Router>
        </UserProvider>
      </Suspense>
    </>
  );
}

export default App;