import React, { useState, useEffect, useContext } from 'react';
import { config, axios } from '../config/config';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Box, Grid, Select, MenuItem, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "../assets/css/apii.css";
import { Link } from 'react-router-dom';
import { NetworkContext } from "../Context/NetworkContext";
import { RegionContext } from "../Context/RegionContext";
import { RoleContext } from "../Context/RoleContext";
import { UserContext } from '../Components/UserContext';
import HealthForm from './HealthForm';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

function HealthList() {
  const [key, setKey] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: ''
  });

  // useEffect(() => {
  //   fetchData();
  // }, [filters]);
  useEffect(() => {
    fetchData();
  }, []);

  const { token, updateToken, user } = useContext(UserContext);
  const networks = useContext(NetworkContext);
  const regions = useContext(RegionContext);
  const roles = useContext(RoleContext);
  const navigate = useNavigate();
  const [networkCoordinator, setNetworkCoordinator] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [regionName, setRegionName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [personName, setPersonName] = useState('');
  const [networkConsultant, setNetworkConsultant] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { t } = useTranslation();

  const handleNetworkNameChange = (event) => {
    setNetworkName(event.target.value);
  };
  const handleRegionNameChange = (event) => {
    setRegionName(event.target.value);
  };
  const handleCountryNameChange = (event) => {
    setCountryName(event.target.value);
  };
  const handlePersonNameChange = (event) => {
    setPersonName(event.target.value);
  };
  const handleNetworkConsultantChange = (event) => {
    setNetworkConsultant(event.target.value);
  };
  const handleNetworkCordinatorChange = (event) => {
    setNetworkCoordinator(event.target.value);
  };

  let selRegions = [];

  const handleAddFormClick = (id) => {
    setDialogOpen(true);
    setId(id);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setId(null);
  };

  const handleFilter = () => {
    let queryString = '/health?limit=-1';

    if (networkCoordinator) {
      queryString += `&networkCoordinator_contains=${networkCoordinator}`;
    }
    if (networkName) {
      queryString += `&network_contains=${networkName}`;
    }
    if (regionName) {
      queryString += `&region_contains=${regionName}`;
    }
    if (countryName) {
      queryString += `&country_contains=${countryName}`;
    }
    if (personName) {
      queryString += `&formCompletedBy_contains=${personName}`;
    }
    if (networkConsultant) {
      queryString += `&networkConsultant_contains=${networkConsultant}`;
    }

    console.log('GET request URL:', queryString);

    axios.get(queryString, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          if (Array.isArray(responseData.data)) {
            console.log("in haelthlist", responseData.data);
            const mappedData = responseData.data.map(async (item, index) => {
              // let userNetworks = [];
              // const userNetworks = networks.map((nt) => {
              //   let ntw = networks.find(n => n._id === nt);
              //   if (ntw) {
              //     return ntw.name;
              //   }
              // });

              const userNetworks = networks.find(n => n._id === item.network);

              const userId = item._id;
              console.log("userID", userId);
              const networkName = userNetworks?.name || 'Not Found';

              // if (typeof item.network === 'string') {
              //   const networkData = await fetchNetworkData(item.network);
              //   if (networkData) {
              //     userNetworks.push(networkData.name);
              //   }
              // }

              return {
                id: item._id,
                col1: networkName,
                col2: item.region,
                col3: item.country,
                col4: item.networkCoordinator,
                col5: item.networkConsultant,
                col6: item.formCompletedBy,
                col74: t(item.status).toUpperCase()
              };
            });
            Promise.all(mappedData).then((formattedData) => {
              setData(formattedData);
              setFilteredData(formattedData);
            });
          } else {
            console.error('Error fetching data: Invalid response structure');
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  async function fetchNetworkData(networkId) {
    try {
      const response = await axios.get(`/network/${networkId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('Error fetching network data:', response);
        return null;
      }
    } catch (error) {
      console.error('Error fetching network data:', error);
      return null;
    }
  }

  useEffect(() => {
    console.log("filtered data", filteredData);
  }, [filteredData]);

  const handleDataUpdate = (updatedHealthData) => {
    console.log('updatedHealthData after update', updatedHealthData);
    setData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.userId === updatedHealthData.userId) {
          return {
            ...item,
            col1: updatedHealthData.network,
            col2: updatedHealthData.region,
            col3: updatedHealthData.country,
            col4: updatedHealthData.networkCoordinator,
            col5: updatedHealthData.networkConsultant,
            col6: updatedHealthData.formCompletedBy,
            col74: t(updatedHealthData.status).toUpperCase()
          };
        }
        return item;
      });

      return updatedData;
    });
  };


  const [id, setId] = useState("");

  async function fetchData() {
    try {
      const response = await axios.get('/health?limit=-1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      console.log("onSuccess callback called");
      if (response.status === 200) {
        const responseData = response.data;
        console.log("Response Data:", responseData);

        const responseData2 = response.data.data;
        console.log(responseData);
        console.log(responseData2);
        console.log("onSuccess callback called");
        if (Array.isArray(responseData.data)) {
          console.log('networks', networks);
          const mappedData = await Promise.all(responseData.data.map(async (item, index) => {
              console.log('item .... ', item);
            console.log('item.network',item.network);
            const userNetworks = networks.find(n => n._id === item.network);

            const userId = item._id;
            console.log("userID", userId);
            console.log('userNetworks', userNetworks);
            const networkName = userNetworks?.name || 'Not Found';
            console.log('networkName', networkName);

            return {
              id: item._id,
              col1:  networkName,
              col2:  item.region,
              col3:  item.country,
              col4:  item.networkCoordinator,
              col5:  item.networkConsultant,
              col6:  item.formCompletedBy,
              col7:  item.approvedBy,
              col8:  item.selectedYear,
              col9:  item.city,
              col10: item.situationalMapping,
              col11: item.visionAndMission,
              col12: item.promotionalDescription,
              col13: item.distinctives,
              col14: item.passionateAbout,
              col15: item.strategicPlan,
              col16: item.strategicAreas,
              col17: item.hasNetworkCoordination,
              col18: item.paidStaffCount,
              col19: item.volunteerStaffCount,
              col20: item.staffRolesAndObjectives,
              col21: item.hasSteeringGroup,
              col22: item.hasSteeringGroupMet,
              col23: item.steeringGroupRolesAndObjectives,
              col24: item.hasWorkingGroups,
              col25: item.workingGroupsCount,
              col26: item.organizationMembersCount,
              col27: item.churchMembersCount,
              col28: item.hasLongTermPartnership,
              col29: item.hasConsultancyAgreement,
              col30: item.vivaSupportSatisfaction,
              col31: item.networkConnectionRating,
              col32: item.vivaCoachRelationshipRating,
              col33: item.trainingCourseRating,
              col34: item.vivaSupportReceived,
              col35: item.safeguardingChildProtectionPolicy,
              col36: item.safeguardingChildProtectionPolicyLastUpdated,
              col37: item.financePolicy,
              col38: item.financePolicyLastUpdated,
              col39: item.financesIndependentlyVerified,
              col40: item.financeHealthCheckCompleted,
              col41: item.governancePolicy,
              col42: item.governancePolicyLastUpdated,
              col43: item.legalRegistrationCompliance,
              col44: item.legalRegistrationSituation,
              col45: item.hrPolicy,
              col46: item.hrPolicyLastUpdated,
              col47: item.hasDatabaseOfOrganizations,
              col48: item.hasRecordOfMemberParticipation,
              col49: item.communicationFrequency,
              col50: item.networkMeetingPercentage,
              col51: item.providesTrainingForMemberOrgs,
              col52: item.providesTrainingForNonMemberOrgs,
              col53: item.childProtection,
              col54: item.codeOfConduct,
              col55: item.financialManagement,
              col56: item.finPolicy,
              col57: item.hasSharedActivityDataSheet,
              col58: item.governmentLevelInitiatives,
              col59: item.businessLeadersInitiatives,
              col60: item.churchLeadersInitiatives,
              col61: item.justiceSectorInitiatives,
              col62: item.hasInfluencedPolicies,
              col63: item.influencedPolicies,
              col64: item.bestStory,
              col65: item.totalIncome,
              col66: item.inCountryIncome,
              col67: item.giftsInKind,
              col68: item.fundraisingStrategy,
              col69: item.fundraisingPlan,
              col70: item.fundraisingResponsibility,
              col71: item.staffAndVolunteersCount,
              col72: item.childrenReachedCount,
              col73: item.additionalComments,
              col74: t(item.status).toUpperCase(),
              userId: userId
            };
          }));
          console.log("onSuccess callback called");
          console.log("consoling mappeddata", mappedData);
          setData(mappedData);
        }
      } else {
        console.error('Error fetching data: Invalid response structure');
      }
    } catch (error) {
      console.log("error fetching user data", error);
    }
  }

  console.log("consoling data", data);
  const columns = [
    {
      field: 'col1',
      headerName: t("healthgrid.nw"),
      width: 250,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <>
            <Link to="#" onClick={() => handleAddFormClick(id)} style={{ textDecoration: 'none', color: 'blue' }}>
              {params.value}
            </Link>
          </>
        );
      },
    },
    { field: 'col2', headerName: t("healthgrid.rg"), width: 150, headerClassName: 'custom-header' },
    { field: 'col3', headerName: t("healthgrid.con"), width: 150, headerClassName: 'custom-header' },
    { field: 'col4', headerName: t("healthgrid.cn"), width: 150, headerClassName: 'custom-header' },
    { field: 'col5', headerName: t("healthgrid.csn"), width: 150, headerClassName: 'custom-header' },
    { field: 'col6', headerName: t("healthgrid.pr"), width: 150, headerClassName: 'custom-header' },
    { field: 'col7', headerName: t("healthgrid.ap"), width: 150, headerClassName: 'custom-header' },
    { field: 'col8', headerName: t("healthgrid.sy"), width: 100, headerClassName: 'custom-header' },
    { field: 'col9', headerName: t("healthgrid.cy"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col10', headerName: t("healthgrid.sm"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col11', headerName: t("healthgrid.vm"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col12', headerName: t("healthgrid.pd"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col13', headerName: t("healthgrid.dt"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col14', headerName: t("healthgrid.pa"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col15', headerName: t("healthgrid.sp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col16', headerName: t("healthgrid.sa"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col17', headerName: t("healthgrid.nc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col18', headerName: t("healthgrid.sc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col19', headerName: t("healthgrid.vsc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col20', headerName: t("healthgrid.sro"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col21', headerName: t("healthgrid.sg"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col22', headerName: t("healthgrid.sgm"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col23', headerName: t("healthgrid.sgro"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col24', headerName: t("healthgrid.wg"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col25', headerName: t("healthgrid.wgc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col26', headerName: t("healthgrid.omc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col27', headerName: t("healthgrid.cmc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col28', headerName: t("healthgrid.pt"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col29', headerName: t("healthgrid.ca"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col30', headerName: t("healthgrid.ss"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col31', headerName: t("healthgrid.ncc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col32', headerName: t("healthgrid.cr"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col33', headerName: t("healthgrid.crr"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col34', headerName: t("healthgrid.vsr"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col35', headerName: t("healthgrid.scp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col36', headerName: t("healthgrid.scpp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col37', headerName: t("healthgrid.fp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col38', headerName: t("healthgrid.fpu"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col39', headerName: t("healthgrid.fiv"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col40', headerName: t("healthgrid.fhc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col41', headerName: t("healthgrid.gpp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col42', headerName: t("healthgrid.gpu"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col43', headerName: t("healthgrid.lr"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col44', headerName: t("healthgrid.lrs"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col45', headerName: t("healthgrid.hp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col46', headerName: t("healthgrid.hpu"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col47', headerName: t("healthgrid.doo"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col48', headerName: t("healthgrid.mop"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col49', headerName: t("healthgrid.cf"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col50', headerName: t("healthgrid.nmp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col51', headerName: t("healthgrid.tmo"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col52', headerName: t("healthgrid.tnmo"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col53', headerName: t("healthgrid.cp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col54', headerName: t("healthgrid.coc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col55', headerName: t("healthgrid.fm"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col56', headerName: t("healthgrid.fpp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col57', headerName: t("healthgrid.sad"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col58', headerName: t("healthgrid.gli"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col59', headerName: t("healthgrid.bli"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col60', headerName: t("healthgrid.cli"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col61', headerName: t("healthgrid.jsi"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col62', headerName: t("healthgrid.ip"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col63', headerName: t("healthgrid.ipp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col64', headerName: t("healthgrid.bs"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col65', headerName: t("healthgrid.ti"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col66', headerName: t("healthgrid.ici"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col67', headerName: t("healthgrid.gik"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col68', headerName: t("healthgrid.fs"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col69', headerName: t("healthgrid.funp"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col70', headerName: t("healthgrid.fr"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col71', headerName: t("healthgrid.svc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col72', headerName: t("healthgrid.crc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col73', headerName: t("healthgrid.acc"), width: 150, headerClassName: 'custom-header', filterable: true },
    { field: 'col74', headerName: t("healthgrid.status"), width: 90, headerClassName: 'custom-header', filterable: true },

    {
      field: 'actions',
      headerName: t("healthgrid.ac"),
      sortable: false,
      headerClassName: 'custom-header',
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            color="error"
            type='submit'
            onClick={() => handleDeleteRow(params.row.userId)}
            style={{ paddingLeft: '20px', paddingRight: '20px' }}
          >
            {t("healthgrid.dl")}
          </Button>
        </>
      ),
    },
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);

  const handleDeleteRow = async (userId) => {
    setOpenDialog(true);
    setDeleteUserId(userId);
  };

  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      try {
        await axios.delete(`/health/${deleteUserId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const updatedUserList = data.filter((user) => user.userId !== deleteUserId);
        setData(updatedUserList);
      } catch (error) {
        console.error('Error deleting row:', error);
      }
    }

    setOpenDialog(false);
    setDeleteUserId(null);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setDeleteUserId(null);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ allColumns: true, fileName: "networkhealthcheck", utf8WithBom: true }}
          exportCsv />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Box sx={{ mt: 1 }}>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <Select
                name="networkName"
                value={networkName}
                onChange={handleNetworkNameChange}
                size="small"
                fullWidth
                className="textfield"
                displayEmpty
              >
                <MenuItem value="" style={{ color: 'gray' }}>
                  {t("healthfilter.nn")}
                </MenuItem>
                {user.network.includes('All')
                  ? networks.map((network) => (
                    <MenuItem key={network.id} value={network.id}>
                      {network.name}
                    </MenuItem>
                  ))
                  : user.network.map((networkId) => {
                    const network = networks.find((item) => item.id === networkId);
                    if(network) {
                      return (
                        <MenuItem key={network.id} value={network.id}>
                          {network.name}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>

            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box marginTop={1}>
              <Select
                name="region"
                value={regionName}
                onChange={handleRegionNameChange}
                placeholder={t("healthfilter.rn")}
                size="small"
                fullWidth
                className="textfield"
                displayEmpty
              >
                <MenuItem value="" style={{ color: 'gray' }}>
                  {t("healthfilter.rn")}
                </MenuItem>
                {user.network.includes('All')
                  ? regions.map((region) => (
                    <MenuItem key={region.name} value={region.name}>
                      {region.name}
                    </MenuItem>
                  ))
                 : user.network.map((networkId) => {
                    const network = networks.find((item) => item.id === networkId);
                    if(network && selRegions.indexOf(network.regionId) < 0) {
                      selRegions.push(network.regionId);
                      const region = regions.find((r) => r.regionId === network.regionId);
                      return (
                        <MenuItem key={region.name} value={region.name}>
                          {region.name}
                        </MenuItem>
                      );
                    }
                  })
               }
              </Select>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="countryName"
                value={countryName}
                onChange={handleCountryNameChange}
                placeholder={t("healthfilter.cn")}
                size="small"
                className="textfield"
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1, mr: 1, marginTop: '5px' }}>
              <Button
                type="submit"
                disableElevation
                variant="contained"
                color="primary"
                onClick={() => handleAddFormClick(null)}
              >
                {t("healthfilter.ahc")}
              </Button>
            </Box>
          </Grid>


        </Grid>
        <Grid container spacing={2} mb={1}>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="networkCoordinator"
                value={networkCoordinator}
                onChange={handleNetworkCordinatorChange}
                placeholder={t("healthfilter.nc")}
                size="small"
                className="textfield"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="networkConsultant"
                value={networkConsultant}
                onChange={handleNetworkConsultantChange}
                placeholder={t("healthfilter.ncs")}
                size="small"
                className="textfield"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="person"
                value={personName}
                onChange={handlePersonNameChange}
                placeholder={t("healthfilter.pn")}
                size="small"
                className="textfield"

              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '9px' }}>
              <Button sx={{ width: "120px", marginRight: '20px' }} disableElevation variant="contained" color="primary" onClick={handleFilter}>
                {t("healthfilter.fl")}
              </Button>
            </Box>
          </Grid>

        </Grid>

        {dialogOpen && <HealthForm onClose={handleDialogClose} id={id} updateData={handleDataUpdate} onSuccess={fetchData} />}
        <div style={{ height: "calc(100vh - 250px)" }}>
          {/* <DataGrid key={key} rows={filteredData.length > 0 ? filteredData : data} columns={columns} components={{ Toolbar: GridToolbarExport }} /> */}
          <DataGrid
            key={key}
            rows={filteredData.length > 0 ? filteredData : data}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  col6: false, col7: false, col9: false,
                  col10: false, col11: false, col12: false, col13: false, col14: false, col15: false, col16: false, col17: false, col18: false,
                  col19: false, col20: false, col21: false, col22: false, col23: false, col24: false, col25: false, col26: false, col27: false,
                  col28: false, col29: false, col30: false, col31: false, col32: false, col33: false, col34: false, col35: false, col36: false, col37: false, col38: false, col39: false, col40: false, col41: false, col42: false, col43: false, col44: false, col45: false, col46: false, col47: false, col48: false, col49: false, col50: false, col51: false, col52: false, col53: false, col54: false, col55: false, col56: false, col57: false, col58: false, col59: false, col60: false, col61: false, col61: false, col62: false, col63: false, col64: false, col65: false, col66: false, col67: false, col68: false, col69: false, col70: false, col71: false, col72: false, col73: false
                },
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </div>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Network Health Check?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HealthList;










