import React, { useState, useEffect, useContext } from "react";
import { config, axios } from '../config/config';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EventIcon from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';
import HubIcon from '@mui/icons-material/Hub';
import LogoutIcon from '@mui/icons-material/Logout';
import LanguageIcon from '@mui/icons-material/Language';
import { useNavigate } from "react-router-dom";
import {useTranslation} from 'react-i18next'
import { UserContext } from "./UserContext";

export const ListItems = ({ onItemClick  }) => {
  const navigate = useNavigate();
  const [isActivitiesClicked, setIsActivitiesClicked] = useState(false);
  const [isHealthClicked, setIsHealthClicked] = useState(false);
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [isLogoutClicked, setIsLogoutClicked] = useState(false);
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const { t } = useTranslation();
  const [isMobileLoggedIn, setIsMobileLoggedIn] = useState(false);
  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 600px)').matches;
    const isLoggedIn = "logged";

    setIsMobileLoggedIn(isMobile && isLoggedIn);
  }, []);

  const handleDashboard = () => {
    setIsDashboardClicked(true);
    setIsActivitiesClicked(false);
    setIsHealthClicked(false);
    setIsProfileClicked(false);
    setIsLogoutClicked(false);
    navigate("/home");
  };

  const handleClick = () => {
    setIsDashboardClicked(false);
    setIsActivitiesClicked(true);
    setIsHealthClicked(false);
    setIsProfileClicked(false);
    setIsLogoutClicked(false);

    if (isMobileLoggedIn) {
      onItemClick("Network Activities");
    } else {
      navigate("/activities");
    }
  };

  const handle = () => {
    setIsDashboardClicked(false);
    setIsActivitiesClicked(false);
    setIsHealthClicked(true);
    setIsProfileClicked(false);
    setIsLogoutClicked(false);

    if (isMobileLoggedIn) {
      onItemClick("Network Health Check");
    } else {
      navigate("/health");
    }
  };

  const handleProfile = () => {
    setIsDashboardClicked(false);
    setIsActivitiesClicked(false);
    setIsHealthClicked(false);
    setIsProfileClicked(true);
    setIsLogoutClicked(false);
    onItemClick("My Profile");
  };

  const handleLogout = () => {
    setIsDashboardClicked(false);
    setIsActivitiesClicked(false);
    setIsHealthClicked(false);
    setIsProfileClicked(false);
    setIsLogoutClicked(true);
    navigate("/logout")
  };

  return (
    <React.Fragment>
      {!isMobileLoggedIn ? (
        <ListItemButton
          onClick={handleDashboard}
          style={{ backgroundColor: isDashboardClicked ? "grey" : "inherit" }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t("listitem.Dashboard")} />
        </ListItemButton>
      ) : null}
      <ListItemButton
        onClick={handleClick}
        style={{ backgroundColor: isActivitiesClicked ? "grey" : "inherit" }}
      >
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary={t("listitem.Network Activities")} />
      </ListItemButton>
      <ListItemButton
        onClick={handle}
        style={{ backgroundColor: isHealthClicked ? "grey" : "inherit" }}
      >
        <ListItemIcon>
          <MonitorHeartIcon />
        </ListItemIcon>
        <ListItemText primary={t("listitem.Network Health Check")} />
      </ListItemButton>
      {isMobileLoggedIn ? (
        <>
          <ListItemButton
            onClick={handleProfile}
            style={{ backgroundColor: isProfileClicked ? "grey" : "inherit" }}
          >
            <ListItemIcon>
              <AccountBoxIcon/>
            </ListItemIcon>
            <ListItemText primary={t("listitem.mp")} />
          </ListItemButton>
          <ListItemButton
            onClick={handleLogout}
            style={{ backgroundColor: isLogoutClicked ? "grey" : "inherit" }}
          >
            <ListItemIcon>
              <LogoutIcon/>
            </ListItemIcon>
            <ListItemText primary={t("listitem.lg")} />
          </ListItemButton>
        </>
      ) : null}
    </React.Fragment>
  );
};

export const SecondaryListItems = () => {
  const navigate = useNavigate();
  const [isUsersClicked, setIsUsersClicked] = useState(false);
  const [isRegionsClicked, setIsRegionsClicked] = useState(false);
  const [isNetworksClicked, setIsNetworksClicked] = useState(false);
  const { token, updateToken } = useContext(UserContext);
  const [theUser, setTheUser] = useState({});
  const {t} = useTranslation();

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('response', response);
      setTheUser(response.data);
      console.log('theUser', theUser);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserInfo();
    }
  }, [token]);


  const handleUsers=()=>{
    setIsUsersClicked(true);
    navigate("/users")
  }
  const handleRegions=()=>{
    setIsRegionsClicked(true);
    navigate("/regions")
  }
  const handleNetworks=()=>{
    setIsNetworksClicked(true);
    navigate("/networks")
  }


  if(theUser.level < 20){
    return(
      <React.Fragment>
        <ListSubheader component="div" inset>
          {/* Administration */}
          {t("listitem.Administration")}
        </ListSubheader>
        <ListItemButton onClick={handleRegions} style={{ backgroundColor: isRegionsClicked ? 'grey' : 'inherit' }}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary={t("listitem.Regions")} />
        </ListItemButton>
        <ListItemButton onClick={handleNetworks} style={{ backgroundColor: isNetworksClicked ? 'grey' : 'inherit' }}>
          <ListItemIcon>
            <HubIcon />
          </ListItemIcon>
          <ListItemText primary={t("listitem.Networks")} />
        </ListItemButton>
        <ListItemButton onClick={handleUsers} style={{ backgroundColor: isUsersClicked ? 'grey' : 'inherit' }}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={t("listitem.Users")} />
        </ListItemButton>
      </React.Fragment>
    );
  }
  else {
    return
  }
}