import React, { useContext, useState, useEffect } from "react";
import { TextField, IconButton, Button, Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'; 
import { config, axios } from '../config/config';
import Alert from '@mui/material/Alert';
import { UserContext } from '../Components/UserContext';
import "../assets/css/Form.css";
import { useTranslation } from 'react-i18next'


const RegionsForm = ({ onClose, id, updateData,onSuccess }) => {
  console.log("consoling id", id);
  const [formData, setFormData] = useState({
    name: "",
  });

  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    console.log(id);
    fetchData();
  }, []);

  const { token, updateToken } = useContext(UserContext);
  async function fetchData() {
    const response = await axios.get(`/regions/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    try {
      if (response.status === 200) {
        const responseData = response.data;

        // if (Array.isArray(responseData.data)) {
          // const indexToSet = id - 1; 

          if (responseData) {
            const obj = responseData;
            console.log("consoling userId", obj._id);
            setUserId(obj._id);
            setFormData({
              name: obj.name,
              userId: obj._id
            });
          }
        // }
      } else {
        console.error('Error fetching data: Invalid response structure');
      }
    } catch (error) {
      console.log("Error fetching user data", error);
    }
  }


  useEffect(() => {
    fetchData();
  }, []);

  const [errorMessage, setErrorMessage] = useState({
    name: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(() => fetchData());


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (!id) {    
      if (name === 'firstName') {
        if (!/^[a-zA-Z\s]+$/.test(value)) {
          setErrorMessage((prevState) => ({ ...prevState, [name]:  (t("regForm.error.in")) }));
        } else {
          setErrorMessage((prevState) => ({ ...prevState, [name]: "" }));
        }
      }
    } else {
      setErrorMessage((prevState) => ({ ...prevState, [name]: "" }));
    }

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDialogClose = () => {
    onClose();
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = ["name"];
    const newErrorMessage = { ...errorMessage };
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrorMessage[field] = (t("regForm.error.frequired"));
        hasErrors = true;
      } else {
        newErrorMessage[field] = "";
      }
    });

    if (hasErrors) {
      setErrorMessage(newErrorMessage);
      return;
    }
    let response = null;

    if (id) {
      try {
        response = await axios.put(
          `/regions/${userId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("consoling regionId in try catch block", userId);
          console.log("consoling api response", response);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            onClose();
          }, 3000);
          updateData({ name: formData.name, userId });
        }
      } catch (error) {
        console.log(error);
        console.log("consoling userId in try catch block", userId);
        setError(t("regForm.error.seter"));
        setSuccess(false);
        setOpen(true);
      }
    } else {
      try {
        response = await axios.post(
          `/regions`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setOpen(true);
          setError("");
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            onClose();
            onSuccess();
          }, 3000);
          // updateData({ name: formData.name});
          updateData({ name: formData.name, userId });
          fetchData(); 
          console.log("updatte data", response);

        // }
          console.log("consoling success", success);
        } else {
          setError(response.data.message);
          setSuccess(false);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
        setError(t("regForm.error.seter"));
        setSuccess(false);
        setOpen(true);
      }
    }
  };

  return (
    <>
      <Dialog maxWidth="lg" open onClose={handleDialogClose}>

        <DialogTitle className="heading">
          {id ? t("regForm.ur") : t("regForm.ar")}
          <IconButton aria-label="close" onClick={handleDialogClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {!id && Object.values(errorMessage).some((error) => error !== "") && (
          <Box marginTop={2}>
            <Alert severity="error"> {t("regForm.error.rf")}</Alert>
          </Box>
        )}

        {error && <Alert severity="error">{error}</Alert>}
        {success && (
          <Alert severity="success">
            {id ? t("regForm.rus") : t("regForm.ras") }
          </Alert>
        )}

        <DialogContent style={{ width: '500px', padding: '20px' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="inputfield" variant="title">
                  {/* Enter Region Name */}
                  {t("regForm.rn")}
                </Typography>
                <Box marginTop={2}>
                  <TextField
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    size="small"
                    className="textfield"
                    error={!!errorMessage.name}
                    helperText={errorMessage.name}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Button variant="outlined" onClick={handleDialogClose}>
                {t("regForm.cancel")}
                </Button>
                <Button variant="contained" type="submit">
                  {id ? t("regForm.upd") : t("regForm.submit")}
                </Button>
              </Box>
            </Grid>
          </form>
        </DialogContent>


      </Dialog>
    </>
  );
};

export default RegionsForm






