import React, { useContext, useState, useEffect } from "react";
import { TextField, IconButton, Select, MenuItem, Radio, RadioGroup, FormControlLabel, FormControl, Button, Box, Dialog, DialogContent, DialogTitle, Grid, Typography, FormHelperText } from "@mui/material";
import { config, axios } from '../config/config';
import Alert from '@mui/material/Alert';
import { NetworkContext } from "../Context/NetworkContext";
import { RegionContext } from "../Context/RegionContext";
import { UserContext } from '../Components/UserContext';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next'
import "../assets/css/Form.css";

const ActivityForm = ({ onClose, id, onSuccess, updateData }) => {
  console.log("consoling id", id);
  const [formData, setFormData] = useState({
    network: "",
    name: "",
    startDate: "",
    endDate: "",
    childOutcome: "",
    safeActivity: "",
    thrivingActivity: "",
    learningActivity: "",
    driver: "",
    fundingSource: "",
    actualExpenditure: "",
    estimatedInKindGifts: "",
    volunteerDays: "",
    networkMembers: "",
    nonMembers: "",
    girlsParticipated: "",
    boysParticipated: "",
    womenParticipated: "",
    menParticipated: "",
    womenDelivered: "",
    menDelivered: "",
    adultsInfluence: "",
    childrenWithDisabilities: "",
    networkChange: "",
    bestStory: "",
    person: "",
    country: "",
    region: "",
    city: "",
    consultantname: "",
    coordinatorname: "",
    status: "draft",
    approvedBy: "",
  });
  console.log("formdata 1", formData);

  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [form, setForm] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const networks = useContext(NetworkContext);
  const regions = useContext(RegionContext);
  const [isDraft, setIsDraft] = useState(true);
  const [theUser, setTheUser] = useState({});
  const [level, setLevel] = useState(0);
  const [ui, setUi] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    console.log('the id', id);
    fetchData();
  }, []);

  const { token, updateToken } = useContext(UserContext);
  const [user, setUser] = useState(null);
  async function fetchData() {
    try {
      const authResponse = await axios.get('/auth/me', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (authResponse.status === 200) {
        const authData = authResponse.data;
        setTheUser(authResponse.data);
        setLevel(authData.level);
        console.log('Auth data:', authData.level);
        console.log('Auth data:', authData);
        setUser(authData);

        const response = await axios.get(`/activity/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const responseData = response.data;

          console.log('responseData',responseData);
          // if (Array.isArray(responseData.data)) {
            // const indexToSet = id - 1;

            if (responseData) {
              const obj = responseData;
              console.log("consoling userId", obj._id);
              console.log("consoling obj", obj);
              setUserId(obj._id);

              console.log("obj.startDate", obj.startDate);
              const startDateStr = obj.startDate;
              const startDate = new Date(startDateStr);
              const day = startDate.getDate();
              const month = startDate.getMonth() + 1;
              const year = startDate.getFullYear();

              const formattedStartDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;

              const endDateStr = obj.endDate;
              const endtDate = new Date(endDateStr);
              const da = endtDate.getDate();
              const mont = endtDate.getMonth() + 1;
              const yea = endtDate.getFullYear();
              const formattedendDate = `${yea}-${mont < 10 ? "0" + mont : mont}-${da < 10 ? "0" + da : da}`;
              setFormData({
                network: obj.network,
                name: obj.name,
                startDate: formattedStartDate,
                endDate: formattedendDate,
                childOutcome: obj.childOutcome,
                safeActivity: obj.safeActivity,
                thrivingActivity: obj.thrivingActivity,
                learningActivity: obj.learningActivity,
                driver: obj.driver,
                networkChange: obj.networkChange,
                fundingSource: obj.fundingSource,
                actualExpenditure: obj.actualExpenditure,
                estimatedInKindGifts: obj.estimatedInKindGifts,
                volunteerDays: obj.volunteerDays,
                networkMembers: obj.networkMembers,
                nonMembers: obj.nonMembers,
                girlsParticipated: obj.girlsParticipated,
                boysParticipated: obj.boysParticipated,
                womenParticipated: obj.womenParticipated,
                menParticipated: obj.menParticipated,
                womenDelivered: obj.womenDelivered,
                menDelivered: obj.menDelivered,
                adultsInfluence: obj.adultsInfluence,
                childrenWithDisabilities: obj.childrenWithDisabilities,
                bestStory: obj.bestStory,
                person: obj.person,
                country: obj.country,
                region: obj.region,
                city: obj.city,
                status: obj.status,
                consultantname: obj.consultantname,
                coordinatorname: obj.coordinatorname,
                userId: obj.userId,
                userLevel: obj.userLevel
              });

              console.log("consoling obj formData", formData);
              return obj;
            }
          // }
        } else {
          console.error('Error fetching activity data: Invalid response structure');
        }
      } else {
        console.error('Error fetching auth data: Invalid response structure');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        const authResponse = await axios.get('/auth/me', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (authResponse.status === 200) {
          const authData = authResponse.data;
          console.log('Auth data:', authData);

        } else {
          console.error('Error fetching auth data: Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAuthData();
    fetchData();
  }, []);


  const [errorMessage, setErrorMessage] = useState({
    network: "",
    name: "",
    startDate: "",
    endDate: "",
    childOutcome: "",
    fundingSource: "",
    actualExpenditure: "",
    estimatedInKindGifts: "",
    volunteerDays: "",
    networkMembers: "",
    nonMembers: "",
    girlsParticipated: "",
    boysParticipated: "",
    womenParticipated: "",
    menParticipated: "",
    womenDelivered: "",
    menDelivered: "",
    adultsInfluence: "",
    childrenWithDisabilities: "",
    bestStory: "",
    person: "",
    country: "",
    region: "",
    city: "",
    driver: "",
    consultantname: "",
    coordinatorname: ""
  });

  const [isMobileLoggedIn, setIsMobileLoggedIn] = useState(false);
  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 600px)').matches;
    const isLoggedIn = "logged";

    setIsMobileLoggedIn(isMobile && isLoggedIn);
  }, []);


  const handleDriver = () => {

    console.log('safeActivity', formData.safeActivity);
    console.log('thrivingActivity', formData.thrivingActivity);
    console.log('learningActivity', formData.learningActivity);
    if((formData.safeActivity === "Meetings" || formData.thrivingActivity === "Meetings" || formData.learningActivity === "Meetings") === true) {
      return "Connecting";
    }
    else if (([
              'Anti',
              'Awareness',
              'Being',
              'Child',
              'Groups',
              'Disaster',
              'Mapping'
              ].indexOf(formData.safeActivity) >= 0 || 
            [
              'Parties',
              'Disaster',
              'Empowerment',
              'Strengthening',
              'Flourish',
              'Education',
              'training',
              'Prayer'
              ].indexOf(formData.thrivingActivity) >= 0 || 
            [
              'clubs',
              'Family'
              ].indexOf(formData.learningActivity) >= 0) === true) {

      return 'Cap';
    }
    else if (([
              'Church',
              'Prevention',
              'Quality',
              'Risk',
              'Policies',
              'Why'
              ].indexOf(formData.safeActivity) >= 0 || 
            [
              'discipline',
              'Children',
              'God',
              'workers'
              ].indexOf(formData.thrivingActivity) >= 0 || 
            [
              'Early',
              'MgmtTraining',
              'Schools'
              ].indexOf(formData.learningActivity) >= 0) === true) {

      return 'Cbp';
    }
    else if (( [
              'bearers',
              'Treatment'
              ].indexOf(formData.safeActivity) >= 0 || 
            [
              'streets'
              ].indexOf(formData.thrivingActivity) >= 0 || 
            [
              'Prayer'
              ].indexOf(formData.learningActivity) >= 0) === true) {
      
      return 'Cip';      
    }
    else if (( [
              'Case',
              'Working',
              ].indexOf(formData.safeActivity) >= 0 || 
            [
              'Skills',
              'Savings',
              'Phone',
              'WASH'
              ].indexOf(formData.thrivingActivity) >= 0 || 
            [
              'CAFI',
              'Creative',
              'Linkages',
              'Mentors',
              'Disabilities',
              'Resources',
              'Teachers',
              'Schlgroups'
              ].indexOf(formData.learningActivity) >= 0) === true) {

      return 'Cai';
    }
    else if (( [
              'Homes'
              ].indexOf(formData.thrivingActivity) >= 0 || 
            [
              'Tchtrang'
              ].indexOf(formData.learningActivity) >= 0) === true) {

      return 'Cbi';
    }
    else if (( [
              'Birth'
              ].indexOf(formData.safeActivity) >= 0 || 
            [
              'Support',
              'Families',
              'Rescue'
              ].indexOf(formData.thrivingActivity) >= 0 || 
            [
              'support'
              ].indexOf(formData.learningActivity) >= 0) === true) {

      return 'Car';
    }
  
    console.log('not here!');
  }

  const handledOutcome = (name, value) => {

    if(name == 'childOutcome' && value == "Safe") {
      formData.thrivingActivity = '';
      formData.learningActivity = '';
    }
    else if(name == 'childOutcome' && value == "Thriving") {
      formData.safeActivity = '';
      formData.learningActivity = '';
    }
    else if(name == 'childOutcome' && value == "Learning") {
      formData.thrivingActivity = '';
      formData.safeActivity = '';
    }

    if(['safeActivity','thrivingActivity','learningActivity'].indexOf(name) >= 0) {
      formData.driver = '';
    }

  }


  const handleInputChange = async (event) => {
    console.log('event', event);
    const { name, value } = event.target;

    console.log( 'name=', name, " | value=", value);

    if(name == 'childOutcome') {
      await handledOutcome(name, value);
    }

    if(['safeActivity','thrivingActivity','learningActivity'].indexOf(name) >= 0) {
      formData[name] = value;
      formData.driver = await handleDriver();
    }

    console.log('formData after selection of activity', formData);

    const selectedNetworkId = event.target.value;
    const selectedNetwork = networks.find((network) => network.id === selectedNetworkId);

    if (selectedNetwork) {
      const { regionId } = selectedNetwork;
      const selectedRegion = regions.find((region) => region.regionId === regionId);

      if (selectedRegion) {
        const { name: regionName, country } = selectedRegion;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
          region: regionName,
          country: selectedNetwork?.countryName,
        }));
        console.log("Updated form data:", {
          ...formData,
          [name]: value,
          region: regionName,
          country: selectedNetwork?.countryName,
        });
        setErrorMessage((prevState) => ({ ...prevState, region: '', country: '' }));
      }
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }


    console.log('formData', formData);

    if (
      name === "name" ||
      name === "network" ||
      name === "childOutcome" ||
      name === "fundingSource" ||
      name === "actualExpenditure" ||
      name === "estimatedInKindGifts" ||
      name === "volunteerDays" ||
      name === "networkMembers" ||
      name === "nonMembers" ||
      name === "girlsParticipated" ||
      name === "boysParticipated" ||
      name === "womenParticipated" ||
      name === "menParticipated" ||
      name === "womenDelivered" ||
      name === "menDelivered" ||
      name === "adultsInfluence" ||
      name === "childrenWithDisabilities" ||
      name === "driver" ||
      name === "person" ||
      name === "country" ||
      name === "region" ||
      name === "city" ||
      name === "consultantname" ||
      name === "coordinatorname"
    ) {
      if (!value) {
        setErrorMessage((prevState) => ({ ...prevState, [name]: `${name} is required` }));
      } else {
        setErrorMessage((prevState) => ({ ...prevState, [name]: '' }));
      }
    }
    const numericValue = Number(value);
    if (name === "actualExpenditure" || name === "estimatedInKindGifts" || name === "volunteerDays" || name === "networkMembers" || name === "nonMembers" || name === "girlsParticipated" || name === "boysParticipated" || name === "womenParticipated" || name === "menParticipated" || name === "womenDelivered" || name === "menDelivered") {
      if (isNaN(numericValue) || numericValue < 0) {
        setErrorMessage((prevState) => ({ ...prevState, [name]: "Value cannot be less than 0" }));
      } else {
        setErrorMessage((prevState) => ({ ...prevState, [name]: '' }));
      }
    }

  };

  const handleSaveDraft = async (event) => {
    event.preventDefault();
    saveRecord("draft");
  }

  const startDateStr = formData.startDate;
  const startDate = new Date(startDateStr);

  const day = startDate.getUTCDate();
  const monthIndex = startDate.getUTCMonth();
  const year = startDate.getUTCFullYear();

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthName = monthNames[monthIndex];

  const formattedStartDate = `${day}-${monthName}-${year}`;

  const endDateStr = formData.endDate;
  const endDate = new Date(endDateStr);

  const days = endDate.getUTCDate();
  const monthvalue = endDate.getUTCMonth();
  const years = endDate.getUTCFullYear();

  const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthname = month[monthvalue];

  const formattedEndDate = `${days}-${monthname}-${years}`;

  const handleApprove = async (event) => {
    event.preventDefault();
    formData.approvedBy = user.userId;
    saveRecord("approved");
  }

  const handleReview = async (event) => {
    event.preventDefault();
    saveRecord("review");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    saveRecord("submitted")
  };

  const saveRecord = async (status) => {
    const requiredFields = [
      "network",
      "name",
      "startDate",
      "endDate",
      "person",
      "country",
      "region",
      "city",
      "childOutcome",
      "fundingSource",
      "actualExpenditure",
      "estimatedInKindGifts",
      "volunteerDays",
      "networkMembers",
      "nonMembers",
      "girlsParticipated",
      "boysParticipated",
      "womenParticipated",
      "menParticipated",
      "womenDelivered",
      "menDelivered",
      "adultsInfluence",
      "childrenWithDisabilities",
      "bestStory",
      "consultantname",
      "coordinatorname",
      "driver"
    ]

    const draftRequiredFields = [
      "network",
      "startDate",
      "endDate",
      "person",
      "country",
      "region",
      "city",
      "name",
      "consultantname",
      "coordinatorname"
    ]
    const newErrorMessage = { ...errorMessage };
    let hasErrors = false;

    console.log('formData at saveRecord', formData);

    requiredFields.forEach((field) => {
      if ([undefined, null, ''].indexOf(formData[field]) >= 0 && status !== 'draft') {
        newErrorMessage[field] = t("activityform.error.frequired");
        console.log('field in error - requiredFields', field);
        console.log('formData - field', formData[field]);
        hasErrors = true;
      } else {
        newErrorMessage[field] = "";
      }
    });

    draftRequiredFields.forEach((field) => {
      if ([undefined, null, ''].indexOf(formData[field]) >= 0 && status == 'draft') {
        newErrorMessage[field] = t("activityform.error.frequired");
        console.log('field in error - draftRequiredFields', field);
        hasErrors = true;
      } else {
        newErrorMessage[field] = "";
      }
    });


    if (hasErrors) {
      setErrorMessage(newErrorMessage);
      setError(t("activityform.error.validation"));
      return;
    }
    let response = null;

    formData.status = status;

    console.log('formData payload', formData);
    console.log('id in save', id);

    if (id) {
      try {
        response = await axios.put(
          `/activity/${id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("consoling userId in try catch block", userId);
          console.log("consoling api response", response);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            onClose();
          }, 3000);

          console.log('formData', formData);

          let networkName = networks.find((network) => network.id === formData.network);
          console.log('networkName', networkName);
          networkName = networkName.name;
          updateData({
            id: id,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            name: formData.name,
            region: formData.region,
            networkName: networkName || "N/A",
            country: formData.country,
            person: formData.person,
            status: formData.status,
            userId
          });
          console.log("updated data in activityform", response.data);
        }
      } catch (error) {
        console.log(error);
        console.log("consoling userId in try catch block", userId);
        setError(t("activityform.error.seter"));
        setSuccess(false);
        setOpen(true);
      }
    }
    else {
      try {
        response = await axios.post(
          `/activity`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {

          setOpen(true);
          setError("");
          setSuccess(true);

          if (isMobileLoggedIn) {
            setTimeout(() => {
              setFormData({
                network: "",
                name: "",
                startDate: "",
                endDate: "",
                childOutcome: "",
                driver: "",
                fundingSource: "",
                actualExpenditure: "",
                estimatedInKindGifts: "",
                volunteerDays: "",
                networkMembers: "",
                nonMembers: "",
                networkChange: "",
                girlsParticipated: "",
                boysParticipated: "",
                womenParticipated: "",
                menParticipated: "",
                womenDelivered: "",
                menDelivered: "",
                adultsInfluence: "",
                childrenWithDisabilities: "",
                bestStory: "",
                person: "",
                country: "",
                region: "",
                city: "",
                consultantname: "",
                coordinatorname: "",
                status: "",
                approvedBy: "",
              });
            }, 2000);
          } else {
            setTimeout(() => {
              onSuccess();
              onClose();
            }, 3000);
          }

          console.log("consoling success", success);
        } else {
          setError(response.data.message);
          setSuccess(false);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
        setError(t("activityform.error.seter"));
        setSuccess(false);
        setOpen(true);
      }
    }
  }


  const handleDialogClose = () => {
    onClose();
  }

  const renderForm = () => {
    return (
      <form onSubmit={isDraft ? handleSaveDraft : handleSubmit}>

        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography className="head">
            {t("activityform.Identification")}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">
              {t("activityform.*A1a")}
            </Typography>
            <span className="text">
              <br />
              {t("activityform.ACRONYM")}
            </span>
            <Box marginTop={2}>
              {user && user.network ? (
                <Select
                  name="network"
                  value={formData.network || ""}
                  onChange={handleInputChange}
                  fullWidth
                  size="small"
                  className="textfield"
                  error={!!errorMessage.network}
                  helperText={errorMessage.network}
                >
                  <MenuItem value="">Select Network</MenuItem>
                  {user.network.includes('All')
                    ? networks.map((network) => (
                      <MenuItem key={network.id} value={network.id}>
                        {network.name}
                      </MenuItem>
                    ))
                    : user.network.map((networkId) => {
                      const network = networks.find((item) => item.id === networkId);
                      if(network) {
                        return (
                          <MenuItem key={network.id} value={network.id}>
                            {network.name}
                          </MenuItem>
                        );                        
                      }
                    })}
                </Select>
              ) : (
                <TextField
                  name="network"
                  value={formData.networkId || ""}
                  onChange={handleInputChange}
                  fullWidth
                  size="small"
                  className="textfield"
                  variant="outlined"
                  placeholder={t("activityform.nn")}
                  error={!!errorMessage.network}
                  helperText={errorMessage.network}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A1b")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="region"
                value={formData.region}
                onChange={handleInputChange}
                required
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.region}
                helperText={errorMessage.region}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A1c")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.country}
                helperText={errorMessage.country}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A1d")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                required
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.city}
                helperText={errorMessage.city}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A1e")}</Typography>
            <span className="text"><br />{t("activityform.Name")}</span>
            <Box marginTop={2}>
              <TextField
                name="consultantname"
                value={formData.consultantname}
                onChange={handleInputChange}
                required
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.consultantname}
                helperText={errorMessage.consultantname}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A1f")}</Typography>
            <span className="text"><br />{t("activityform.Name")}</span>
            <Box marginTop={2}>
              <TextField
                name="coordinatorname"
                value={formData.coordinatorname}
                onChange={handleInputChange}
                required
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.coordinatorname}
                helperText={errorMessage.coordinatorname}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A2")}</Typography>
            <span className="text"><br />{t("activityform.Name")}</span>
            <Box marginTop={2}>
              <TextField
                name="person"
                value={formData.person}
                onChange={handleInputChange}
                required
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.person}
                helperText={errorMessage.person}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={18} md={12} lg={8}>
            <Typography className="head">
              {t("activityform.ACTIVITY")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A3")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.name}
                helperText={errorMessage.name}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A4")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="startDate"
                type="date"
                value={formData.startDate}
                onChange={handleInputChange}
                required
                size="small"
                fullWidth
                error={!!errorMessage.startDate}
                helperText={errorMessage.startDate}
              />
            </Box>

          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A5")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="endDate"
                type="date"
                value={formData.endDate}
                onChange={handleInputChange}
                required
                size="small"
                fullWidth
                error={!!errorMessage.endDate}
                helperText={errorMessage.endDate}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A6")}</Typography>
            <span className="text"><br />{t("activityform.Depending")}</span>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.childOutcome}>
                <RadioGroup aria-label="childOutcome" name="childOutcome" value={formData.childOutcome} onChange={handleInputChange}>
                  <FormControlLabel value="Safe" checked={formData.childOutcome === 'Safe' ? true : false} control={<Radio />} label={t("activityform.Safe")} />
                  <FormControlLabel value="Thriving" checked={formData.childOutcome === 'Thriving' ? true : false} control={<Radio />} label={t("activityform.Thriving")} />
                  <FormControlLabel value="Learning" checked={formData.childOutcome === 'Learning' ? true : false} control={<Radio />} label={t("activityform.Learning")} />

                </RadioGroup>
                <FormHelperText>{errorMessage.childOutcome}</FormHelperText>
              </FormControl>
              {formData.childOutcome === 'Safe' && (
                <Grid item xs={12}>
                  <br />
                  <Typography className="inputfield" variant="title">{t("activityform.*A7a")}</Typography>
                  <br />
                  <br />
                  <RadioGroup aria-label="safeActivity" name="safeActivity" value={formData.safeActivity} onChange={handleInputChange}>
                    <FormControlLabel value="Groups" checked={formData.safeActivity === 'Groups' ? true : false} control={<Radio />} label={t("activityform.Groups")} />
                    <FormControlLabel value="Treatment" checked={formData.safeActivity === 'Treatment' ? true : false} control={<Radio />} label={t("activityform.Treatment")} />
                    <FormControlLabel value="Why" checked={formData.safeActivity === 'Why' ? true : false} control={<Radio />} label={t("activityform.Why")} />
                    <FormControlLabel value="Being" checked={formData.safeActivity === 'Being' ? true : false} control={<Radio />} label={t("activityform.Being")} />
                    <FormControlLabel value="Birth" checked={formData.safeActivity === 'Birth' ? true : false} control={<Radio />} label={t("activityform.Birth")} />
                    <FormControlLabel value="Awareness" checked={formData.safeActivity === 'Awareness' ? true : false} control={<Radio />} label={t("activityform.Awareness")} />
                    <FormControlLabel value="Child" checked={formData.safeActivity === 'Child' ? true : false} control={<Radio />} label={t("activityform.Child")} />
                    <FormControlLabel value="Case" checked={formData.safeActivity === 'Case' ? true : false} control={<Radio />} label={t("activityform.Case")} />
                    <FormControlLabel value="Anti" checked={formData.safeActivity === 'Anti' ? true : false} control={<Radio />} label={t("activityform.Anti")} />
                    <FormControlLabel value="Disaster" checked={formData.safeActivity === 'Disaster' ? true : false} control={<Radio />} label={t("activityform.Disaster")} />
                    <FormControlLabel value="Policies" checked={formData.safeActivity === 'Policies' ? true : false} control={<Radio />} label={t("activityform.Policies")} />
                    <FormControlLabel value="Quality" checked={formData.safeActivity === 'Quality' ? true : false} control={<Radio />} label={t("activityform.Quality")} />
                    <FormControlLabel value="Risk" checked={formData.safeActivity === 'Risk' ? true : false} control={<Radio />} label={t("activityform.Risk")} />
                    <FormControlLabel value="Church" checked={formData.safeActivity === 'Church' ? true : false} control={<Radio />} label={t("activityform.Church")} />
                    <FormControlLabel value="Prevention" checked={formData.safeActivity === 'Prevention' ? true : false} control={<Radio />} label={t("activityform.Prevention")} />
                    <FormControlLabel value="Working" checked={formData.safeActivity === 'Working' ? true : false} control={<Radio />} label={t("activityform.Working")} />
                    <FormControlLabel value="Mapping" checked={formData.safeActivity === 'Mapping' ? true : false} control={<Radio />} label={t("activityform.Mapping")} />
                    <FormControlLabel value="bearers" checked={formData.safeActivity === 'bearers' ? true : false} control={<Radio />} label={t("activityform.bearers")} />
                    <FormControlLabel value="Meetings" checked={formData.safeActivity === 'Meetings' ? true : false} control={<Radio />} label={t("activityform.Meetings")} />
                  </RadioGroup>
                </Grid>
              )}

              {formData.childOutcome === 'Thriving' && (
                <Grid item xs={12}>
                  <br />
                  <Typography className="inputfield" variant="title">{t("activityform.*A7b")}</Typography>
                  <br />
                  <br />
                  <RadioGroup aria-label="thrivingActivity" name="thrivingActivity" value={formData.thrivingActivity} onChange={handleInputChange}>
                    <FormControlLabel value="Parties" checked={formData.thrivingActivity === 'Parties' ? true : false} control={<Radio />} label={t("activityform.Parties")} />
                    <FormControlLabel value="Prayer" checked={formData.thrivingActivity === 'Prayer' ? true : false} control={<Radio />} label={t("activityform.Prayer")} />
                    <FormControlLabel value="Rescue" checked={formData.thrivingActivity === 'Rescue' ? true : false} control={<Radio />} label={t("activityform.Rescue")} />
                    <FormControlLabel value="Families" checked={formData.thrivingActivity === 'Families' ? true : false} control={<Radio />} label={t("activityform.Families")} />
                    <FormControlLabel value="streets" checked={formData.thrivingActivity === 'streets' ? true : false} control={<Radio />} label={t("activityform.streets")} />
                    <FormControlLabel value="Strengthening" checked={formData.thrivingActivity === 'Strengthening' ? true : false} control={<Radio />} label={t("activityform.Strengthening")} />
                    <FormControlLabel value="Empowerment" checked={formData.thrivingActivity === 'Empowerment' ? true : false} control={<Radio />} label={t("activityform.Empowerment")} />
                    <FormControlLabel value="Savings" checked={formData.thrivingActivity === 'Savings' ? true : false} control={<Radio />} label={t("activityform.Savings")} />
                    <FormControlLabel value="Phone" checked={formData.thrivingActivity === 'Phone' ? true : false} control={<Radio />} label={t("activityform.Phone")} />
                    <FormControlLabel value="Flourish" checked={formData.thrivingActivity === 'Flourish' ? true : false} control={<Radio />} label={t("activityform.Flourish")} />
                    <FormControlLabel value="Support" checked={formData.thrivingActivity === 'Support' ? true : false} control={<Radio />} label={t("activityform.Support")} />
                    <FormControlLabel value="Skills" checked={formData.thrivingActivity === 'Skills' ? true : false} control={<Radio />} label={t("activityform.Skills")} />
                    <FormControlLabel value="training" checked={formData.thrivingActivity === 'training' ? true : false} control={<Radio />} label={t("activityform.training")} />
                    <FormControlLabel value="Homes" checked={formData.thrivingActivity === 'Homes' ? true : false} control={<Radio />} label={t("activityform.Homes")} />
                    <FormControlLabel value="discipline" checked={formData.thrivingActivity === 'discipline' ? true : false} control={<Radio />} label={t("activityform.discipline")} />
                    <FormControlLabel value="workers" checked={formData.thrivingActivity === 'workers' ? true : false} control={<Radio />} label={t("activityform.workers")} />
                    <FormControlLabel value="Children" checked={formData.thrivingActivity === 'Children' ? true : false} control={<Radio />} label={t("activityform.Children")} />
                    <FormControlLabel value="Disaster" checked={formData.thrivingActivity === 'Disaster' ? true : false} control={<Radio />} label={t("activityform.Disaster")} />
                    <FormControlLabel value="Education" checked={formData.thrivingActivity === 'Education' ? true : false} control={<Radio />} label={t("activityform.Education")} />
                    <FormControlLabel value="WASH" checked={formData.thrivingActivity === 'WASH' ? true : false} control={<Radio />} label={t("activityform.WASH")} />
                    <FormControlLabel value="God" checked={formData.thrivingActivity === 'God' ? true : false} control={<Radio />} label={t("activityform.God")} />
                    <FormControlLabel value="Meetings" checked={formData.thrivingActivity === 'Meetings' ? true : false} control={<Radio />} label={t("activityform.Meetings")} />
                  </RadioGroup>
                </Grid>
              )}

              {formData.childOutcome === "Learning" && (
                <Grid item xs={12}>
                  <br />
                  <Typography className="inputfield" variant="title">{t("activityform.*A7c")}</Typography>
                  <br />
                  <br />
                  <RadioGroup aria-label="learningActivity" name="learningActivity" value={formData.learningActivity} onChange={handleInputChange}>
                    <FormControlLabel value="Early" checked={formData.learningActivity === 'Early' ? true : false} control={<Radio />} label={t("activityform.Early")} />
                    <FormControlLabel value="Schools" checked={formData.learningActivity === 'Schools' ? true : false} control={<Radio />} label={t("activityform.Schools")} />
                    <FormControlLabel value="Resources" checked={formData.learningActivity === 'Resources' ? true : false} control={<Radio />} label={t("activityform.Resources")} />
                    <FormControlLabel value="clubs" checked={formData.learningActivity === 'clubs' ? true : false} control={<Radio />} label={t("activityform.clubs")} />
                    <FormControlLabel value="support" checked={formData.learningActivity === 'support' ? true : false} control={<Radio />} label={t("activityform.support")} />
                    <FormControlLabel value="Creative" checked={formData.learningActivity === 'Creative' ? true : false} control={<Radio />} label={t("activityform.Creative")} />
                    <FormControlLabel value="Teachers" checked={formData.learningActivity === 'Teachers' ? true : false} control={<Radio />} label={t("activityform.Teachers")} />
                    <FormControlLabel value="Tchtrang" checked={formData.learningActivity === 'Tchtrang' ? true : false} control={<Radio />} label={t("activityform.Tchtrang")} />
                    <FormControlLabel value="Disabilities" checked={formData.learningActivity === 'Disabilities' ? true : false} control={<Radio />} label={t("activityform.Disabilities")} />
                    <FormControlLabel value="Mentors" checked={formData.learningActivity === 'Mentors' ? true : false} control={<Radio />} label={t("activityform.Mentors")} />
                    <FormControlLabel value="Linkages" checked={formData.learningActivity === 'Linkages' ? true : false} control={<Radio />} label={t("activityform.Linkages")} />
                    <FormControlLabel value="Family" checked={formData.learningActivity === 'Family' ? true : false} control={<Radio />} label={t("activityform.Family")} />
                    <FormControlLabel value="CAFI" checked={formData.learningActivity === 'CAFI' ? true : false} control={<Radio />} label={t("activityform.CAFI")} />
                    <FormControlLabel value="Schlgroups" checked={formData.learningActivity === 'Schlgroups' ? true : false} control={<Radio />} label={t("activityform.Schlgroups")} />
                    <FormControlLabel value="MgmtTraining" checked={formData.learningActivity === 'MgmtTraining' ? true : false} control={<Radio />} label={t("activityform.MgmtTraining")} />
                    <FormControlLabel value="Meetings" checked={formData.learningActivity === 'Meetings' ? true : false} control={<Radio />} label={t("activityform.Meetings")} />
                  </RadioGroup>
                </Grid>
              )}


            </Box>
          </Grid>

          { (formData.safeActivity || formData.thrivingActivity || formData.learningActivity) && (
          <Grid item xs={12}>
            <br />
            <Typography size="small" className="inputfield" variant="title">
              {t("activityform.*A8")}
            </Typography>
            <br />
            <br />
            {( handleDriver() == 'Connecting' ) &&
            (
              <RadioGroup aria-label="driver" name="driver" value={formData.driver}>
                <FormControlLabel value="Connecting" checked control={<Radio />} label={t("activityform.Conct")} /> 
              </RadioGroup>
            )}

            {( handleDriver() == 'Cap' ) && 

            (
              <RadioGroup aria-label="driver" name="driver" value={formData.driver}>
                <FormControlLabel value="Cap" checked control={<Radio />} label={t("activityform.Cap")} />
              </RadioGroup>
            )}

            {( handleDriver() == 'Cbp' ) && 
            (
              <RadioGroup aria-label="driver" name="driver" value={formData.driver}>
                <FormControlLabel value="Cbp" checked control={<Radio />} label={t("activityform.Cbp")} />
              </RadioGroup>
            )}

            {( handleDriver() == 'Cip' ) &&
            (
              <RadioGroup aria-label="driver" name="driver" value={formData.driver}>
                <FormControlLabel value="Cip" checked control={<Radio />} label={t("activityform.Cip")} />
              </RadioGroup>
            )}

            {( handleDriver() == 'Cai' ) && 
            (
              <RadioGroup aria-label="driver" name="driver" value={formData.driver}>
                <FormControlLabel value="Cai" checked control={<Radio />} label={t("activityform.Cai")} />
              </RadioGroup>
            )}

            {( handleDriver() == 'Cbi' ) && 
            (
              <RadioGroup aria-label="driver" name="driver" value={formData.driver}>
                <FormControlLabel value="Cbi" checked control={<Radio />} label={t("activityform.Cbi")} />
              </RadioGroup>
            )}

            {( handleDriver() == 'Car' ) &&  
            (
              <RadioGroup aria-label="driver" name="driver" value={formData.driver}>
                <FormControlLabel value="Car" checked control={<Radio />} label={t("activityform.Car")} />
              </RadioGroup>
            )}

          </Grid>
        )}


          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A9")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="fundingSource"
                value={formData.fundingSource}
                onChange={handleInputChange}
                size="small"
                required
                fullWidth
                error={!!errorMessage.fundingSource}
                helperText={errorMessage.fundingSource}
              />
            </Box>

          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A10")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="actualExpenditure"
                type="number"
                value={formData.actualExpenditure}
                onChange={handleInputChange}
                size="small"
                required
                fullWidth
                error={!!errorMessage.actualExpenditure}
                helperText={errorMessage.actualExpenditure}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A11")}</Typography>
            <Box marginTop={2}></Box>
            <TextField
              name="estimatedInKindGifts"
              type="number"
              size="small"
              value={formData.estimatedInKindGifts}
              onChange={handleInputChange}
              required
              fullWidth
              error={!!errorMessage.estimatedInKindGifts}
              helperText={errorMessage.estimatedInKindGifts}
            />

          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A12")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="volunteerDays"
                type="number"
                size="small"
                value={formData.volunteerDays}
                onChange={handleInputChange}
                required
                fullWidth
                error={!!errorMessage.volunteerDays}
                helperText={errorMessage.volunteerDays}
              />

            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A13")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="networkMembers"
                type="number"
                size="small"
                value={formData.networkMembers}
                onChange={handleInputChange}
                required
                fullWidth
                error={!!errorMessage.networkMembers}
                helperText={errorMessage.networkMembers}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A14")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="nonMembers"
                type="number"
                value={formData.nonMembers}
                size="small"
                onChange={handleInputChange}
                required
                fullWidth
                error={!!errorMessage.nonMembers}
                helperText={errorMessage.nonMembers}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A15")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="girlsParticipated"
                type="number"
                value={formData.girlsParticipated}
                onChange={handleInputChange}
                size="small"
                required
                fullWidth
                error={!!errorMessage.girlsParticipated}
                helperText={errorMessage.girlsParticipated}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A16")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="boysParticipated"
                type="number"
                value={formData.boysParticipated}
                onChange={handleInputChange}
                size="small"
                required
                fullWidth
                error={!!errorMessage.boysParticipated}
                helperText={errorMessage.boysParticipated}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A17")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="womenParticipated"
                type="number"
                value={formData.womenParticipated}
                onChange={handleInputChange}
                size="small"
                required
                fullWidth
                error={!!errorMessage.womenParticipated}
                helperText={errorMessage.womenParticipated}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A18")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="menParticipated"
                type="number"
                value={formData.menParticipated}
                onChange={handleInputChange}
                size="small"
                required
                fullWidth
                error={!!errorMessage.menParticipated}
                helperText={errorMessage.menParticipated}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A19")} </Typography>
            <Box marginTop={2}>
              <TextField
                name="womenDelivered"
                type="number"
                value={formData.womenDelivered}
                onChange={handleInputChange}
                required
                size="small"
                fullWidth
                error={!!errorMessage.womenDelivered}
                helperText={errorMessage.womenDelivered}
              />

            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A20")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="menDelivered"
                type="number"
                value={formData.menDelivered}
                onChange={handleInputChange}
                size="small"
                required
                fullWidth
                error={!!errorMessage.menDelivered}
                helperText={errorMessage.menDelivered}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography size="small" className="inputfield" variant="title">{t("activityform.*A21a")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.adultsInfluence}>
                <RadioGroup aria-label="activity-type" name="adultsInfluence" value={formData.adultsInfluence} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("activityform.yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("activityform.no")} />


                </RadioGroup>
                <FormHelperText>{errorMessage.adultsInfluence}</FormHelperText>
              </FormControl>
            </Box>
            {formData.adultsInfluence === "Yes" && (
              <Box marginTop={2}>
                <br />
                <Typography size="small" className="inputfield" variant="title">{t("activityform.*A21b")}</Typography>
                <br />
                <br />
                <TextField
                  id="outlined-multiline-flexible"
                  variant="outlined"
                  fullWidth
                  name="networkChange"
                  value={formData.networkChange}
                  onChange={handleInputChange}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography size="small" className="inputfield" variant="title">
              {t("activityform.*A22")}
            </Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.childrenWithDisabilities}>
                <RadioGroup
                  aria-label="childrenWithDisabilities"
                  name="childrenWithDisabilities"
                  value={formData.childrenWithDisabilities}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label={t("activityform.yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("activityform.no")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.childrenWithDisabilities}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography size="small" className="inputfield" variant="title">
              {t("activityform.*A22")}
            </Typography>
            <Box marginTop={2}>
              <RadioGroup
                aria-label="childrenWithDisabilities"
                name="childrenWithDisabilities"
                value={formData.childrenWithDisabilities}
                onChange={handleInputChange}
                error={!!errorMessage.childrenWithDisabilities}
                helperText={errorMessage.childrenWithDisabilities}
              >
                <FormControlLabel value="Yes" control={<Radio />} label={t("activityform.yes")} />
                <FormControlLabel value="No" control={<Radio />} label={t("activityform.no")} />
              </RadioGroup>
              {errorMessage.childrenWithDisabilities && (
                <FormHelperText>{errorMessage.childrenWithDisabilities}</FormHelperText>
              )}
            </Box>
          </Grid> */}


          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("activityform.*A23")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="bestStory"
                value={formData.bestStory}
                onChange={handleInputChange}
                required
                multiline
                rows={4}
                size="small"
                fullWidth
                error={!!errorMessage.bestStory}
                helperText={errorMessage.bestStory}
              />
            </Box>
          </Grid>
        </Grid>

        {console.log(formData)}
        <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: "24px" }}>
          {(['', undefined, theUser?.id].indexOf(formData.userId) >= 0 && ["", "draft", "review"].indexOf(formData.status) >= 0) && (
            <Button variant="outlined" color="primary" onClick={handleSaveDraft}>
              {t("activityform.SAVE")}
            </Button>
          )}

          {(theUser?.id !== formData.userId && ["submitted"].indexOf(formData.status) >= 0) && (
            <Button variant="outlined" color="primary" onClick={handleReview}>
              {t("activityform.rv")}
            </Button>
          )}


          {(['', undefined, theUser?.id].indexOf(formData.userId) >= 0 && ["", "draft", "review"].indexOf(formData.status) >= 0) && (
            <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>
              {/* Submit for Approval */}
              {t("activityform.SUBMIT")}
            </Button>
          )}

          {theUser?.id !== formData.userId && ['submitted', 'review'].indexOf(formData.status) >= 0 && user?.level < formData.userLevel && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleApprove}
            >
              {t("activityform.ap")}
            </Button>
          )}

        </Box>
      </form>
    )
  }

  console.log("formData:", formData);
  return (
    <>
      {isMobileLoggedIn ? (
        renderForm()

      ) : (
        <Dialog maxWidth="md" open onClose={handleDialogClose}>
          <DialogTitle className="heading">
            {id ? t("activityform.Update") : t("activityform.Addactivity")}
            <IconButton aria-label="close" onClick={handleDialogClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          {error && <Alert severity="error">{error}</Alert>}
          {success && (
            <Alert severity="success">
              {id ? t("activityform.aus") : t("activityform.aas")}
            </Alert>
          )}
          <DialogContent>
            {renderForm()}
          </DialogContent>
        </Dialog>
      )}

      {isMobileLoggedIn && <><p>&nbsp;</p> <p>&nbsp;</p> </>}
    </>
  );

};

export default ActivityForm