import React, { useState, useContext } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { ListItems, SecondaryListItems } from './listItems';
import Header2 from './Header2';
import RegionList from './RegionList';
import { useTranslation } from 'react-i18next';


const Regions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  return (
    <>

      <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 999, marginTop: 0 }}>
        <div style={{ width: '20%' }}>
          <Header />
        </div>
        <div style={{ width: '82%', marginLeft: '18%', textAlign: 'right' }}>
          {/* <Header2 title="Regions" /> */}
          <Header2 title= {t("header.rg")}/>
        </div>
      </div>

      <div style={{ marginTop: '80px', display: 'flex' }}>
        <div style={{ width: '18%', position: 'fixed' }}>
          <ListItems />
          <SecondaryListItems />
        </div>
        <div style={{ marginLeft: '18%', flex: 1, overflow: 'auto', height: 'calc(100vh - 80px)' }}>
          <RegionList />
        </div>
      </div>
      <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </>
  )
};

export default Regions