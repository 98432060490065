import Axios from "axios";
import env from "react-dotenv";

const configuration = {
	apiURL: env.API_URL,
	cfgSYear: env.START_YEAR || 2022,
};

console.log("process.env", process.env);
Axios.defaults.baseURL = configuration.apiURL || 'http://localhost:4040/api/v1'

export const config = configuration;
export const axios = Axios;
