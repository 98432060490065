import React, { useContext, useState, useEffect } from "react";
import {
  TextField,
  IconButton,
  Select,
  MenuItem,
  InputAdornment,
  Button,
  Box,
  Grid,
  Typography,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { config, axios } from "../config/config";
import languagesData from "../languages.json"
import Alert from "@mui/material/Alert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NetworkContext } from '../Context/NetworkContext';
import { UserContext } from "../Components/UserContext";
import { RoleContext } from "../Context/RoleContext";
import "../assets/css/Form.css";
import { useTranslation } from "react-i18next";

const ProfileForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    lang: "",
    role: "",
    network: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    lang: "",
    role: "",
    network: ""
  });
  const [success, setSuccess] = useState(false);
  const {t, i18n }=useTranslation();
  const {language, setLanguage} = useState('en');
  const [isValidForm, setIsValidForm] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [id, setId] = useState("");
  const languages = languagesData;
  const networks = useContext(NetworkContext);
  const roles = useContext(RoleContext);

  const availableLanguages = ['en','pt','es',];
  const languageOptions = languages
    .filter(language => availableLanguages.includes(language.code.toLowerCase()))
    .map((language) => (
      <MenuItem key={language.code} value={language.code}>
        {language.name}
      </MenuItem>
    ));

  const [user, setUser] = useState(null);
  async function fetchData() {
    try {
      const authResponse = await axios.get('/auth/me', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (authResponse.status === 200) {
        const authData = authResponse.data;
        const obj = authData;
        setUserId(authData.userId)
        setUser(authData);
        setFormData({
          firstName: obj.firstName,
          lastName: obj.lastName,
          mobile: obj.mobile,
          email: obj.email,
          lang: obj.lang,
          role: obj.role,
          network: obj.network,
          userId: obj.userId,

        });

        const response = await axios.get('/users?limit=-1', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const responseData = response.data;

          if (Array.isArray(responseData.data)) {
            const indexToSet = id - 1;

            if (responseData.data[indexToSet]) {
              const obj = responseData.data[indexToSet];
              console.log("consoling userId", obj._id);
              console.log("consoling obj", obj);
              setUserId(obj._id);

              console.log("obj.startDate", obj.startDate);

              setFormData({
                userId: obj.userId,

              });

              console.log("consoling obj formData", formData);
            }
          }
        } else {
          console.error('Error fetching activity data: Invalid response structure');
        }
      } else {
        console.error('Error fetching auth data: Invalid response structure');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        const authResponse = await axios.get('/auth/me', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (authResponse.status === 200) {
          const authData = authResponse.data;
          console.log('Auth data:', authData);

        } else {
          console.error('Error fetching auth data: Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAuthData();
    fetchData();
  }, []);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [selectedRole, setSelectedRole] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    setErrorMessage((prevErrorMessage) => ({
      ...prevErrorMessage,
      [name]: '',
    }));
  };

  const resetFormData = () => {
    setFormData({
      firstName: user.firstName,
      lastName: user.lastName,
      mobile: user.mobile,
      email: user.email,
      password: "",
      lang: user.lang,
      role: user.role,
      network: user.network,
    });

    setErrorMessage({
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      password: "",
      lang: "",
      role: "",
      network: "",
    });
  };

  const handleCancel = () => {
    resetFormData();
  };

  const handleRoleChange = (event) => {
    const selectedRoleId = event.target.value;
    const selectedRole = roles.find((role) => role._id === selectedRoleId);
    setSelectedRole(selectedRole);
    setFormData((prevFormData) => ({
      ...prevFormData,
      role: selectedRole?._id,
      level: selectedRole?.level || 0,
      network: "",
    }));

    setErrorMessage((prevErrorMessage) => ({
      ...prevErrorMessage,
      role: '',
    }));
  };

  const validateForm = () => {
    let newErrorMessage = { ...errorMessage };
    let hasErrors = false;

    if (!formData.firstName) {
      newErrorMessage.firstName =t("pform.error.frequired")
      hasErrors = true;
    } else {
      newErrorMessage.firstName = "";
    }

    if (!formData.lastName) {
      newErrorMessage.lastName = t("pform.error.frequired")
      hasErrors = true;
    } else {
      newErrorMessage.lastName = "";
    }

    if (!formData.mobile) {
      newErrorMessage.mobile = t("pform.error.frequired")
      hasErrors = true;
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrorMessage.mobile = t("pform.error.im")
      hasErrors = true;
    } else {
      newErrorMessage.mobile = "";
    }

    if (!formData.email) {
      newErrorMessage.email = t("pform.error.frequired")
      hasErrors = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrorMessage.email = t("pform.error.ie")
      hasErrors = true;
    } else {
      newErrorMessage.email = "";
    }

    if (!formData.lang) {
      newErrorMessage.lang = t("pform.error.frequired")
      hasErrors = true;
    } else {
      newErrorMessage.lang = "";
    }

    if (!formData.role) {
      newErrorMessage.role = t("pform.error.frequired")
      hasErrors = true;
    } else {
      newErrorMessage.role = "";
    }

    if (!formData.network) {
      newErrorMessage.network = t("pform.error.frequired")
      hasErrors = true;
    } else {
      newErrorMessage.network = "";
    }

    setErrorMessage(newErrorMessage);
    return !hasErrors;
  };


  const { token, updateToken } = useContext(UserContext);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.password === "") {
      const { password, ...formDataWithoutPassword } = formData;
      setFormData(formDataWithoutPassword);
    }
    const isValid = validateForm();
    setIsValidForm(isValid);

    if (!isValid) {
      setTimeout(() => {
        setIsValidForm(true);
      }, 3000);
      return;
    }
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);

    try {
      const response = await axios.put(`/users/${userId}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status == 200) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        console.log("Updated user information:", response.data);
        console.log("userId inside handlesubmit", id);

        console.log('selected lang',formData.lang);
        i18n.changeLanguage(formData.lang);

        const updatedMeResponse = await axios.get("/auth/me", {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (updatedMeResponse.status === 200) {
          const updatedUserObj = updatedMeResponse.data;
          console.log("Updated logged-in user information:", updatedUserObj);
        } else {
          console.log("Error fetching updated logged-in user information:", updatedMeResponse.data);
        }

      } else {
        console.log("Error updating user information:", response.data);
      }
    } catch (error) {
      console.log("Error updating user information:", error);
    }
  };

  return (
    <>
      {success && (
        <Alert
          severity="success"
          onClose={() => setSuccess(false)}
        >   
          {t("pform.pu")}
        </Alert>
      )}
      {!isValidForm && (
        <Alert
          severity="error"
        >
          {t("pform.error.ff")}
        </Alert>
      )}
      <Grid container >

        <Grid item xs={12} sm={5} mr={2} ml={2} mt={1} >
          <Typography className="inputfield" variant="title">{t("pform.fn")}</Typography>
          <Box marginTop={1}>
            <TextField
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              fullWidth
              size="small"
              className="textfield"
              error={!!errorMessage.firstName}
              helperText={errorMessage.firstName}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} ml={2} mr={2} mt={1} >
          <Typography className="inputfield" variant="title">{t("pform.ln")}</Typography>
          <Box marginTop={1}>
            <TextField
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              fullWidth
              size="small"
              className="textfield"
              error={!!errorMessage.lastName}
              helperText={errorMessage.lastName}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} mr={2} ml={2} mt={1} >
          <Typography className="inputfield" variant="title">{t("pform.em")}</Typography>
          <Box marginTop={1}>
            <TextField
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              size="small"
              className="textfield"
              autoComplete="username"
              error={!!errorMessage.email}
              helperText={errorMessage.email}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} ml={2}mr={2} mt={1} >
          <Typography className="inputfield" variant="title">{t("pform.pswrd")}</Typography>
          <Box marginTop={1}>
            <TextField
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              type={showPassword ? "text" : "password"}
              fullWidth
              size="small"
              className="textfield"
              error={!!errorMessage.password}
              helperText={errorMessage.password}
              InputProps={{
                autoComplete: "current-password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>


        <Grid item xs={12} sm={5} mr={2} ml={2} mt={1} >
          <Typography className="inputfield" variant="title">{t("pform.mob")}</Typography>
          <Box marginTop={1}>
            <TextField
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              fullWidth
              size="small"
              className="textfield"
              error={!!errorMessage.mobile}
              helperText={errorMessage.mobile}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={5} ml={2} mr={2} mt={1} >
          <Typography className="inputfield" variant="title">
           {t("pform.lg")}
          </Typography>
          <Box marginTop={1}>
            <FormControl fullWidth size="small" error={!!errorMessage.lang}>
              <Select
                name="lang"
                value={formData.lang}
                onChange={handleInputChange}
                fullWidth
                size="small"
                className="textfield"
              >
                {languageOptions}
              </Select>
              {!!errorMessage.lang && (
                <FormHelperText>{errorMessage.lang}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5} mr={2} ml={2} mt={1} >
          <Typography className="inputfield" variant="title">{t("pform.rl")}</Typography>
          <Box marginTop={1}>
            <FormControl fullWidth size="small" error={!!errorMessage.role}>
              <Select
                name="role"
                value={formData.role}
                onChange={handleRoleChange}
                className="textfield"
                disabled
              >
                <MenuItem value="">Select Role</MenuItem>
                {roles?.length > 0 && roles.map((role) => (
                  <MenuItem key={role._id} value={role._id}>
                    {role.roleName}
                  </MenuItem>
                ))}
              </Select>
              {!!errorMessage.role && (
                <FormHelperText>{errorMessage.role}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5} ml={2} mr={2} mt={1} >
          <Typography className="inputfield" variant="title">
           {t("pform.nt")}
          </Typography>
          <Box marginTop={1}>
            <FormControl fullWidth size="small" error={!!errorMessage.network}>
              {user && user.network ? (
                <Select
                  multiple={user.level < 30 ? true : false}
                  name="network"
                  value={formData.network || ""}
                  onChange={handleInputChange}
                  fullWidth
                  size="small"
                  className="textfield"
                >
                  <MenuItem value="">Select Network</MenuItem>
                  {user.network.includes('All')
                    ? 
                      networks.map((network) => (
                      <MenuItem key={network.id} value={network.id}>
                        {network.name}
                      </MenuItem>
                    ))
                    : user.network.map((networkId) => {
                      const network = networks.find((item) => item.id === networkId);
                      if(network) {
                        return (
                          <MenuItem key={network.id} value={network.id}>
                            {network.name}
                          </MenuItem>
                        );
                      }
                    })}
                </Select>
              ) : (
                <>
                  <TextField
                    name="network"
                    value={formData.network || ""}
                    onChange={handleInputChange}
                    fullWidth
                    size="small"
                    className="textfield"
                    variant="outlined"
                    placeholder="Network Name"
                  />
                  {!!errorMessage.network && (
                    <FormHelperText>{errorMessage.network}</FormHelperText>
                  )}
                </>
              )






            }
            </FormControl>
          </Box>
        </Grid>

      </Grid>

      <Grid item xs={12} ml={5} mr={14}>
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Button variant="outlined" onClick={handleCancel}>
          {t("pform.rs")}
          </Button>
          <Button variant="contained" type="submit" onClick={handleSubmit}>
         {t("pform.up")}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default ProfileForm