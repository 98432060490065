import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isMobileLoggedIn, setIsMobileLoggedIn] = useState(false);

  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 600px)').matches;
    setIsMobileLoggedIn(isMobile);
  }, []);

  return (
    <footer
      style={{
        padding: '10px',
        textAlign: 'center',
        position: 'absolute',
        bottom: '0',
        width: '100%',
      }}
    >
      {isMobileLoggedIn ? (
        <p style={{fontSize:'11px'}}>
          © {t('login.copyright')} Viva-India {new Date().getFullYear()} |{' '}
          <a
            href="https://www.stadenterprises.com"
            target="_blank"
            style={{ color: theme.palette.viva.main, textDecoration: 'none' }}
          >
            {t('login.powered_by')} STAD Enterprises
          </a>
        </p>
      ) : (
        <p>
          © {t('login.copyright')} Viva-India {new Date().getFullYear()} |{' '}
          <a
            href="https://www.stadenterprises.com"
            target="_blank"
            style={{ color: theme.palette.viva.main, textDecoration: 'none' }}
          >
            {t('login.powered_by')} STAD Enterprises
          </a>
        </p>
      )}
    </footer>
  );
};

export default Footer;
