import React, { useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

const RadarChart = ({ ucn, purpose, strategy, bench5, staff, bench6, bench7, bench8, bench9, bench10,score }) => {
  let sampleData = [ucn, purpose, strategy, bench5, staff, bench6, bench7, bench8, bench9, bench10];
  const { t } = useTranslation();

  const sampleLabels = [
    t("chart.b1"),
    t("chart.b2"),
    t("chart.b3"),
    t("chart.b4"),
    t("chart.b5"),
    t("chart.b6"),
    t("chart.b7"),
    t("chart.b8"),
    t("chart.b9"),
    t("chart.b10")
  ];
  console.log("sample data received", sampleData);

  const chartData = {
    labels: sampleLabels,
    datasets: [{
      data: sampleData,
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 2,
      pointBackgroundColor: 'rgba(255, 99, 132, 1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
    }],
  };

  // const chartData = {
  //   labels: ['1','2','3','4','5','6','7','8','9','10'],
  //   datasets: [{
  //     data: [ 7,3,5,4,8,2,10,9,1,6 ],
  //     backgroundColor: 'rgba(255, 99, 132, 0.2)',
  //     borderColor: 'rgba(255, 99, 132, 1)',
  //     borderWidth: 2,
  //     pointBackgroundColor: 'rgba(255, 99, 132, 1)',
  //     pointBorderColor: '#fff',
  //     pointHoverBackgroundColor: '#fff',
  //     pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
  //   }],
  // };

  const chartOptions = {
    scales: {
      r: {
        min: 0,
        max: 10,
        ticks: {
          beginAtZero: true,
          fontSize: 10,
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 0,
        bottom: 0,
      },
      margin: {
        left: 0,
        right: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },    
    // elements: {
    //   line: {
    //     borderWidth: 3
    //   }
    // }

  };

  useEffect(() => {
    console.log("Updated Props received:", {
      ucn,
      purpose,
      strategy,
      staff,
      bench5,
      bench6,
      bench7,
      bench8,
      bench9,
      bench10,
    });

    sampleData = [ucn, purpose, strategy, staff, bench5, bench6, bench7, bench8, bench9, bench10];

  }, [ucn, purpose, strategy, staff, bench5, bench6, bench7, bench8, bench9, bench10]);
  console.log("chartdta", chartData);

  return (
    <div>
      <Grid container style={{ justifyContent: 'center'}}>
        <h1 style={{ marginTop: '50px' }}>{t("chart.bss")}</h1>
      </Grid>
      <Grid container style={{ justifyContent: 'center'}}>
        <h1 style={{ marginBottom: '30px' }}>{t("chart.bsc")} : {score}</h1>
      </Grid>
      <Grid container style={{ marginTop: '-100px', marginBottom: '-100px', justifyContent: 'center'}}>
      <Grid item xs={12} md={7}>
        <Radar data={chartData} options={chartOptions} />
      </Grid>
      </Grid>
    </div>
  );
};

export default RadarChart;