import React, { useState, useContext, useEffect } from 'react';
import { config, axios } from '../config/config';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NetworkContext } from "../Context/NetworkContext";
import { RegionContext } from "../Context/RegionContext";
import { UserContext } from '../Components/UserContext';
import ActivityForm from './ActivityForm';
import HealthForm from './HealthForm';
import { ListItems } from "../Components/listItems"
import ProfileForm from './ProfileForm';
import Footer from './Footer';


const drawerWidth = 240;

function Layout(props) {
  const { window, renderForm, renderhForm, isMobileLoggedIn } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('Network Activities');

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMenuItemClick = (item) => {
    console.log('Clicked item:', item);
    setActiveItem(item);
    console.log('Active item:', activeItem);
    setMobileOpen(false);
    handleDrawerToggle();
  };


  const { token, updateToken } = useContext(UserContext);
  const [networks, setNetworks] = useState(() => fetchNetworkData());
  const [regions, setRegions] = useState(() => fetchRegionData());
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Viva India
      </Typography>
      <Divider />
      <List>
        {/* <ListItems /> */}
        <ListItems isMobileLoggedIn={isMobileLoggedIn} onItemClick={handleMenuItemClick} />

      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  async function fetchNetworkData() {
    try {
      const response = await axios.get('/network?limit=1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setNetworks(response.data.data);
    } catch (error) {
      console.log('Error in fetching networks:', error);
    }
  }

  async function fetchRegionData() {
    try {
      const response = await axios.get('/regions?limit=-1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setRegions(response.data.data);
    } catch (error) {
      console.log('Error in fetching regions:', error);
    }
  }


  return (
    <>
      <div style={{ flex: 1, overflow: 'auto', height: 'calc(100vh - 40px)' }}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar component="nav">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
              >
                NHC APP
              </Typography>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {activeItem}
              </Typography>
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
          <Box component="main" sx={{ p: 3 }}>
            <Toolbar />
            <NetworkContext.Provider value={networks}>
              <RegionContext.Provider value={regions}>
                {activeItem === 'Network Activities' && (
                  <div style={{ marginTop: '10px', display: 'flex' }}>
                    <div style={{ marginLeft: '0', flex: 1, overflow: 'auto', height: 'calc(100vh - 80px)' }}>
                      {networks.length > 0 && regions.length > 0 ? <ActivityForm isMobileLoggedIn={isMobileLoggedIn} renderForm={renderForm} /> : ''}
                    </div>
                  </div>
                )}
                {activeItem === 'Network Health Check' && (
                  <div style={{ marginTop: '10px', display: 'flex' }}>
                    <div style={{ marginLeft: '0', flex: 1, overflow: 'auto', height: 'calc(100vh - 80px)' }}>
                      {networks.length > 0 && regions.length > 0 ? <HealthForm renderhForm={renderhForm} /> : ''}
                    </div>
                  </div>
                )}
                {activeItem === 'My Profile' && (
                  <div style={{ marginTop: '20px', display: 'flex' }}>
                    <div style={{ marginLeft: '0', flex: 1, overflow: 'auto', height: 'calc(100vh - 80px)' }}>
                      {networks.length > 0 && regions.length > 0 ? <ProfileForm /> : ''}
                    </div>
                  </div>
                )}
              </RegionContext.Provider>
            </NetworkContext.Provider>
          </Box>
        </Box>
      </div>
      <Footer isMobileLoggedIn={isMobileLoggedIn} />
    </>
  );
}

Layout.propTypes = {
  window: PropTypes.func,
};

export default Layout;