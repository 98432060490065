import React, { useState, useEffect, useContext } from "react";
import { TextField, Button, Box, Dialog, DialogContent, Checkbox, FormHelperText, FormGroup, DialogTitle, Grid, Select, MenuItem, IconButton, FormControl, InputLabel, Radio, RadioGroup, FormControlLabel, Typography } from "@mui/material";
import { config, axios } from '../config/config';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { NetworkContext } from "../Context/NetworkContext";
import { RegionContext } from "../Context/RegionContext";
import { UserContext } from '../Components/UserContext';
import { useTranslation } from 'react-i18next'
import "../assets/css/Form.css"

const HealthForm = ({ onClose, id, updateData, onSuccess }) => {
  console.log("consoling id", id);
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedStrategicAreas, setSelectedStrategicAreas] = useState([]);
  const [formData, setFormData] = useState({
    selectedYear: '',
    network: '',
    country: '',
    city: '',
    region: '',
    networkConsultant: '',
    networkCoordinator: '',
    formCompletedBy: '',
    situationalMapping: '',
    visionAndMission: '',
    promotionalDescription: '',
    distinctives: '',
    passionateAbout: '',
    strategicPlan: '',
    strategicAreas: [],
    hasNetworkCoordination: '',
    paidStaffCount: '',
    volunteerStaffCount: '',
    staffRolesAndObjectives: '',
    hasSteeringGroup: '',
    hasSteeringGroupMet: '',
    steeringGroupRolesAndObjectives: '',
    hasWorkingGroups: '',
    workingGroupsCount: '',
    organizationMembersCount: '',
    churchMembersCount: '',
    hasLongTermPartnership: '',
    hasConsultancyAgreement: '',
    vivaSupportSatisfaction: '',
    networkConnectionRating: '',
    vivaCoachRelationshipRating: '',
    trainingCourseRating: '',
    vivaSupportReceived: [],
    safeguardingChildProtectionPolicy: '',
    safeguardingChildProtectionPolicyLastUpdated: '',
    financePolicy: '',
    financePolicyLastUpdated: '',
    financesIndependentlyVerified: '',
    financeHealthCheckCompleted: '',
    governancePolicy: '',
    governancePolicyLastUpdated: '',
    legalRegistrationCompliance: '',
    legalRegistrationSituation: '',
    hrPolicy: '',
    hrPolicyLastUpdated: '',
    hasDatabaseOfOrganizations: '',
    hasRecordOfMemberParticipation: '',
    communicationFrequency: '',
    networkMeetingPercentage: '',
    providesTrainingForMemberOrgs: '',
    providesTrainingForNonMemberOrgs: '',
    childProtection: '',
    codeOfConduct: '',
    financialManagement: '',
    finPolicy: '',
    hasSharedActivityDataSheet: '',
    governmentLevelInitiatives: '',
    businessLeadersInitiatives: '',
    churchLeadersInitiatives: '',
    justiceSectorInitiatives: '',
    hasInfluencedPolicies: '',
    influencedPolicies: '',
    bestStory: '',
    totalIncome: '',
    inCountryIncome: '',
    giftsInKind: '',
    fundraisingStrategy: '',
    fundraisingPlan: '',
    fundraisingResponsibility: '',
    staffAndVolunteersCount: '',
    childrenReachedCount: '',
    additionalComments: '',
    status: "draft"
  });
  console.log("formdata", formData);

  const [userId, setUserId] = useState("");
  const [form, setForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const networks = useContext(NetworkContext);
  const [isDraft, setIsDraft] = useState(true);
  const [theUser, setTheUser] = useState({});
  const regions = useContext(RegionContext);
  const [user, setUser] = useState(null);
  const [level, setLevel] = useState(0);

  useEffect(() => {
    console.log(id);
    fetchData();
  }, []);

  const { token, updateToken } = useContext(UserContext);


  async function fetchData() {
    try {
      const authResponse = await axios.get('/auth/me', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (authResponse.status === 200) {
        const authData = authResponse.data;
        setTheUser(authResponse.data);
        setLevel(authData.level);
        console.log('Auth data:', authData.level);
        console.log('Auth data:', authData);
        setUser(authData);
        const response = await axios.get(`/health/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });


        if (response.status === 200) {

          const responseData = response.data;
          console.log("consoling response", responseData);

          // if (Array.isArray(responseData.data)) {
            // const indexToSet = id - 1;

            if (responseData) {
              const obj = responseData;
              console.log("cosnoling obj", obj);
              console.log("consoling userId", obj._id);
              console.log("viva support received", obj.vivaSupportReceived)
              setSelectedOptions(obj.vivaSupportReceived);
              setSelectedStrategicAreas(obj.strategicAreas);
              setUserId(obj._id);
              setFormData({
                selectedYear: obj.selectedYear,
                network: obj.network,
                country: obj.country,
                city: obj.city,
                region: obj.region,
                networkConsultant: obj.networkConsultant,
                networkCoordinator: obj.networkCoordinator,
                formCompletedBy: obj.formCompletedBy,
                situationalMapping: obj.situationalMapping,
                visionAndMission: obj.visionAndMission,
                promotionalDescription: obj.promotionalDescription,
                distinctives: obj.distinctives,
                passionateAbout: obj.passionateAbout,
                strategicPlan: obj.strategicPlan,
                strategicAreas: obj.strategicAreas,
                hasNetworkCoordination: obj.hasNetworkCoordination,
                paidStaffCount: obj.paidStaffCount,
                volunteerStaffCount: obj.volunteerStaffCount,
                staffRolesAndObjectives: obj.staffRolesAndObjectives,
                hasSteeringGroup: obj.hasSteeringGroup,
                hasSteeringGroupMet: obj.hasSteeringGroupMet,
                steeringGroupRolesAndObjectives: obj.steeringGroupRolesAndObjectives,
                hasWorkingGroups: obj.hasWorkingGroups,
                workingGroupsCount: obj.workingGroupsCount,
                organizationMembersCount: obj.organizationMembersCount,
                churchMembersCount: obj.churchMembersCount,
                hasLongTermPartnership: obj.hasLongTermPartnership,
                hasConsultancyAgreement: obj.hasConsultancyAgreement,
                vivaSupportSatisfaction: obj.vivaSupportSatisfaction,
                networkConnectionRating: obj.networkConnectionRating,
                vivaCoachRelationshipRating: obj.vivaCoachRelationshipRating,
                trainingCourseRating: obj.trainingCourseRating,
                vivaSupportReceived: obj.vivaSupportReceived,
                safeguardingChildProtectionPolicy: obj.safeguardingChildProtectionPolicy,
                safeguardingChildProtectionPolicyLastUpdated: obj.safeguardingChildProtectionPolicyLastUpdated,
                financePolicy: obj.financePolicy,
                financePolicyLastUpdated: obj.financePolicyLastUpdated,
                financesIndependentlyVerified: obj.financesIndependentlyVerified,
                financeHealthCheckCompleted: obj.financeHealthCheckCompleted,
                governancePolicy: obj.governancePolicy,
                governancePolicyLastUpdated: obj.governancePolicyLastUpdated,
                legalRegistrationCompliance: obj.legalRegistrationCompliance,
                legalRegistrationSituation: obj.legalRegistrationSituation,
                hrPolicy: obj.hrPolicy,
                hrPolicyLastUpdated: obj.hrPolicyLastUpdated,
                hasDatabaseOfOrganizations: obj.hasDatabaseOfOrganizations,
                hasRecordOfMemberParticipation: obj.hasRecordOfMemberParticipation,
                communicationFrequency: obj.communicationFrequency,
                networkMeetingPercentage: obj.networkMeetingPercentage,
                providesTrainingForMemberOrgs: obj.providesTrainingForMemberOrgs,
                providesTrainingForNonMemberOrgs: obj.providesTrainingForNonMemberOrgs,
                childProtection: obj.childProtection,
                codeOfConduct: obj.codeOfConduct,
                financialManagement: obj.financialManagement,
                finPolicy: obj.finPolicy,
                hasSharedActivityDataSheet: obj.hasSharedActivityDataSheet,
                governmentLevelInitiatives: obj.governmentLevelInitiatives,
                businessLeadersInitiatives: obj.businessLeadersInitiatives,
                churchLeadersInitiatives: obj.churchLeadersInitiatives,
                justiceSectorInitiatives: obj.justiceSectorInitiatives,
                hasInfluencedPolicies: obj.hasInfluencedPolicies,
                influencedPolicies: obj.influencedPolicies,
                bestStory: obj.bestStory,
                totalIncome: obj.totalIncome,
                inCountryIncome: obj.inCountryIncome,
                giftsInKind: obj.giftsInKind,
                fundraisingStrategy: obj.fundraisingStrategy,
                fundraisingPlan: obj.fundraisingPlan,
                fundraisingResponsibility: obj.fundraisingResponsibility,
                staffAndVolunteersCount: obj.staffAndVolunteersCount,
                childrenReachedCount: obj.childrenReachedCount,
                additionalComments: obj.additionalComments,
                status: obj.status,
                // userId: obj._id,
                userId: obj.userId,
                userLevel: obj.userLevel
              });
            }
          // }
        } else {
          console.error('Error fetching data: Invalid response structure');
        }
      } else {
        console.error('Error fetching auth data: Invalid response structure');
      }
    } catch (error) {
      console.log("Error fetching user data", error);
    }
  }


  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        const authResponse = await axios.get('/auth/me', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (authResponse.status === 200) {
          const authData = authResponse.data;
          console.log('Auth data:', authData);

        } else {
          console.error('Error fetching auth data: Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAuthData();
    fetchData();
  }, []);

  const [isMobileLoggedIn, setIsMobileLoggedIn] = useState(false);
  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 600px)').matches;
    const isLoggedIn = "logged";

    setIsMobileLoggedIn(isMobile && isLoggedIn);
  }, []);

  const [errorMessage, setErrorMessage] = useState({
    selectedYear: "",
    network: "",
    country: "",
    city: "",
    region: "",
    networkConsultant: "",
    networkCoordinator: "",
    formCompletedBy: "",
    situationalMapping: "",
    visionAndMission: "",
    promotionalDescription: "",
    distinctives: "",
    passionateAbout: "",
    strategicPlan: "",
    strategicAreas: [""],
    hasNetworkCoordination: "",
    paidStaffCount: "",
    volunteerStaffCount: "",
    staffRolesAndObjectives: "",
    hasSteeringGroup: "",
    hasSteeringGroupMet: "",
    steeringGroupRolesAndObjectives: "",
    hasWorkingGroups: "",
    workingGroupsCount: "",
    organizationMembersCount: "",
    churchMembersCount: "",
    hasLongTermPartnership: "",
    hasConsultancyAgreement: "",
    vivaSupportSatisfaction: "",
    networkConnectionRating: "",
    vivaCoachRelationshipRating: "",
    trainingCourseRating: "",
    vivaSupportReceived: [""],
    safeguardingChildProtectionPolicy: "",
    financePolicy: "",
    financesIndependentlyVerified: "",
    financeHealthCheckCompleted: "",
    governancePolicy: "",
    legalRegistrationCompliance: "",
    legalRegistrationSituation: "",
    hrPolicy: "",
    hasDatabaseOfOrganizations: "",
    hasRecordOfMemberParticipation: "",
    communicationFrequency: "",
    networkMeetingPercentage: "",
    providesTrainingForMemberOrgs: "",
    providesTrainingForNonMemberOrgs: "",
    childProtection: "",
    codeOfConduct: "",
    financialManagement: "",
    finPolicy: "",
    hasSharedActivityDataSheet: "",
    governmentLevelInitiatives: "",
    businessLeadersInitiatives: "",
    churchLeadersInitiatives: "",
    justiceSectorInitiatives: "",
    hasInfluencedPolicies: "",
    influencedPolicies: "",
    bestStory: "",
    totalIncome: "",
    inCountryIncome: "",
    giftsInKind: "",
    fundraisingStrategy: "",
    fundraisingPlan: "",
    fundraisingResponsibility: "",
    staffAndVolunteersCount: "",
    childrenReachedCount: "",
    additionalComments: ""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const selectedNetworkId = event.target.value;
    const selectedNetwork = networks.find((network) => network.id === selectedNetworkId);

    if (selectedNetwork) {
      const { regionId } = selectedNetwork;
      const selectedRegion = regions.find((region) => region.regionId === regionId);

      if (selectedRegion) {
        const { name: regionName, country } = selectedRegion;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
          region: regionName,
          country: selectedNetwork?.countryName
        }));
        setErrorMessage((prevState) => ({ ...prevState, region: '', country: '' }));
      }
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
    const numericValue = Number(value);
    if (
      name === "selectedYear" ||
      name === 'network' ||
      name === 'country' ||
      name === 'city' ||
      name === "region" ||
      name === 'networkConsultant' ||
      name === 'networkCoordinator' ||
      name === 'formCompletedBy' ||
      name === 'situationalMapping' ||
      name === 'visionAndMission' ||
      name === 'promotionalDescription' ||
      name === 'distinctives' ||
      name === 'passionateAbout' ||
      name === 'strategicPlan' ||
      // name === 'strategicAreas' ||
      name === 'hasNetworkCoordination' ||
      name === 'staffRolesAndObjectives' ||
      name === 'hasSteeringGroup' ||
      // name === 'hasSteeringGroupMet' ||
      name === 'steeringGroupRolesAndObjectives' ||
      name === 'hasWorkingGroups' ||
      name === 'hasLongTermPartnership' ||
      name === 'hasConsultancyAgreement' ||
      // name === 'vivaSupportSatisfaction' ||
      name === 'networkConnectionRating' ||
      name === 'vivaCoachRelationshipRating' ||
      name === 'trainingCourseRating' ||
      name === 'vivaSupportReceived' ||
      name === 'safeguardingChildProtectionPolicy' ||
      name === 'financePolicy' ||
      name === 'financesIndependentlyVerified' ||
      name === 'financeHealthCheckCompleted' ||
      name === 'governancePolicy' ||
      name === 'legalRegistrationCompliance' ||
      // name === 'legalRegistrationSituation' ||
      name === 'hrPolicy' ||
      name === 'hasDatabaseOfOrganizations' ||
      name === 'hasRecordOfMemberParticipation' ||
      name === 'communicationFrequency' ||
      name === 'providesTrainingForMemberOrgs' ||
      name === 'providesTrainingForNonMemberOrgs' ||
      name === 'financialManagement' ||
      name === 'hasSharedActivityDataSheet' ||
      name === 'hasInfluencedPolicies' ||
      // name === 'influencedPolicies' ||
      name === 'bestStory' ||
      name === 'fundraisingStrategy' ||
      name === 'fundraisingPlan' ||
      name === 'fundraisingResponsibility' ||
      name === 'additionalComments'
    ) {
      if (!value) {
        setErrorMessage((prevState) => ({ ...prevState, [name]: `${name} is required` }));
      } else {
        setErrorMessage((prevState) => ({ ...prevState, [name]: '' }));
      }
    }

    if (
      name === 'paidStaffCount' ||
      name === 'volunteerStaffCount' ||
      name === 'workingGroupsCount' ||
      name === 'organizationMembersCount' ||
      name === 'churchMembersCount' ||
      name === 'governmentLevelInitiatives' ||
      name === 'businessLeadersInitiatives' ||
      name === 'churchLeadersInitiatives' ||
      name === 'justiceSectorInitiatives' ||
      name === 'totalIncome' ||
      name === 'inCountryIncome' ||
      name === 'giftsInKind' ||
      name === 'staffAndVolunteersCount' ||
      name === 'childrenReachedCount'
    )
      if (isNaN(numericValue) || numericValue < 0) {
        setErrorMessage((prevState) => ({ ...prevState, [name]: t("healthform.error.vcant") }));
      } else {
        setErrorMessage((prevState) => ({ ...prevState, [name]: '' }));
      }
      

    if (
      name === 'networkMeetingPercentage' ||
      name === 'childProtection' ||
      name === 'codeOfConduct' ||
      name === 'finPolicy'
    ) {
      const numericValue = Number(value);
      if (isNaN(numericValue)) {
        setErrorMessage((prevState) => ({ ...prevState, [name]: (t("healthform.error.ipv")) }));
      } else if (numericValue < 0) {
        setErrorMessage((prevState) => ({ ...prevState, [name]: (t("healthform.error.pcn")) }));
      } else if (numericValue > 100) {
        setErrorMessage((prevState) => ({ ...prevState, [name]: (t("healthform.error.pch")) }));
      } else {
        setErrorMessage((prevState) => ({ ...prevState, [name]: '' }));
      }
    }


    if (name === 'safeguardingChildProtectionPolicy') {
      if (value === 'No') {
        setFormData((prevState) => ({
          ...prevState,
          safeguardingChildProtectionPolicy: value,
          safeguardingChildProtectionPolicyLastUpdated: 'none',
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          safeguardingChildProtectionPolicy: value,
        }));
      }
    } else if (name === 'safeguardingChildProtectionPolicyLastUpdated') {
      setFormData((prevState) => ({
        ...prevState,
        safeguardingChildProtectionPolicyLastUpdated: value,
      }));
    } else if (name === 'hrPolicy') {
      if (value === 'No') {
        setFormData((prevState) => ({
          ...prevState,
          hrPolicy: value,
          hrPolicyLastUpdated: 'none',
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          hrPolicy: value,
        }));
      }
    } else if (name === 'hrPolicyLastUpdated') {
      setFormData((prevState) => ({
        ...prevState,
        hrPolicyLastUpdated: value,
      }));
    } else if (name === 'governancePolicy') {
      if (value === 'No') {
        setFormData((prevState) => ({
          ...prevState,
          governancePolicy: value,
          governancePolicyLastUpdated: 'none',
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          governancePolicy: value,
        }));
      }
    } else if (name === 'governancePolicyLastUpdated') {
      setFormData((prevState) => ({
        ...prevState,
        governancePolicyLastUpdated: value,
      }));
    } else if (name === 'financePolicy') {
      if (value === 'No') {
        setFormData((prevState) => ({
          ...prevState,
          financePolicy: value,
          financePolicyLastUpdated: 'none',
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          financePolicy: value,
        }));
      }
    } else if (name === 'financePolicyLastUpdated') {
      setFormData((prevState) => ({
        ...prevState,
        financePolicyLastUpdated: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleDialogClose = () => {
    onClose();
  };

  const currentYear = new Date().getFullYear() - 1;
  const years = [
    `${currentYear}-${currentYear + 1}`,
    `${currentYear + 1}-${currentYear + 2}`,
    `${currentYear + 2}-${currentYear + 3}`
  ];

  // show options of years starting from the year defined in env
  const generateYearOptions = () => {
    const options = [];
    console.log("config", config);
    for (let i = config.cfgSYear; i <= currentYear + 1; i++) {
      const startYear = i;
      const endYear = i + 1;
      options.push(`${startYear}-${endYear}`);
    }
    return options;
  };


  // if(new Date().getMonth() > 5) {
  //   currentYear = new Date().getFullYear();
  //   years = [
  //     `${currentYear}-${currentYear + 1}`,
  //     `${currentYear + 1}-${currentYear + 2}`
  //   ];
  // }


  const handleSaveDraft = async (event) => {
    event.preventDefault();
    saveRecord("draft")
  };


  const handleApprove = async (event) => {
    event.preventDefault();
    formData.approvedBy = user.userId;
    saveRecord("approved");
  }


  const handleReview = async (event) => {
    event.preventDefault();
    saveRecord("review");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    saveRecord("submitted")
  };


  const saveRecord = async (status) => {
    const requiredFields = [
      'selectedYear',
      'network',
      'country',
      'city',
      'region',
      'networkConsultant',
      'networkCoordinator',
      'formCompletedBy',
      'situationalMapping',
      'visionAndMission',
      'promotionalDescription',
      'distinctives',
      'passionateAbout',
      'strategicPlan',
      // 'strategicAreas',
      'hasNetworkCoordination',
      'paidStaffCount',
      'volunteerStaffCount',
      'staffRolesAndObjectives',
      'hasSteeringGroup',
      // 'hasSteeringGroupMet',
      'steeringGroupRolesAndObjectives',
      'hasWorkingGroups',
      'workingGroupsCount',
      'organizationMembersCount',
      'churchMembersCount',
      'hasLongTermPartnership',
      'hasConsultancyAgreement',
      'vivaSupportSatisfaction',
      'networkConnectionRating',
      'vivaCoachRelationshipRating',
      'trainingCourseRating',
      'vivaSupportReceived',
      'safeguardingChildProtectionPolicy',
      'financePolicy',
      'financesIndependentlyVerified',
      'financeHealthCheckCompleted',
      'governancePolicy',
      'legalRegistrationCompliance',
      // 'legalRegistrationSituation',
      'hrPolicy',
      'hasDatabaseOfOrganizations',
      'hasRecordOfMemberParticipation',
      'communicationFrequency',
      'networkMeetingPercentage',
      'providesTrainingForMemberOrgs',
      'providesTrainingForNonMemberOrgs',
      'childProtection',
      'codeOfConduct',
      'financialManagement',
      'finPolicy',
      'hasSharedActivityDataSheet',
      'governmentLevelInitiatives',
      'businessLeadersInitiatives',
      'churchLeadersInitiatives',
      'justiceSectorInitiatives',
      'hasInfluencedPolicies',
      // 'influencedPolicies',
      'bestStory',
      'totalIncome',
      'inCountryIncome',
      'giftsInKind',
      'fundraisingStrategy',
      'fundraisingPlan',
      'fundraisingResponsibility',
      'staffAndVolunteersCount',
      'childrenReachedCount',
      'additionalComments'
    ]

    const draftRequiredFields = [
      'selectedYear',
      'network',
      'country',
      'city',
      'region',
      'networkConsultant',
      'networkCoordinator'
    ]

    const newErrorMessage = { ...errorMessage };
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if ([undefined, null, ''].indexOf(formData[field]) >= 0 && status !== 'draft') {
        newErrorMessage[field] = (t("healthform.error.frequired"));
        hasErrors = true;
      } else {
        newErrorMessage[field] = "";
      }
    });

    draftRequiredFields.forEach((field) => {
      if ([undefined, null, ''].indexOf(formData[field]) >= 0 && status == 'draft') {
        newErrorMessage[field] = t("activityform.error.frequired");
        hasErrors = true;
      } else {
        newErrorMessage[field] = "";
      }
    });

    if (selectedOptions.length === 0 && status !== 'draft') {
      newErrorMessage["vivaSupportReceived"] = (t("healthform.error.frequired"));
      hasErrors = true;
    } else {
      newErrorMessage["vivaSupportReceived"] = "";
    }
    console.log("console error 1", hasErrors);

    if (selectedStrategicAreas.length === 0 && status !== 'draft') {
      newErrorMessage["strategicAreas"] = (t("healthform.error.frequired"));
      hasErrors = true;
    } else {
      newErrorMessage["strategicAreas"] = "";
    }
    console.log("console error 2", hasErrors);


    if (hasErrors) {
      setErrorMessage(newErrorMessage);
      setError(t("healthform.error.validation"));
      console.log("console new error message", newErrorMessage);
      console.log("consoling erroe mesg", errorMessage);
      return;
    }

    let response = null;
    formData.status = status;
    console.log('formData payload', formData);
    console.log('id', id);
    if (id) {
      try {
        response = await axios.put(
          `/health/${id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("consoling userId in try catch block", userId);
          console.log("consoling api response", response);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            onClose();
          }, 3000);
          console.log('formData', formData);
          updateData({
            id: id,
            networkCoordinator: formData.networkCoordinator,
            networkConsultant: formData.networkConsultant,
            network: networks.find((network) => network.id === formData.network)?.name || "N/A",
            country: formData.country,
            region: formData.region,
            status: formData.status,
            formCompletedBy: formData.formCompletedBy,
            userId
          });
          console.log("updated data in helath form", response.data);
        }
      } catch (error) {
        console.log(error);
        console.log("consoling userId in try catch block", userId);
        setError((t("healthform.error.seter")));
        setSuccess(false);
        setOpen(true);
      }
    } else {

      try {
        response = await axios.post(
          `/health`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setOpen(true);
          setError("");
          setSuccess(true);
          if (isMobileLoggedIn) {
            setTimeout(() => {
              setFormData({
                selectedYear: '',
                network: '',
                country: '',
                city: '',
                region: '',
                networkConsultant: '',
                networkCoordinator: '',
                formCompletedBy: '',
                situationalMapping: '',
                visionAndMission: '',
                promotionalDescription: '',
                distinctives: '',
                passionateAbout: '',
                strategicPlan: '',
                strategicAreas: [''],
                hasNetworkCoordination: '',
                paidStaffCount: '',
                volunteerStaffCount: '',
                staffRolesAndObjectives: '',
                hasSteeringGroup: '',
                hasSteeringGroupMet: '',
                steeringGroupRolesAndObjectives: '',
                hasWorkingGroups: '',
                workingGroupsCount: '',
                organizationMembersCount: '',
                churchMembersCount: '',
                hasLongTermPartnership: '',
                hasConsultancyAgreement: '',
                vivaSupportSatisfaction: '',
                networkConnectionRating: '',
                vivaCoachRelationshipRating: '',
                trainingCourseRating: '',
                vivaSupportReceived: [],
                safeguardingChildProtectionPolicy: '',
                safeguardingChildProtectionPolicyLastUpdated: '',
                financePolicy: '',
                financePolicyLastUpdated: '',
                financesIndependentlyVerified: '',
                financeHealthCheckCompleted: '',
                governancePolicy: '',
                governancePolicyLastUpdated: '',
                legalRegistrationCompliance: '',
                legalRegistrationSituation: '',
                hrPolicy: '',
                hrPolicyLastUpdated: '',
                hasDatabaseOfOrganizations: '',
                hasRecordOfMemberParticipation: '',
                communicationFrequency: '',
                networkMeetingPercentage: '',
                providesTrainingForMemberOrgs: '',
                providesTrainingForNonMemberOrgs: '',
                childProtection: '',
                codeOfConduct: '',
                financialManagement: '',
                finPolicy: '',
                hasSharedActivityDataSheet: '',
                governmentLevelInitiatives: '',
                businessLeadersInitiatives: '',
                churchLeadersInitiatives: '',
                justiceSectorInitiatives: '',
                hasInfluencedPolicies: '',
                influencedPolicies: '',
                bestStory: '',
                totalIncome: '',
                inCountryIncome: '',
                giftsInKind: '',
                fundraisingStrategy: '',
                fundraisingPlan: '',
                fundraisingResponsibility: '',
                staffAndVolunteersCount: '',
                childrenReachedCount: '',
                additionalComments: '',
                status: ''
              });
            }, 2000);
          } else {
            setTimeout(() => {
              onClose();
              onSuccess();
            }, 3000);
          }
          console.log("consoling success", success);
        } else {
          setError(response.data.message);
          setSuccess(false);
          setOpen(true);
        }
      } catch (error) {
        console.error("helath ceck failed:", error);
        if (error.response && error.response.status === 422) {
          formData.status = "draft";
          console.log("response.data.message", error.response.data.message.errors.network.message)
          setError(`Error ${error.response.data.message.errors.network.message}`);
          setTimeout(() => {
            setError("");
          }, 6000);
        }
      }
    }
  };

  const handleCheckboxChange = (event) => {
    let value = event.target.value;
    let isChecked = event.target.checked;

    let updatedOptions = [...selectedOptions];
    if (selectedOptions.length >= 1 || value) {
      setErrorMessage((prevState) => ({ ...prevState, vivaSupportReceived: '' }));
    }
    if (isChecked) {
      if (!updatedOptions.includes(value)) {
        updatedOptions.push(value);
      }
    } else {
      updatedOptions = updatedOptions.filter((option) => option !== value);
    }

    setSelectedOptions(updatedOptions);
    setFormData((prevFormData) => ({
      ...prevFormData,
      vivaSupportReceived: updatedOptions,
    }));
  };

  const handleSelectedAreasChange = (event) => {
    let value = event.target.value;
    let isChecked = event.target.checked;

    let updatedOptions = [...selectedStrategicAreas];
    if (selectedStrategicAreas.length >= 1 || value) {
      setErrorMessage((prevState) => ({ ...prevState, selectedStrategicAreas: '' }));
    }
    if (isChecked) {
      if (!updatedOptions.includes(value)) {
        updatedOptions.push(value);
      }
    } else {
      updatedOptions = updatedOptions.filter((option) => option !== value);
    }

    setSelectedStrategicAreas(updatedOptions);
    console.log('selectedStrategicAreas', updatedOptions)
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedStrategicAreas: updatedOptions,
    }));
  };

  const renderhForm = () => {
    return (
      <form onSubmit={isDraft ? handleSaveDraft : handleSubmit}>
        {/* <form onSubmit={handleSubmit}> */}
        <Typography className="head">
          {t("healthform.Hcy")}
        </Typography>

        <Grid item xs={12}>
          <Typography className="inputfield" variant="title">
            {t("healthform.A1")}
          </Typography>
          <span className="text">
            <br />
            {t("healthform.Year")}
          </span>
          <Box marginTop={2}>
            <FormControl fullWidth size="small" error={!!errorMessage.selectedYear}>
              <Select
                name="selectedYear"
                value={formData.selectedYear}
                onChange={handleInputChange}
                className="textfield"
              >
                <MenuItem value="">Select Year</MenuItem>
                {generateYearOptions().map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {errorMessage.selectedYear && (
                <FormHelperText>{errorMessage.selectedYear}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>



        <Typography className="head">
          {t("healthform.Ni")}
        </Typography>
        {/* </Grid> */}
        <Grid container spacing={2}>

          {/* <Grid item xs={12}>
          <Typography className="inputfield" variant="title">
            *A1a. Network Name
          </Typography>
          <span className="text">
            <br />
            Network Name in Full and the (ACRONYM) in brackets
          </span>
          <Box marginTop={2}>
            <Select
              name="network"
              value={formData.network}
              onChange={handleInputChange}
              fullWidth
              size="small"
              className="textfield"
              error={!!errorMessage.network}
              helperText={errorMessage.network}
            >
              <MenuItem value="">Select Network</MenuItem>
              {networks?.length > 0 &&
                networks.map((network) => (
                  <MenuItem key={network.id} value={network.id}>
                    {network.name}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Grid> */}
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">
              {t("healthform.A1a")}
            </Typography>
            <span className="text">
              <br />
              {t("healthform.Nn")}
            </span>
            <Box marginTop={2}>
              {user && user.network ? (
                <Select
                  name="network"
                  value={formData.network || ""}
                  onChange={handleInputChange}
                  fullWidth
                  size="small"
                  className="textfield"
                  error={!!errorMessage.network}
                  helperText={errorMessage.network}
                >
                  <MenuItem value="">Select Network</MenuItem>
                  {user.network.includes('All')
                    ? networks.map((network) => (
                      <MenuItem key={network.id} value={network.id}>
                        {network.name}
                      </MenuItem>
                    ))
                    : user.network.map((networkId) => {
                      const network = networks.find((item) => item.id === networkId);
                      if(network) {
                        return (
                          <MenuItem key={network.id} value={network.id}>
                            {network.name}
                          </MenuItem>
                        );
                      }
                    })}
                </Select>
              ) : (
                <TextField
                  name="network"
                  value={formData.networkId || ""}
                  onChange={handleInputChange}
                  fullWidth
                  size="small"
                  className="textfield"
                  variant="outlined"
                  placeholder="Network Name"
                  error={!!errorMessage.network}
                  helperText={errorMessage.network}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A1b")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="region"
                value={formData.region}
                onChange={handleInputChange}
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.region}
                helperText={errorMessage.region}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A1c")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.country}
                helperText={errorMessage.country}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A1d")}</Typography>
            <Box marginTop={2}>
              <TextField
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.city}
                helperText={errorMessage.city}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A1e")}</Typography>
            <span className="text"><br />{t("healthform.Name")}</span>
            <Box marginTop={2}>
              <TextField
                name="networkConsultant"
                value={formData.networkConsultant}
                onChange={handleInputChange}
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.networkConsultant}
                helperText={errorMessage.networkConsultant}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A1f")}</Typography>
            <span className="text"><br />{t("healthform.Name")}</span>
            <Box marginTop={2}>
              <TextField
                name="networkCoordinator"
                value={formData.networkCoordinator}
                onChange={handleInputChange}
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.networkCoordinator}
                helperText={errorMessage.networkCoordinator}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A2")}</Typography>
            <span className="text"><br />{t("healthform.Name")}</span>
            <Box marginTop={2}>
              <TextField
                name="formCompletedBy"
                value={formData.formCompletedBy}
                onChange={handleInputChange}
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.formCompletedBy}
                helperText={errorMessage.formCompletedBy}
              />
            </Box>
          </Grid>
        </Grid>
        <Typography className="head">
          {t("healthform.PART A")}
        </Typography>

        {/* </Grid> */}

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">
              {t("healthform.A.1")}
            </Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.situationalMapping}>
                <RadioGroup
                  aria-label="situationalMapping"
                  name="situationalMapping"
                  value={formData.situationalMapping}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.situationalMapping}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>


          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A.2")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.visionAndMission}>
                <RadioGroup aria-label="visionAndMission" name="visionAndMission" value={formData.visionAndMission} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.visionAndMission}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A3")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.promotionalDescription}>
                <RadioGroup aria-label="promotionalDescription" name="promotionalDescription" value={formData.promotionalDescription} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.promotionalDescription}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A4")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.distinctives}>
                <RadioGroup aria-label="distinctives" name="distinctives" value={formData.distinctives} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.distinctives}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A5")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.passionateAbout}>
                <RadioGroup aria-label="passionateAbout" name="passionateAbout" value={formData.passionateAbout} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.passionateAbout}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.A6")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.strategicPlan}>
                <RadioGroup aria-label="strategicPlan" name="strategicPlan" value={formData.strategicPlan} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.strategicPlan}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title" >
              {t("healthform.A7")}
            </Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset"  error={!!errorMessage.strategicAreas}>
              <FormGroup aria-label="strategicAreas" name="strategicAreas" value={formData.strategicAreas} >
                <FormControlLabel
                  control={<Checkbox checked={selectedStrategicAreas.includes('safe')} onChange={handleSelectedAreasChange} value="safe" />}
                  label={t("healthform.Safe")}
                />
                <FormControlLabel
                  control={<Checkbox checked={selectedStrategicAreas.includes("thriving")} onChange={handleSelectedAreasChange} value="thriving" />}
                  label={t("healthform.Thriving")}
                />
                <FormControlLabel
                  control={<Checkbox checked={selectedStrategicAreas.includes('learning')} onChange={handleSelectedAreasChange} value="learning" />}
                  label={t("healthform.Learning")}
                />
              </FormGroup>
              <FormHelperText>{errorMessage.strategicAreas}</FormHelperText>
              </FormControl>
            </Box>          
          </Grid>
        </Grid>


        <Typography className="head">
          {t("healthform.PART B")}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.B1")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset"  error={!!errorMessage.hasNetworkCoordination}>
                <RadioGroup aria-label="hasNetworkCoordination" name="hasNetworkCoordination" value={formData.hasNetworkCoordination} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.hasNetworkCoordination}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.B2")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="paidStaffCount"
                value={formData.paidStaffCount}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.paidStaffCount}
                helperText={errorMessage.paidStaffCount}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.B3")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="volunteerStaffCount"
                value={formData.volunteerStaffCount}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.volunteerStaffCount}
                helperText={errorMessage.volunteerStaffCount}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.B4")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset"  error={!!errorMessage.staffRolesAndObjectives}>
                <RadioGroup aria-label="staffRolesAndObjectives" name="staffRolesAndObjectives" value={formData.staffRolesAndObjectives} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.staffRolesAndObjectives}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography size="small" className="inputfield" variant="title">{t("healthform.B5a")}</Typography>
            <Box marginTop={2}>
            <FormControl component="fieldset"  error={!!errorMessage.hasSteeringGroup}>
              <RadioGroup aria-label="hasSteeringGroup" name="hasSteeringGroup" value={formData.hasSteeringGroup} onChange={handleInputChange}>
                <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
              </RadioGroup>
              <FormHelperText>{errorMessage.hasSteeringGroup}</FormHelperText>
              </FormControl>
            </Box>
            {formData.hasSteeringGroup === "Yes" && (
              <Box marginTop={2}>
                <br />
                <Typography size="small" className="inputfield" variant="title">{t("healthform.B5b")}</Typography>
                <br />
                <br />
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="hasSteeringGroupMet"
                  value={formData.hasSteeringGroupMet}
                  onChange={handleInputChange}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.B6")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset"  error={!!errorMessage.steeringGroupRolesAndObjectives}>
                <RadioGroup aria-label="steeringGroupRolesAndObjectives" name="steeringGroupRolesAndObjectives" value={formData.steeringGroupRolesAndObjectives} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.steeringGroupRolesAndObjectives}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.B7")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset"  error={!!errorMessage.hasWorkingGroups}>
                <RadioGroup aria-label="hasWorkingGroups" name="hasWorkingGroups" value={formData.hasWorkingGroups} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.hasWorkingGroups}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.B8")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="workingGroupsCount"
                value={formData.workingGroupsCount}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.workingGroupsCount}
                helperText={errorMessage.workingGroupsCount}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.B9")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="organizationMembersCount"
                value={formData.organizationMembersCount}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.organizationMembersCount}
                helperText={errorMessage.organizationMembersCount}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.B10")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="churchMembersCount"
                value={formData.churchMembersCount}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.churchMembersCount}
                helperText={errorMessage.churchMembersCount}
              />
            </Box>
          </Grid>

        </Grid>
        <Typography className="head">
          {t("healthform.PART C")}

        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.C1")}</Typography>

            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.hasLongTermPartnership}>
                <RadioGroup aria-label="hasLongTermPartnership" name="hasLongTermPartnership" value={formData.hasLongTermPartnership} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.hasLongTermPartnership}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.C2")}</Typography>


            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.hasConsultancyAgreement}>
                <RadioGroup aria-label="hasConsultancyAgreement" name="hasConsultancyAgreement" value={formData.hasConsultancyAgreement} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.hasConsultancyAgreement}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.C3")}</Typography>

            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.vivaSupportSatisfaction}>
                <RadioGroup aria-label="vivaSupportSatisfaction" name="vivaSupportSatisfaction" value={formData.vivaSupportSatisfaction} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.vivaSupportSatisfaction}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.C4")}</Typography>

            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.networkConnectionRating}>
                <RadioGroup aria-label="networkConnectionRating" name="networkConnectionRating" value={formData.networkConnectionRating} onChange={handleInputChange}>
                  <FormControlLabel value="Excellent" control={<Radio />} label={t("healthform.Excellent")} />
                  <FormControlLabel value="Good" control={<Radio />} label={t("healthform.Good")} />
                  <FormControlLabel value="Satisfactory" control={<Radio />} label={t("healthform.St")} />
                  <FormControlLabel value="Unsatisfactory" control={<Radio />} label={t("healthform.Un")} />
                  <FormControlLabel value="We have not participated this year" control={<Radio />} label={t("healthform.np")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.networkConnectionRating}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.C5")}</Typography>

            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.vivaCoachRelationshipRating}>
                <RadioGroup aria-label="vivaCoachRelationshipRating" name="vivaCoachRelationshipRating" value={formData.vivaCoachRelationshipRating} onChange={handleInputChange}>
                  <FormControlLabel value="Excellent" control={<Radio />} label={t("healthform.Excellent")} />
                  <FormControlLabel value="Good" control={<Radio />} label={t("healthform.Good")} />
                  <FormControlLabel value="Satisfactory" control={<Radio />} label={t("healthform.St")} />
                  <FormControlLabel value="Unsatisfactory" control={<Radio />} label={t("healthform.Un")} />
                  <FormControlLabel value="We have not participated this year" control={<Radio />} label={t("healthform.np")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.vivaCoachRelationshipRating}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.C6")}</Typography>

            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.trainingCourseRating}>
                <RadioGroup aria-label="trainingCourseRating" name="trainingCourseRating" value={formData.trainingCourseRating} onChange={handleInputChange}>
                  <FormControlLabel value="Excellent" control={<Radio />} label={t("healthform.Excellent")} />
                  <FormControlLabel value="Good" control={<Radio />} label={t("healthform.Good")} />
                  <FormControlLabel value="Satisfactory" control={<Radio />} label={t("healthform.St")} />
                  <FormControlLabel value="Unsatisfactory" control={<Radio />} label={t("healthform.Un")} />
                  <FormControlLabel value="We have not participated this year" control={<Radio />} label={t("healthform.np")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.trainingCourseRating}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

    <Grid item xs={12}>
            <Typography className="inputfield" variant="title" >
              {t("healthform.C7")}
            </Typography>
            <FormControl component="fieldset"  error={!!errorMessage.vivaSupportReceived}>
            <FormGroup aria-label="vivaSupportReceived" name="vivaSupportReceived" value={formData.vivaSupportReceived} >
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.includes('NTC')} onChange={handleCheckboxChange} value="NTC" />}
                label={t("healthform.Ntc")}
              />
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.includes("RM")} onChange={handleCheckboxChange} value="RM" />}
                label={t("healthform.Rm")}
              />
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.includes('SWPME')} onChange={handleCheckboxChange} value="SWPME" />}
                label={t("healthform.Spm")}
              />
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.includes('SWF')} onChange={handleCheckboxChange} value="SWF" />}
                label={t("healthform.Swf")}
              />
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.includes('PPS')} onChange={handleCheckboxChange} value="PPS" />}
                label={t("healthform.Pps")}
              />
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.includes('DNP')} onChange={handleCheckboxChange} value="DNP" />}
                label={t("healthform.Dnp")}
              />
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.includes('DNM')} onChange={handleCheckboxChange} value="DNM" />}
                label={t("healthform.Dnm")}
              />
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.includes('Other')} onChange={handleCheckboxChange} value="Other" />}
                label={t("healthform.Other")}
              />
             
            </FormGroup>
            <FormHelperText>{errorMessage.vivaSupportReceived}</FormHelperText>
            </FormControl>
          
          </Grid>

        </Grid>

        <Typography className="head">
          {t("healthform.PART D")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.D1.1")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset"error={!!errorMessage.safeguardingChildProtectionPolicy}>
                <RadioGroup aria-label="safeguardingChildProtectionPolicy" name="safeguardingChildProtectionPolicy" value={formData.safeguardingChildProtectionPolicy} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.safeguardingChildProtectionPolicy}</FormHelperText>
              </FormControl>
            </Box>
            {formData.safeguardingChildProtectionPolicy === "Yes" && (
              <Box marginTop={2}>
                <FormControl component="fieldset">
                  <Typography className="inputfield" variant="title">
                    {t("healthform.D1.2")}
                  </Typography>
                  <RadioGroup aria-label="safeguardingChildProtectionPolicyLastUpdated" name="safeguardingChildProtectionPolicyLastUpdated" value={formData.safeguardingChildProtectionPolicyLastUpdated} onChange={handleInputChange}>
                    <FormControlLabel value="3yrs" control={<Radio />} label={t("healthform.3y")} />
                    <FormControlLabel value="5yrs" control={<Radio />} label={t("healthform.5y")} />
                    <FormControlLabel value="1yr" control={<Radio />} label={t("healthform.notupdated")} />
                  </RadioGroup>
                </FormControl>

              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.D2.1")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.financePolicy}>
                <RadioGroup aria-label="financePolicy" name="financePolicy" value={formData.financePolicy} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.financePolicy}</FormHelperText>
              </FormControl>
            </Box>
            {formData.financePolicy === "Yes" && (
              <Box marginTop={2}>
                <FormControl component="fieldset">
                  <Typography className="inputfield" variant="title">
                    {t("healthform.D2.2")}
                  </Typography>
                  <RadioGroup aria-label="financePolicyLastUpdated" name="financePolicyLastUpdated" value={formData.financePolicyLastUpdated} onChange={handleInputChange}>
                    <FormControlLabel value="3yrs" control={<Radio />} label={t("healthform.3y")} />
                    <FormControlLabel value="5yrs" control={<Radio />} label={t("healthform.5y")} />
                    <FormControlLabel value="1yr" control={<Radio />} label={t("healthform.notupdated")} />
                  </RadioGroup>
                </FormControl>

              </Box>
            )}
          </Grid>
          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.D3")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.financesIndependentlyVerified}>
                <RadioGroup aria-label="financesIndependentlyVerified" name="financesIndependentlyVerified" value={formData.financesIndependentlyVerified} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.financesIndependentlyVerified}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.D4")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.financeHealthCheckCompleted}>
                <RadioGroup aria-label="financeHealthCheckCompleted" name="financeHealthCheckCompleted" value={formData.financeHealthCheckCompleted} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.financeHealthCheckCompleted}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.D5.1")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.governancePolicy}>
                <RadioGroup aria-label="governancePolicy" name="governancePolicy" value={formData.governancePolicy} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.governancePolicy}</FormHelperText>
              </FormControl>
            </Box>
            {formData.governancePolicy === "Yes" && (
              <Box marginTop={2}>
                <br />
                <FormControl component="fieldset">
                  <Typography className="inputfield" variant="title">
                    {t("healthform.D5.2")}
                  </Typography>
                  <RadioGroup aria-label="governancePolicyLastUpdated" name="governancePolicyLastUpdated" value={formData.governancePolicyLastUpdated} onChange={handleInputChange}>
                    <FormControlLabel value="3yrs" control={<Radio />} label={t("healthform.3y")} />
                    <FormControlLabel value="5yrs" control={<Radio />} label={t("healthform.5y")} />
                    <FormControlLabel value="1yr" control={<Radio />} label={t("healthform.notupdated")} />
                  </RadioGroup>
                </FormControl>

              </Box>
            )}
          </Grid>


          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.D6.1")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.legalRegistrationCompliance}>
                <RadioGroup aria-label="legalRegistrationCompliance" name="legalRegistrationCompliance" value={formData.legalRegistrationCompliance} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                  <FormControlLabel value="Partially" control={<Radio />} label={t("healthform.pp")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.legalRegistrationCompliance}</FormHelperText>
              </FormControl>
            </Box>
            {formData.legalRegistrationCompliance === "Partially" && (
              <Box marginTop={2}>
                <br />
                <Typography size="small" className="inputfield" variant="title">{t("healthform.D6.2")}</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="legalRegistrationSituation"
                  value={formData.legalRegistrationSituation}
                  onChange={handleInputChange}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.D7.1")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.hrPolicy}>
                <RadioGroup aria-label="hrPolicy" name="hrPolicy" value={formData.hrPolicy} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.hrPolicy}</FormHelperText>
              </FormControl>
            </Box>
            {formData.hrPolicy === "Yes" && (
              <Box marginTop={2}>
                <FormControl component="fieldset">
                  <Typography className="inputfield" variant="title">
                    {t("healthform.D7.2")}
                  </Typography>
                  <RadioGroup aria-label="hrPolicyLastUpdated" name="hrPolicyLastUpdated" value={formData.hrPolicyLastUpdated} onChange={handleInputChange}>
                    <FormControlLabel value="3yrs" control={<Radio />} label={t("healthform.3y")} />
                    <FormControlLabel value="5yrs" control={<Radio />} label={t("healthform.5y")} />
                    <FormControlLabel value="1yr" control={<Radio />} label={t("healthform.notupdated")} />
                  </RadioGroup>
                </FormControl>

              </Box>
            )}
          </Grid>

        </Grid>


        <Typography className="head">
          {t("healthform.PART E")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.E1")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.hasDatabaseOfOrganizations}>
                <RadioGroup aria-label="hasDatabaseOfOrganizations" name="hasDatabaseOfOrganizations" value={formData.hasDatabaseOfOrganizations} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.hasDatabaseOfOrganizations}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.E2")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.hasRecordOfMemberParticipation}>
                <RadioGroup aria-label="hasRecordOfMemberParticipation" name="hasRecordOfMemberParticipation" value={formData.hasRecordOfMemberParticipation} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.hasRecordOfMemberParticipation}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.E3")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.communicationFrequency}>
                <RadioGroup aria-label="communicationFrequency" name="communicationFrequency" value={formData.communicationFrequency} onChange={handleInputChange}>
                  <FormControlLabel value="More than once a week" control={<Radio />} label={t("healthform.week")} />
                  <FormControlLabel value="More than once a fortnight" control={<Radio />} label={t("healthform.fortnight")} />
                  <FormControlLabel value="More than once a month" control={<Radio />} label={t("healthform.month")} />
                  <FormControlLabel value="More than once a quarter" control={<Radio />} label={t("healthform.quarter")} />
                  <FormControlLabel value="More than once a year" control={<Radio />} label={t("healthform.year")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.communicationFrequency}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.E4")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="networkMeetingPercentage"
                value={formData.networkMeetingPercentage}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.networkMeetingPercentage}
                helperText={errorMessage.networkMeetingPercentage}
              />
            </Box>
          </Grid>


          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.E5")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.providesTrainingForMemberOrgs}>
                <RadioGroup aria-label="providesTrainingForMemberOrgs" name="providesTrainingForMemberOrgs" value={formData.providesTrainingForMemberOrgs} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.providesTrainingForMemberOrgs}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.E6")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.providesTrainingForNonMemberOrgs}>
                <RadioGroup aria-label="providesTrainingForNonMemberOrgs" name="providesTrainingForNonMemberOrgs" value={formData.providesTrainingForNonMemberOrgs} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.providesTrainingForNonMemberOrgs}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.E7")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="childProtection"
                value={formData.childProtection}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.childProtection}
                helperText={errorMessage.childProtection}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.E8")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="codeOfConduct"
                value={formData.codeOfConduct}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.codeOfConduct}
                helperText={errorMessage.codeOfConduct}
              />
            </Box>
          </Grid>


          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.E9")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.financialManagement}>
                <RadioGroup aria-label="financialManagement" name="financialManagement" value={formData.financialManagement} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.financialManagement}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.E10")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="finPolicy"
                value={formData.finPolicy}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.finPolicy}
                helperText={errorMessage.finPolicy}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.E11")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.hasSharedActivityDataSheet}>
                <RadioGroup aria-label="hasSharedActivityDataSheet" name="hasSharedActivityDataSheet" value={formData.hasSharedActivityDataSheet} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.hasSharedActivityDataSheet}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.E12")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="governmentLevelInitiatives"
                value={formData.governmentLevelInitiatives}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.governmentLevelInitiatives}
                helperText={errorMessage.governmentLevelInitiatives}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.E13")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="businessLeadersInitiatives"
                value={formData.businessLeadersInitiatives}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.businessLeadersInitiatives}
                helperText={errorMessage.businessLeadersInitiatives}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.E14")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="churchLeadersInitiatives"
                value={formData.churchLeadersInitiatives}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.churchLeadersInitiatives}
                helperText={errorMessage.churchLeadersInitiatives}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.E15")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="justiceSectorInitiatives"
                value={formData.justiceSectorInitiatives}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.justiceSectorInitiatives}
                helperText={errorMessage.justiceSectorInitiatives}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.E16.1")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.hasInfluencedPolicies}>
                <RadioGroup aria-label="hasInfluencedPolicies" name="hasInfluencedPolicies" value={formData.hasInfluencedPolicies} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.hasInfluencedPolicies}</FormHelperText>
              </FormControl>
            </Box>
            {formData.hasInfluencedPolicies === "Yes" && (
              <Box marginTop={2}>
                <br />
                <FormControl component="fieldset">
                  <Typography className="inputfield" variant="title">
                    {t("healthform.E16.2")}
                  </Typography>
                  <Box marginTop={2}>
                    <TextField
                      name="influencedPolicies"
                      value={formData.influencedPolicies}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="textfield"
                    />
                  </Box>
                </FormControl>

              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.E17")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="bestStory"
                value={formData.bestStory}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                size="small"
                className="textfield"
                error={!!errorMessage.bestStory}
                helperText={errorMessage.bestStory}
              />
            </Box>
          </Grid>

        </Grid>


        <Typography className="head">
          {t("healthform.PART F")}
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.F1")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="totalIncome"
                value={formData.totalIncome}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.totalIncome}
                helperText={errorMessage.totalIncome}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.F2")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="inCountryIncome"
                value={formData.inCountryIncome}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.inCountryIncome}
                helperText={errorMessage.inCountryIncome}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.F3")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="giftsInKind"
                value={formData.giftsInKind}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.giftsInKind}
                helperText={errorMessage.giftsInKind}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.F4")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.fundraisingStrategy}>
                <RadioGroup aria-label="fundraisingStrategy" name="fundraisingStrategy" value={formData.fundraisingStrategy} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.fundraisingStrategy}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.F5")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.fundraisingPlan}>
                <RadioGroup aria-label="fundraisingPlan" name="fundraisingPlan" value={formData.fundraisingPlan} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.fundraisingPlan}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Typography className="inputfield" variant="title">{t("healthform.F6")}</Typography>
            <Box marginTop={2}>
              <FormControl component="fieldset" error={!!errorMessage.fundraisingResponsibility}>
                <RadioGroup aria-label="fundraisingResponsibility" name="fundraisingResponsibility" value={formData.fundraisingResponsibility} onChange={handleInputChange}>
                  <FormControlLabel value="Yes" control={<Radio />} label={t("healthform.Yes")} />
                  <FormControlLabel value="No" control={<Radio />} label={t("healthform.No")} />
                </RadioGroup>
                <FormHelperText>{errorMessage.fundraisingResponsibility}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>


        </Grid>


        <Typography className="head">
          {t("healthform.PART G")}
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.G1")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="staffAndVolunteersCount"
                value={formData.staffAndVolunteersCount}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.staffAndVolunteersCount}
                helperText={errorMessage.staffAndVolunteersCount}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.G2")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="childrenReachedCount"
                value={formData.childrenReachedCount}
                onChange={handleInputChange}
                fullWidth
                size="small"
                type="number"
                className="textfield"
                error={!!errorMessage.childrenReachedCount}
                helperText={errorMessage.childrenReachedCount}
              />
            </Box>
          </Grid>

        </Grid>

        <Typography className="head">
          {t("healthform.PART H")}
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography className="inputfield" variant="title">{t("healthform.H1")}</Typography>

            <Box marginTop={2}>
              <TextField
                name="additionalComments"
                value={formData.additionalComments}
                onChange={handleInputChange}
                fullWidth
                size="small"
                className="textfield"
                error={!!errorMessage.additionalComments}
                helperText={errorMessage.additionalComments}
              />
            </Box>
          </Grid>

        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: "24px" }}>
          {(['', undefined, theUser?.id].indexOf(formData.userId) >= 0 && ["", "draft", "review"].indexOf(formData.status) >= 0) && (
            <Button variant="outlined" color="primary" onClick={handleSaveDraft}>
              {t("healthform.sd")}
            </Button>
          )}

          {console.log("formData.status:", formData.status)}
          {console.log("formData.theuser:", theUser.level)}
          {console.log("formData.userid:", formData.userLevel)}
          {(theUser?.id != formData.userId && ["submitted"].indexOf(formData.status) >= 0) && (
            <Button variant="outlined" color="primary" onClick={handleReview}>
              {t("healthform.rv")}
            </Button>
          )}


          {(['', undefined, theUser?.id].indexOf(formData.userId) >= 0 && ["", "draft", "review"].indexOf(formData.status) >= 0) && (
            <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>
              {t("healthform.sa")}
            </Button>
          )}

          {theUser?.id !== formData.userId && ['submitted', 'review'].indexOf(formData.status) >= 0 && user?.level < formData.userLevel && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleApprove}
            >
              {t("healthform.ap")}
            </Button>
          )}

        </Box>
      </form>
    )
  }

  console.log("formdata userId", formData.userId);
  return (
    <>
      {isMobileLoggedIn ? (
        renderhForm()

      ) : (

        <Dialog maxWidth="md" open onClose={handleDialogClose}>
          <DialogTitle className="heading">
            {id ? t("healthform.unhc") : t("healthform.Nhc")}
            <IconButton aria-label="close" onClick={handleDialogClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
      


          {error && <Alert severity="error">{error}</Alert>}
          {success && (
            <Alert severity="success">
              {id ? t("healthform.nhcu") : t("healthform.nhca")}
            </Alert>
          )}
          <DialogContent>
            {renderhForm()}
          </DialogContent>
        </Dialog>
      )}

      {isMobileLoggedIn && <><p>&nbsp;</p> <p>&nbsp;</p> </>}
    </>
  );
}

export default HealthForm