import { Grid } from '@mui/material';
import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const ScatterChart = ({ bubbleChartData }) => {
  console.log("bubble", bubbleChartData);
  const { t } = useTranslation();
  
  const bubbleChart = {
    datasets: [
      {
        data: bubbleChartData,
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  const bubbleChartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: t("chart.ae"),
        },
        beginAtZero: true,
      },
      y: {
        title: {
          display: true,
          text: t("chart.tcr"),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const point = bubbleChartData[context.dataIndex];
            return [
              `Activity description: ${point.label}`,
              `Child-Focussed Outcome: ${point.childOutcome}`,
              `Expenditure:${point.x}`,
              `#Children Reached: ${point.childrenReached}`,
            ];
          },
        }
        
      },
      legend: {
        display: false, 
      },
    },
  };

  return (
    <Grid>
      <Scatter data={bubbleChart} options={bubbleChartOptions} height={500} width={800} />
    </Grid>
  );
};

export default ScatterChart;