import React, { useState, useEffect, useContext } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { ArcElement } from "chart.js";
import Chart from "chart.js/auto";
import { config, axios } from '../config/config';
import { UserContext } from '../Components/UserContext';
import { Grid, Typography, Select, MenuItem, Box, TextField, Checkbox } from '@mui/material';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NetworkContext } from '../Context/NetworkContext';
import { RegionContext } from '../Context/RegionContext';
import ScatterChart from './ScatterChart';
import { useTranslation } from 'react-i18next';
import RadarChart from './RadarChart';

const BarChart8 = () => {
  const { token } = useContext(UserContext);
  const networks = useContext(NetworkContext);
  const regions = useContext(RegionContext);
  const { t } = useTranslation();
  const [city, setCity] = useState("");
  const [user, setUser] = useState("");
  const [usernet, setUsernet] = useState("");
  const [net, setNet] = useState("");
  const [py, setPy] = useState('');
  const [percent, setPercent] = useState("")
  const [percentincome, setPercentIncome] = useState("")
  const [percentchurch, setPercentChurch] = useState("")
  const [percentmember, setPercentMember] = useState("")
  const [activityData, setActivityData] = useState("");
  const [activityCounts, setActivityCounts] = useState({});
  let currentYear = new Date().getFullYear();
  const month = new Date().getMonth();
  if(month <= 9) {    // add only when the month beyond
    currentYear -= 1;
  }
  const [selectedYear, setSelectedYear] = useState(`${currentYear}-${currentYear + 1}`);
  const startMonth = 4;
  let [memberForYear2022, setMemberForYear2022] = useState(0);
  let [memberForYear2023, setMemberForYear2023] = useState(0);
  let [income2022, setIncome2022] = useState(0);
  let [income2023, setIncome2023] = useState(0);
  let [church2022, setChurch2022] = useState(0);
  let [church2023, setChurch2023] = useState(0);
  let [children2022, setChildren2022] = useState(0);
  let [children2023, setChildren2023] = useState(0);
  const [adultsInfluenceData, setAdultsInfluenceData] = useState({});
  const [childrenWithDisabilitiesData, setChildrenWithDisabilitiesData] = useState({});
  const [ucn, setUcn] = useState(0);
  const [purpose, setPurpose] = useState(0);
  const [strategy, setStrategy] = useState(0);
  const [staff, setStaff] = useState(0);
  const [score, setScore] = useState(0);
  const [bench6, setBench6] = useState(0);
  const [bench7, setBench7] = useState(0);
  const [bench8, setBench8] = useState(0);
  const [bench9, setBench9] = useState(0);
  const [bench10, setBench10] = useState(0);
  const [bench5, setBench5] = useState(0);
  const [averageMenParticipated, setAverageMenParticipated] = useState(0);
  const [averageWomenParticipated, setAverageWomenParticipated] = useState(0);
  const [averageGirlParticipated, setAverageGirlParticipated] = useState(0);
  const [averageBoyParticipated, setAverageBoyParticipated] = useState(0);
  const [expenditure, setExpenditure] = useState(0);
  const [bubbleChartData, setBubbleChartData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(['Safe']);
  const [selectedDriver, setSelectedDriver] = useState(['Connecting']);
  const [selectedOutcome, setSelectedOutcome] = useState(['Safe', 'Learning', 'Thriving']);
  const [fundingSourceData, setFundingSourceData] = useState({});
  const [name, setName] = useState("");
  const [gifts, setGifts] = useState("");
  const [hours, setHours] = useState("");
  const [womenDel, setWomenDel] = useState("");
  const [menDel, setMenDel] = useState("");
  const [members, setMembers] = useState("");
  const [nonmembers, setNonMembers] = useState("");
  const [child, setChild] = useState(0);
  const [formData, setFormData] = useState({
    network: "",
    country: "",
    region: ""
  });

  useEffect(() => {
    const [startYear] = selectedYear.split('-').map(Number);
    const calculatedPreviousYear = startYear - 1;
    setPy(`${calculatedPreviousYear}-${calculatedPreviousYear + 1}`);
  }, [selectedYear]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authResponse = await axios.get('/auth/me', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (authResponse.status === 200) {
          const authData = authResponse.data;
          console.log("auth data response", authData)
          setUser(authData);
          setUsernet(authData.network[0]);
          // const selectedauth = authData.network[0];
          const selectedauth = authData.network[0] === 'All' ? networks[0].id : authData.network[0];
          const selected = networks.find((network) => network._id === selectedauth);
   
          console.log('selectedauth', selectedauth);
          console.log('selected', selected);
          if (selected) {
            const selectedname = selected.name;
            setNet(selectedname);
            console.log('regions before selectedRegion', regions);
            const selectedRegion = regions.find((region) => region.regionId === selected.regionId);

            console.log('selectedRegion', selectedRegion);
            if (selectedRegion) {
              const { name: regionName, country } = selectedRegion;
              setFormData((prevState) => ({
                ...prevState,
                network: authData.network[0] === 'All' ? networks[0].id : authData.network[0],
                region: regionName,
                country: country || selected.countryName,
              }));
              const previousYear = getPreviousYear(selectedYear);
              console.log("previous year", previousYear);
              const years = [selectedYear, previousYear];

              let dataArray = [];

              try {
                setSelectedYear(selectedYear);

                const startYear = selectedYear.split('-')[0];
                const endYear = selectedYear.split('-')[1];

                const startDate = `${startYear}-0${startMonth}-01T00:00:00.000Z`;
                const endDate = `${parseInt(endYear)}-0${startMonth}-01T00:00:00.000Z`;

                const activityUrl = `/activity?limit=-1&startDate_gte=${startDate}&endDate_lte=${endDate}&network=${authData.network[0]}&status=approved`;

                console.log('Activity URL:', activityUrl);

                const activityResponse = await axios.get(activityUrl, {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                  },
                });

                if (activityResponse.status === 200) {
                  const activityData = activityResponse.data;
                  console.log('activity data', activityData);

                  if (Array.isArray(activityData.data)) {

                    console.log("activity data inside hbhsvgdc", activityData.data);
                    dataArray = activityData.data;

                    const numberOfActivities = dataArray.length;
                    setName(numberOfActivities);

                    const totalExpenditure = dataArray.reduce((sum, item) => sum + item.actualExpenditure, 0);
                    console.log("Total Actual Expenditure:", totalExpenditure);
                    setExpenditure(totalExpenditure);

                    const Kindgifts = dataArray.reduce((sum, item) => sum + item.estimatedInKindGifts, 0);
                    setGifts(Kindgifts);

                    const volunteerdays = dataArray.reduce((sum, item) => sum + item.volunteerDays, 0);
                    const volunteer = volunteerdays * 24;
                    setHours(volunteer);

                    const womenParticipated = dataArray.map((item) => item.womenParticipated);
                    const menParticipated = dataArray.map((item) => item.menParticipated);

                    const totalwomenParticipated = womenParticipated.reduce((sum, value) => sum + value, 0);
                    const totalmenParticipated = menParticipated.reduce((sum, value) => sum + value, 0);
                    const totalActivities = dataArray.length;

                    const avgwomenParticipated = totalwomenParticipated / totalActivities;
                    const avgmenParticipated = totalmenParticipated / totalActivities;

                    setAverageWomenParticipated(avgwomenParticipated);
                    setAverageMenParticipated(avgmenParticipated);

                    const girlsParticipated = dataArray.map((item) => item.girlsParticipated);
                    const boysParticipated = dataArray.map((item) => item.boysParticipated);

                    const totalgirlsParticipated = girlsParticipated.reduce((sum, value) => sum + value, 0);
                    const totalboysParticipated = boysParticipated.reduce((sum, value) => sum + value, 0);
                    const totalAct = dataArray.length;

                    const avggirlParticipated = totalgirlsParticipated / totalAct;
                    const avgboyParticipated = totalboysParticipated / totalAct;

                    setAverageGirlParticipated(avggirlParticipated);
                    setAverageBoyParticipated(avgboyParticipated);

                    const wdelivered = dataArray.map((item) => item.womenDelivered);
                    const mdelivered = dataArray.map((item) => item.menDelivered);

                    const totalwParticipated = wdelivered.reduce((sum, value) => sum + value, 0);
                    const totalmParticipated = mdelivered.reduce((sum, value) => sum + value, 0);
                    const total = dataArray.length;

                    const avgwomdelivered = totalwParticipated / total;
                    const avgmdelivered = totalmParticipated / total;

                    setWomenDel(avgwomdelivered);
                    setMenDel(avgmdelivered);

                    const netmem = dataArray.map((item) => item.networkMembers);
                    const nonmem = dataArray.map((item) => item.nonMembers);

                    const totalmem = netmem.reduce((sum, value) => sum + value, 0);
                    const totalnmem = nonmem.reduce((sum, value) => sum + value, 0);
                    const sum = dataArray.length;

                    const avgmem = totalmem / sum;
                    const avgnmem = totalnmem / sum;

                    setMembers(avgmem);
                    setNonMembers(avgnmem);

                    const bubbleData = dataArray.map(item => ({
                      x: item.actualExpenditure,
                      y: item.boysParticipated + item.girlsParticipated,
                      pointRadius: item.actualExpenditure,
                      label: item.name,
                      childrenReached: item.boysParticipated + item.girlsParticipated,
                      childOutcome: item.childOutcome,
                      x: item.actualExpenditure,
                    }));
                    console.log("bubbledata", bubbleData);
                    setBubbleChartData(bubbleData);

                    const fundingSources = {};
                    dataArray.forEach((item) => {
                      if (selectedOutcome.includes(item.childOutcome)) {
                        const fundingSource = item.fundingSource;
                        if (!fundingSources[fundingSource]) {
                          fundingSources[fundingSource] = {};
                          selectedOutcome.forEach((outcome) => {
                            fundingSources[fundingSource][outcome] = 0;
                          });
                        }
                        fundingSources[fundingSource][item.childOutcome] += item.actualExpenditure;
                      }
                    });

                    setFundingSourceData(fundingSources);

                    const childOutcomeCounts = {
                      Safe: 0,
                      Thriving: 0,
                      Learning: 0,
                    };
                    dataArray.forEach((item) => {
                      const { childOutcome } = item;
                      console.log('childOutcome:', childOutcome);
                      if (childOutcome === 'Safe') {
                        childOutcomeCounts.Safe++;
                      }
                      if (childOutcome === 'Thriving') {
                        childOutcomeCounts.Thriving++;
                      }
                      if (childOutcome === 'Learning') {
                        childOutcomeCounts.Learning++;
                      }
                    });

                    console.log('Final childOutcomeCounts:', childOutcomeCounts);
                    setChild(childOutcomeCounts);

                    const activityCounts = {};
                    dataArray.forEach((item) => {
                      for (const key in item) {
                        if (key.endsWith('Activity') && item[key]) {
                          const activityType = item[key] || 'Unknown';
                          activityCounts[activityType] = (activityCounts[activityType] || 0) + 1;
                        }
                      }
                    });

                    const activityCountArray = Object.values(activityCounts);
                    setActivityData(activityCountArray);
                    setActivityCounts(activityCounts);


                    let adultsInfluenceCount = 0;
                    let childrenWithDisabilitiesCount = 0;
                    let tActivities = 0;

                    dataArray.forEach((item) => {
                      if (item.adultsInfluence === 'Yes') {
                        adultsInfluenceCount++;
                      }
                      if (item.childrenWithDisabilities === 'Yes') {
                        childrenWithDisabilitiesCount++;
                      }
                      tActivities++;
                    });

                    const adultsInfluencePercentage = (adultsInfluenceCount / tActivities) * 100;
                    const childrenWithDisabilitiesPercentage = (childrenWithDisabilitiesCount / tActivities) * 100;

                    setAdultsInfluenceData({
                      label: 'Adults Influence',
                      percentage: adultsInfluencePercentage.toFixed(2),
                    });

                    setChildrenWithDisabilitiesData({
                      label: 'Children with Disabilities',
                      percentage: childrenWithDisabilitiesPercentage.toFixed(2),
                    });

                  } else {
                    console.error('Error fetching activity data: Invalid response data structure - Expected an array');
                  }
                } else {
                  console.error('Error fetching activity data: Invalid response structure');
                }
              } catch (error) {
                console.error('Error fetching data:', error);
              }

              try {
                const healthResponse = await axios.get(`/health?limit=-1&selectedYear_in=${years.join(',')}&network=${authData.network[0] === 'All' ? networks[0].id : authData.network[0]}&status=approved`, {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                  },
                });

                if (healthResponse.status === 200) {
                  console.log("helath api", healthResponse.config.url);
                  const cityData = healthResponse.data.data;
                  console.log("health data inside handle input ", cityData);

                  const cities = cityData.map(item => item.city);
                  console.log("cities", cities);

                  const organizationMembersCountForYear2022 = cityData
                    .filter(item => item.selectedYear === previousYear)
                    .map(item => item.organizationMembersCount);

                  const organizationMembersCountForYear2023 = cityData
                    .filter(item => item.selectedYear === selectedYear)
                    .map(item => item.organizationMembersCount);

                  const totalMembers2022 = organizationMembersCountForYear2022.reduce((sum, value) => sum + value, 0);
                  const totalMembers2023 = organizationMembersCountForYear2023.reduce((sum, value) => sum + value, 0);

                  setMemberForYear2022(totalMembers2022);
                  setMemberForYear2023(totalMembers2023);

                  const incomeYear2022 = cityData
                    .filter(item => item.selectedYear === previousYear)
                    .map(item => item.totalIncome);

                  const incomeYear2023 = cityData
                    .filter(item => item.selectedYear === selectedYear)
                    .map(item => item.totalIncome);

                  const totalIncome2022 = incomeYear2022.reduce((sum, value) => sum + value, 0);
                  const totalIncome2023 = incomeYear2023.reduce((sum, value) => sum + value, 0);

                  setIncome2022(totalIncome2022);
                  setIncome2023(totalIncome2023);

                  const churchYear2022 = cityData
                    .filter(item => item.selectedYear === previousYear)
                    .map(item => item.churchMembersCount);

                  const churchYear2023 = cityData
                    .filter(item => item.selectedYear === selectedYear)
                    .map(item => item.churchMembersCount);

                  const totalChurch2022 = churchYear2022.reduce((sum, value) => sum + value, 0);
                  const totalChurch2023 = churchYear2023.reduce((sum, value) => sum + value, 0);

                  setChurch2022(totalChurch2022);
                  setChurch2023(totalChurch2023);

                  const childrenYear2022 = cityData
                    .filter(item => item.selectedYear === previousYear)
                    .map(item => item.childrenReachedCount);

                  const childrenYear2023 = cityData
                    .filter(item => item.selectedYear === selectedYear)
                    .map(item => item.childrenReachedCount);

                  const totalChildren2022 = childrenYear2022.reduce((sum, value) => sum + value, 0);
                  const totalChildren2023 = childrenYear2023.reduce((sum, value) => sum + value, 0);


                  setChildren2022(totalChildren2022);
                  setChildren2023(totalChildren2023);

                  const selectedYearData = cityData
                    .filter(item => item.selectedYear === selectedYear);

                  const previousYearData = cityData
                    .filter(item => item.selectedYear === previousYear);

                  const selectedYearChildren = selectedYearData.length > 0 ? selectedYearData.map(item => item.childrenReachedCount || 0) : [0];
                  const previousYearChildren = previousYearData.length > 0 ? previousYearData.map(item => item.childrenReachedCount || 0) : [0];

                  const selectedYearMember = selectedYearData.length > 0 ? selectedYearData.map(item => item.organizationMembersCount || 0) : [0];
                  const previousYearMember = previousYearData.length > 0 ? previousYearData.map(item => item.organizationMembersCount || 0) : [0];

                  const selectedYearIncome = selectedYearData.length > 0 ? selectedYearData.map(item => item.totalIncome || 0) : [0];
                  const previousYearIncome = previousYearData.length > 0 ? previousYearData.map(item => item.totalIncome || 0) : [0];

                  const selectedYearChurch = selectedYearData.length > 0 ? selectedYearData.map(item => item.churchMembersCount || 0) : [0];
                  const previousYearChurch = previousYearData.length > 0 ? previousYearData.map(item => item.churchMembersCount || 0) : [0];

                  let percentageDifference = ((selectedYearChildren - previousYearChildren) / previousYearChildren) * 100;
                  percentageDifference = Math.min(percentageDifference, 100);

                  let result;
                  if (percentageDifference > 0) {
                    result = "increase";
                  } else if (percentageDifference < 0) {
                    result = "decrease";
                  } else {
                    result = "no change";
                  }

                  const percentageInfoText = `${percentageDifference.toFixed(2)}%, ${result}`;
                  setPercent(percentageInfoText);

                  let percentageDifferenceincome = ((selectedYearIncome - previousYearIncome) / previousYearIncome) * 100;
                  percentageDifferenceincome = Math.min(percentageDifferenceincome, 100);

                  let resultincome;
                  if (percentageDifferenceincome > 0) {
                    resultincome = "increase";
                  } else if (percentageDifferenceincome < 0) {
                    resultincome = "decrease";
                  } else {
                    resultincome = "no change";
                  }

                  const percentageIncome = `${percentageDifferenceincome.toFixed(2)}%, ${resultincome}`;
                  console.log("prcentage income", percentageIncome)
                  setPercentIncome(percentageIncome);

                  let percentageDifferencechurch = ((selectedYearChurch - previousYearChurch) / previousYearChurch) * 100;
                  percentageDifferencechurch = Math.min(percentageDifferencechurch, 100);

                  let resultchurch;
                  if (percentageDifferencechurch > 0) {
                    resultchurch = "increase";
                  } else if (percentageDifferencechurch < 0) {
                    resultchurch = "decrease";
                  } else {
                    resultchurch = "no change";
                  }

                  const percentageChurch = `${percentageDifferencechurch.toFixed(2)}%, ${resultchurch}`;
                  setPercentChurch(percentageChurch);

                  let percentageDifferencemember = ((selectedYearMember - previousYearMember) / previousYearMember) * 100;
                  percentageDifferencemember = Math.min(percentageDifferencemember, 100);

                  let resultmember;
                  if (percentageDifferencemember > 0) {
                    resultmember = "increase";
                  } else if (percentageDifferencemember < 0) {
                    resultmember = "decrease";
                  } else {
                    resultmember = "no change";
                  }

                  const percentageMember = `${percentageDifferencemember.toFixed(2)}%, ${resultmember}`;
                  setPercentMember(percentageMember);

                  const filteredCityData = cityData.filter(item => item.selectedYear === selectedYear);

                  const convertAndSum = (array) => {
                    const convertedArray = array.map((value) => {
                      if (value === "Yes") {
                        return 1;
                      } else {
                        return 0;
                      }
                    });

                    return convertedArray.reduce((sum, value) => sum + value, 0);
                  };

                  const Ucn = filteredCityData.map((item) => item.situationalMapping);
                  console.log('Ucn.....', Ucn);
                  // const ucnSum = convertAndSum(Ucn);
                  const ucnSum = Ucn[0] == 'Yes' ? 10 : 0;
                  setUcn(ucnSum);

                  let totalCityCount = 0;
                  const valuesToCheck = ['SWPME', 'SWF', 'DNP'];
                  // cityData.forEach(city => {
                  //   const vivaSupportReceived = city.vivaSupportReceived || [];
                  //   let count = 0;

                  //   vivaSupportReceived.forEach(value => {
                  //     if (valuesToCheck.includes(value)) {
                  //       count++;
                  //     }
                  //   });
                  //   if (city.hasLongTermPartnership === "Yes") {
                  //     count++;
                  //   }
                  //   if (city.hasConsultancyAgreement === "Yes") {
                  //     count++;
                  //   }
                  //   totalCityCount += count;
                  // })

                  const longTermPartnership = filteredCityData.map((item) => item.hasLongTermPartnership)[0] == 'Yes' ? 4 : 0;
                  const consultancyAgreement = filteredCityData.map((item) => item.hasConsultancyAgreement)[0] == 'Yes' ? 4 : 0;
                  const valueSupport = filteredCityData.map((item) => item.vivaSupportReceived)[0];

                  let valueSupportRMSum = valueSupport?.includes('RM') ? 2 : 0;

                  let valueSupportOtherSum = 0;
                  if(valueSupportRMSum <= 0) {
                    if ( valueSupport?.length >= 2 ) {
                      valueSupportOtherSum = 2;
                    }
                    else if( valueSupport?.length == 1 ) {
                      valueSupportOtherSum = 1;
                    }
                  }

                  totalCityCount = longTermPartnership + consultancyAgreement + valueSupportRMSum + valueSupportOtherSum;
                  setBench5(totalCityCount);

                  const visionSum = filteredCityData.map((item) => item.visionAndMission)[0] == 'Yes' ? 4 : 0;
                  const descSum = filteredCityData.map((item) => item.promotionalDescription)[0] == 'Yes' ? 2 : 0;
                  const disSum = filteredCityData.map((item) => item.distinctives)[0] == 'Yes' ? 2 : 0;
                  const paSum = filteredCityData.map((item) => item.passionateAbout)[0] == 'Yes' ? 2 : 0;
                  const totalSum = visionSum + descSum + disSum + paSum;
                  setPurpose(totalSum);

                  const splan = filteredCityData.map((item) => item.strategicPlan);
                  const strategySum = splan == 'Yes' ? 10 : 0;
                  setStrategy(strategySum);

                  function Sum(array) {
                    const convertedArray = array.map((value) => {
                      if (value === "Yes") {
                        return 1;
                      } else {
                        return 0;
                      }
                    });

                    return convertedArray.reduce((sum, value) => sum + value, 0);
                  }

                  const b1 = filteredCityData.map((item) => item.hasNetworkCoordination)[0] == 'Yes' ? 3 : 0;
                  const b4 = filteredCityData.map((item) => item.staffRolesAndObjectives)[0] == 'Yes' ? 3 : 0;
                  const b5 = filteredCityData.map((item) => item.hasSteeringGroup)[0] == 'Yes' ? 2 : 0;
                  const b7 = filteredCityData.map((item) => item.hasWorkingGroups)[0] == 'Yes' ? 2 : 0;



                  const d1 = filteredCityData.map((item) => item.safeguardingChildProtectionPolicy)[0] == 'Yes' ? 2 : 0;
                  const d2 = filteredCityData.map((item) => item.financePolicy)[0] == 'Yes' ? 2 : 0;
                  const d3 = filteredCityData.map((item) => item.financeHealthCheckCompleted)[0] == 'Yes' ? 2 : 0;
                  const d4 = filteredCityData.map((item) => item.governancePolicy)[0] == 'Yes' ? 2 : 0;
                  const lr = filteredCityData.map((item) => item.legalRegistrationCompliance)[0];
                  let d6 = 0;
                  if(lr == 'Yes') {
                    d6 = 2;
                  }
                  else if ( lr == 'Partially') {
                    d6 = 1;
                  }

                  const e1 = filteredCityData.map((item) => item.hasDatabaseOfOrganizations)[0] == 'Yes' ? 5 : 0;
                  const e2 = filteredCityData.map((item) => item.hasRecordOfMemberParticipation)[0] == 'Yes' ? 5 : 0;

                  const e11 = filteredCityData.map((item) => item.hasSharedActivityDataSheet)[0] != 'Yes' && dataArray.length <= 0 ? 0 : 4;
                  const activityFormsCount = dataArray.length >= 4 ? 3 : 0;
                  let safe = 0;
                  let thrive = 0;
                  let learn = 0;

                  dataArray.map((d) => {
                    if(d.childOutcome == 'Safe') {
                      safe = 1;
                    }
                    else if (d.childOutcome == 'Thriving') {
                      thrive = 1;
                    }
                    else {
                      learn = 1;
                    }
                  })

                  const activityAcrossNetwork = safe + thrive + learn > 1 ? 3 : 0;

                  const e12 = filteredCityData.map((item) => item.governmentLevelInitiatives)[0] >= 1 ? 2 : 0;
                  const e13 = filteredCityData.map((item) => item.businessLeadersInitiatives)[0] >= 1 ? 2 : 0;
                  const e14 = filteredCityData.map((item) => item.churchLeadersInitiatives)[0] >= 1 ? 2 : 0;
                  const e15 = filteredCityData.map((item) => item.justiceSectorInitiatives)[0] >= 1 ? 2 : 0;
                  const e16 = filteredCityData.map((item) => item.hasInfluencedPolicies) == 'Yes' ? 2 : 0;

                  const f1Val = filteredCityData.map((item) => item.totalIncome)[0];
                  let f1 = 0;
                  if(f1Val >= 25000) {
                    f1 = 2;
                  }
                  else if(f1Val < 25000 && f1Val >= 5000) {
                    f1 = 1;
                  }

                  const f2Val = filteredCityData.map((item) => item.inCountryIncome)[0];
                  let f2 = 0;
                  if(f2Val >= 25000) {
                    f2 = 2;
                  }
                  else if(f2Val < 25000 && f2Val >= 5000) {
                    f2 = 1;
                  }

                  const f3Val = filteredCityData.map((item) => item.giftsInKind)[0];
                  let f3 = 0;
                  if(f3Val >= 25000) {
                    f3 = 2;
                  }
                  else if(f3Val < 25000 && f3Val >= 5000) {
                    f3 = 1;
                  }

                  const f4 = filteredCityData.map((item) => item.fundraisingStrategy)[0] == 'Yes' ? 2 : 0;
                  const f5 = filteredCityData.map((item) => item.fundraisingPlan)[0] == 'Yes' ? 2 : 0;

                  const total = b1 + b4 + b5 + b7;
                  const total2 = d1 + d2 + d3 + d4 + d6;
                  const total3 = e1 + e2;
                  const total4 = e11 + activityFormsCount + activityAcrossNetwork;
                  const total5 = e12 + e13 + e14 + e15 + e16;
                  const total6 = f1 + f2 + f3 + f4 + f5;
                  const totalscore = total + total2 + total3 + total4 + total5 + total6 + totalCityCount + ucnSum + totalSum + strategySum
                  console.log("total score", totalscore)

                  setStaff(total);
                  setBench6(total2);
                  setBench7(total3);
                  setBench8(total4);
                  setBench9(total5);
                  setBench10(total6);
                  setCity(cities);
                  setScore(totalscore)


                }
              } catch (error) {
                console.error('Error fetching health data:', error);
              }

            } else {
              console.error('Region not found for the selected network.');
            }
          } else {
            console.error('Network not found in the networks context.');
          }
        } else {
          console.error('Error fetching auth data: Invalid response status');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(selectedYear);
    // }, [selectedYear]);
  }, []);

  const adultsInfluenceChart = {
    labels: [`Yes: ${adultsInfluenceData.percentage}%`, `No: ${(100 - adultsInfluenceData.percentage).toFixed(2)}%`],
    datasets: [
      {
        data: [adultsInfluenceData.percentage, 100 - adultsInfluenceData.percentage],
        backgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  const cwdChartData = {
    labels: [`${t("chart.yes")}: ${childrenWithDisabilitiesData.percentage}%`, `${t("chart.no")}: ${(100 - childrenWithDisabilitiesData.percentage).toFixed(2)}%`],
    datasets: [
      {
        data: [childrenWithDisabilitiesData.percentage, 100 - childrenWithDisabilitiesData.percentage],
        backgroundColor: ['#FFCE56', '#36A2EB'],
      },
    ],
  };

  console.log('activityCounts',Object.keys(activityCounts));
  console.log('akeys', Object.keys(activityCounts));
  let tt = 'Treatment';
  console.log('translated', t(`activityform.${tt}`));
  const activityKeys = Object.keys(activityCounts).map(k => { return t(`activityform.${k}`) });
  console.log('activityKeys', activityKeys);

  const chartData12 = {
    labels: activityKeys,
    datasets: [
      {
        label: t("chart.activity_count"),
        data: activityData,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
  console.log('chartData12:', chartData12);

  const chartOptions12 = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.noa"),
        },
      },
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const chartData1 = {
    labels: [py, selectedYear],
    datasets: [
      {
        label: t("chart.children"),
        data: [children2022 || 0, children2023 || 0],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const chartData11 = {
    labels: [t("chart.S"), t("chart.T"), t("chart.L")],
    datasets: [
      {
        label: t("chart.cf"),
        data: Object.values(child),
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 99, 132, 0.6)',
        ],
        borderWidth: 1,
      },
    ],
  };

  console.log('chartData11:', chartData11);

  const chartData2 = {
    labels: [py, selectedYear],
    datasets: [
      {
        label: t("chart.churches"),
        data: [church2022 || 0, church2023 || 0],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const chartData3 = {
    labels: [py, selectedYear],
    datasets: [
      {
        label: t("chart.organisation"),
        data: [memberForYear2022 || 0, memberForYear2023 || 0],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const chartData4 = {
    labels: [py, selectedYear],
    datasets: [
      {
        label: t("chart.income"),
        data: [income2022 || 0, income2023 || 0],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };
  { console.log("chart data 4", chartData4) }
  const chartData5 = {
    labels: [t("chart.women"), t("chart.men")],
    datasets: [
      {
        label: t("chart.avg"),
        data: [averageWomenParticipated, averageMenParticipated],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };
  const chartData6 = {
    labels: [t("chart.girls"), t("chart.boys")],
    datasets: [
      {
        label: t("chart.avg_part"),
        data: [averageGirlParticipated, averageBoyParticipated],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const chartData7 = {
    labels: [t("chart.women"), t("chart.men")],
    datasets: [
      {
        label: t("chart.avg_activities"),
        data: [womenDel, menDel],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };
  const chartData8 = {
    labels: [t("chart.Members"), t("chart.nm")],
    datasets: [
      {
        label: t("chart.avg_churches"),
        data: [members, nonmembers],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const fundingSourceLabels = Object.keys(fundingSourceData);
  const outcomeDatasets = selectedOutcome.map(outcome => ({
    label: outcome,
    data: fundingSourceLabels.map(label => fundingSourceData[label][outcome]),
  }));

  console.log('selectedOutcome', selectedOutcome);
  const chartData9 = {
    labels: selectedOutcome,
    datasets: fundingSourceLabels.map((fundingSource, index) => ({
      label: fundingSource,
      data: selectedOutcome.map((outcome) => fundingSourceData[fundingSource][outcome]),
      backgroundColor: `rgba(75, 192, 192, ${0.5 - (index * 0.1)})`,
      borderColor: `rgba(75, 192, 192, 1)`,
      stack: 'stack',
    })),
  };
  const backgroundColors = [
    'rgba(75, 192, 192, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(255, 205, 86, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(255, 0, 0, 0.5)',
    'rgba(0, 255, 0, 0.5)',
    'rgba(0, 0, 255, 0.5)',
    'rgba(255, 128, 0, 0.5)',
  ];

  chartData9.datasets.forEach((dataset, index) => {
    dataset.backgroundColor = backgroundColors[index % backgroundColors.length];
  });

  const chartOptions9 = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.ae"),
        },
      },
      x: {
        title: {
          display: true,
          text: t("chart.sof"),
        },
      },
    },
    plugins: {
    //   legend: {
    //     display: false,
    //   },
      // title: {
      //   display: true,
      //   text: (context) => {
      //     return fundingSourceLabels.join(', ');
      //   },
      //   position: 'top',
      //   align: 'center',
      //   font: {
      //     size: 14,
      //   },
      // },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
    },
  };
  chartOptions9.scales.x.stacked = true;

  const chartOptions11 = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
        },
      },
      x: {
        title: {
          display: true,
          text: t("chart.cf"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    }
  };

  const chartOptions2 = {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.cn"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: (context) => {
          return `${percentchurch}`;
        },
        position: 'top',
        align: 'center',
        font: {
          size: 14,
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    barPercentage: 0.3,
    categoryPercentage: 0.5,
  };

  const chartOptions1 = {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.cr"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: (context) => {
          return `${percent}`;
        },
        position: 'top',
        align: 'center',
        font: {
          size: 14,
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    barPercentage: 0.3,
    categoryPercentage: 0.5,
  };

  const chartOptions3 = {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.on"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: (context) => {
          return `${percentmember}`;
        },
        position: 'top',
        align: 'center',
        font: {
          size: 14,
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    barPercentage: 0.3,
    categoryPercentage: 0.5,
  };

  const chartOptions4 = {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.ti"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: (context) => {
          return `${percentincome}`;
        },
        position: 'top',
        align: 'center',
        font: {
          size: 14,
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    barPercentage: 0.3,
    categoryPercentage: 0.5,
  };

  const chartOptions5 = {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 11,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.aapa"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    barPercentage: 0.3,
    categoryPercentage: 0.5,
  };

  const chartOptions6 = {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 11,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.acda"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    barPercentage: 0.3,
    categoryPercentage: 0.5,
  };

  const chartOptions7 = {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 11,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.aada"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    barPercentage: 0.3,
    categoryPercentage: 0.5,
  };

  const chartOptions8 = {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 11,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t("chart.aco"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    barPercentage: 0.3,
    categoryPercentage: 0.5,
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    const selectedNetworkId = event.target.value;
    const selectedNetwork = networks.find((network) => network.id === selectedNetworkId);

    if (selectedNetwork) {
      const { regionId } = selectedNetwork;
      const selectedNetworkName = selectedNetwork.name;
      setNet(selectedNetworkName);

      const selectedRegion = regions.find((region) => region.regionId === regionId);

      if (selectedRegion) {
        const { name: regionName, country } = selectedRegion;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
          region: regionName,
          country: selectedNetwork?.countryName,
        }));
        const previousYear = getPreviousYear(selectedYear);
        console.log("previous year", previousYear);
        const years = [selectedYear, previousYear];

        let dataArray = [];

        try {
          setSelectedYear(selectedYear);
          const startYear = selectedYear.split('-')[0];
          const endYear = selectedYear.split('-')[1];

          const startDate = `${startYear}-0${startMonth}-01T00:00:00.000Z`;
          const endDate = `${parseInt(endYear)}-0${startMonth}-01T00:00:00.000Z`;

          const activityUrl = `/activity?limit=-1&startDate_gte=${startDate}&endDate_lte=${endDate}&network=${selectedNetwork.id}&status=approved`;

          console.log('Activity URL:', activityUrl);

          const activityResponse = await axios.get(activityUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (activityResponse.status === 200) {
            const activityData = activityResponse.data;
            console.log('activity data', activityData);

            if (Array.isArray(activityData.data)) {

              console.log("activity data inside hbhsvgdc", activityData.data);
              dataArray = activityData.data;

              const numberOfActivities = dataArray.length;
              setName(numberOfActivities);

              const totalExpenditure = dataArray.reduce((sum, item) => sum + item.actualExpenditure, 0);
              console.log("Total Actual Expenditure:", totalExpenditure);
              setExpenditure(totalExpenditure);

              const Kindgifts = dataArray.reduce((sum, item) => sum + item.estimatedInKindGifts, 0);
              setGifts(Kindgifts);

              const volunteerdays = dataArray.reduce((sum, item) => sum + item.volunteerDays, 0);
              const volunteer = volunteerdays * 24;
              setHours(volunteer);

              const womenParticipated = dataArray.map((item) => item.womenParticipated);
              const menParticipated = dataArray.map((item) => item.menParticipated);

              const totalwomenParticipated = womenParticipated.reduce((sum, value) => sum + value, 0);
              const totalmenParticipated = menParticipated.reduce((sum, value) => sum + value, 0);
              const totalActivities = dataArray.length;

              const avgwomenParticipated = totalwomenParticipated / totalActivities;
              const avgmenParticipated = totalmenParticipated / totalActivities;

              setAverageWomenParticipated(avgwomenParticipated);
              setAverageMenParticipated(avgmenParticipated);

              const girlsParticipated = dataArray.map((item) => item.girlsParticipated);
              const boysParticipated = dataArray.map((item) => item.boysParticipated);

              const totalgirlsParticipated = girlsParticipated.reduce((sum, value) => sum + value, 0);
              const totalboysParticipated = boysParticipated.reduce((sum, value) => sum + value, 0);
              const totalAct = dataArray.length;

              const avggirlParticipated = totalgirlsParticipated / totalAct;
              const avgboyParticipated = totalboysParticipated / totalAct;

              setAverageGirlParticipated(avggirlParticipated);
              setAverageBoyParticipated(avgboyParticipated);

              const wdelivered = dataArray.map((item) => item.womenDelivered);
              const mdelivered = dataArray.map((item) => item.menDelivered);

              const totalwParticipated = wdelivered.reduce((sum, value) => sum + value, 0);
              const totalmParticipated = mdelivered.reduce((sum, value) => sum + value, 0);
              const total = dataArray.length;

              const avgwomdelivered = totalwParticipated / total;
              const avgmdelivered = totalmParticipated / total;

              setWomenDel(avgwomdelivered);
              setMenDel(avgmdelivered);

              const netmem = dataArray.map((item) => item.networkMembers);
              const nonmem = dataArray.map((item) => item.nonMembers);

              const totalmem = netmem.reduce((sum, value) => sum + value, 0);
              const totalnmem = nonmem.reduce((sum, value) => sum + value, 0);
              const sum = dataArray.length;

              const avgmem = totalmem / sum;
              const avgnmem = totalnmem / sum;

              setMembers(avgmem);
              setNonMembers(avgnmem);

              const bubbleData = dataArray.map(item => ({
                x: item.actualExpenditure,
                y: item.boysParticipated + item.girlsParticipated,
                pointRadius: item.actualExpenditure,
                label: item.name,
                childrenReached: item.boysParticipated + item.girlsParticipated,
                childOutcome: item.childOutcome,
                x: item.actualExpenditure,
              }));
              console.log("bubbledata", bubbleData);
              setBubbleChartData(bubbleData);

              const fundingSources = {};
              dataArray.forEach((item) => {
                if (selectedOutcome.includes(item.childOutcome)) {
                  const fundingSource = item.fundingSource;
                  if (!fundingSources[fundingSource]) {
                    fundingSources[fundingSource] = {};
                    selectedOutcome.forEach((outcome) => {
                      fundingSources[fundingSource][outcome] = 0;
                    });
                  }
                  fundingSources[fundingSource][item.childOutcome] += item.actualExpenditure;
                }
              });

              setFundingSourceData(fundingSources);

              const childOutcomeCounts = {
                Safe: 0,
                Thriving: 0,
                Learning: 0,
              };
              dataArray.forEach((item) => {
                const { childOutcome } = item;
                console.log('childOutcome:', childOutcome);
                if (childOutcome === 'Safe') {
                  childOutcomeCounts.Safe++;
                }
                if (childOutcome === 'Thriving') {
                  childOutcomeCounts.Thriving++;
                }
                if (childOutcome === 'Learning') {
                  childOutcomeCounts.Learning++;
                }
              });

              console.log('Final childOutcomeCounts:', childOutcomeCounts);
              setChild(childOutcomeCounts);

              const activityCounts = {};
              dataArray.forEach((item) => {
                for (const key in item) {
                  if (key.endsWith('Activity') && item[key]) {
                    const activityType = item[key] || 'Unknown';
                    activityCounts[activityType] = (activityCounts[activityType] || 0) + 1;
                  }
                }
              });

              const activityCountArray = Object.values(activityCounts);
              setActivityData(activityCountArray);
              setActivityCounts(activityCounts);


              let adultsInfluenceCount = 0;
              let childrenWithDisabilitiesCount = 0;
              let tActivities = 0;

              dataArray.forEach((item) => {
                if (item.adultsInfluence === 'Yes') {
                  adultsInfluenceCount++;
                }
                if (item.childrenWithDisabilities === 'Yes') {
                  childrenWithDisabilitiesCount++;
                }
                tActivities++;
              });

              const adultsInfluencePercentage = (adultsInfluenceCount / tActivities) * 100;
              const childrenWithDisabilitiesPercentage = (childrenWithDisabilitiesCount / tActivities) * 100;

              setAdultsInfluenceData({
                label: 'Adults Influence',
                percentage: adultsInfluencePercentage.toFixed(2),
              });

              setChildrenWithDisabilitiesData({
                label: 'Children with Disabilities',
                percentage: childrenWithDisabilitiesPercentage.toFixed(2),
              });

            } else {
              console.error('Error fetching activity data: Invalid response data structure - Expected an array');
            }
          } else {
            console.error('Error fetching activity data: Invalid response structure');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }

        try {
          const healthResponse = await axios.get(`/health?limit=-1&selectedYear_in=${years.join(',')}&network=${selectedNetwork.id}&status=approved`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (healthResponse.status === 200) {
            console.log("helath api", healthResponse.config.url);
            const cityData = healthResponse.data.data;
            console.log("health data inside handle input ", cityData);

            const cities = cityData.map(item => item.city);
            console.log("cities", cities);

            const organizationMembersCountForYear2022 = cityData
              .filter(item => item.selectedYear === previousYear)
              .map(item => item.organizationMembersCount);

            const organizationMembersCountForYear2023 = cityData
              .filter(item => item.selectedYear === selectedYear)
              .map(item => item.organizationMembersCount);

            const totalMembers2022 = organizationMembersCountForYear2022.reduce((sum, value) => sum + value, 0);
            const totalMembers2023 = organizationMembersCountForYear2023.reduce((sum, value) => sum + value, 0);

            setMemberForYear2022(totalMembers2022);
            setMemberForYear2023(totalMembers2023);

            const incomeYear2022 = cityData
              .filter(item => item.selectedYear === previousYear)
              .map(item => item.totalIncome);

            const incomeYear2023 = cityData
              .filter(item => item.selectedYear === selectedYear)
              .map(item => item.totalIncome);

            const totalIncome2022 = incomeYear2022.reduce((sum, value) => sum + value, 0);
            const totalIncome2023 = incomeYear2023.reduce((sum, value) => sum + value, 0);

            setIncome2022(totalIncome2022);
            setIncome2023(totalIncome2023);

            const churchYear2022 = cityData
              .filter(item => item.selectedYear === previousYear)
              .map(item => item.churchMembersCount);

            const churchYear2023 = cityData
              .filter(item => item.selectedYear === selectedYear)
              .map(item => item.churchMembersCount);

            const totalChurch2022 = churchYear2022.reduce((sum, value) => sum + value, 0);
            const totalChurch2023 = churchYear2023.reduce((sum, value) => sum + value, 0);

            setChurch2022(totalChurch2022);
            setChurch2023(totalChurch2023);

            const childrenYear2022 = cityData
              .filter(item => item.selectedYear === previousYear)
              .map(item => item.childrenReachedCount);

            const childrenYear2023 = cityData
              .filter(item => item.selectedYear === selectedYear)
              .map(item => item.childrenReachedCount);

            const totalChildren2022 = childrenYear2022.reduce((sum, value) => sum + value, 0);
            const totalChildren2023 = childrenYear2023.reduce((sum, value) => sum + value, 0);


            setChildren2022(totalChildren2022);
            setChildren2023(totalChildren2023);

            const selectedYearData = cityData
              .filter(item => item.selectedYear === selectedYear);

            const previousYearData = cityData
              .filter(item => item.selectedYear === previousYear);

            const selectedYearChildren = selectedYearData.length > 0 ? selectedYearData.map(item => item.childrenReachedCount || 0) : [0];
            const previousYearChildren = previousYearData.length > 0 ? previousYearData.map(item => item.childrenReachedCount || 0) : [0];

            const selectedYearMember = selectedYearData.length > 0 ? selectedYearData.map(item => item.organizationMembersCount || 0) : [0];
            const previousYearMember = previousYearData.length > 0 ? previousYearData.map(item => item.organizationMembersCount || 0) : [0];

            const selectedYearIncome = selectedYearData.length > 0 ? selectedYearData.map(item => item.totalIncome || 0) : [0];
            const previousYearIncome = previousYearData.length > 0 ? previousYearData.map(item => item.totalIncome || 0) : [0];

            const selectedYearChurch = selectedYearData.length > 0 ? selectedYearData.map(item => item.churchMembersCount || 0) : [0];
            const previousYearChurch = previousYearData.length > 0 ? previousYearData.map(item => item.churchMembersCount || 0) : [0];

            let percentageDifference = ((selectedYearChildren - previousYearChildren) / previousYearChildren) * 100;
            percentageDifference = Math.min(percentageDifference, 100);

            let result;
            if (percentageDifference > 0) {
              result = "increase";
            } else if (percentageDifference < 0) {
              result = "decrease";
            } else {
              result = "no change";
            }

            const percentageInfoText = `${percentageDifference.toFixed(2)}%, ${result}`;
            setPercent(percentageInfoText);

            let percentageDifferenceincome = ((selectedYearIncome - previousYearIncome) / previousYearIncome) * 100;
            percentageDifferenceincome = Math.min(percentageDifferenceincome, 100);

            let resultincome;
            if (percentageDifferenceincome > 0) {
              resultincome = "increase";
            } else if (percentageDifferenceincome < 0) {
              resultincome = "decrease";
            } else {
              resultincome = "no change";
            }

            const percentageIncome = `${percentageDifferenceincome.toFixed(2)}%, ${resultincome}`;
            console.log("prcentage income", percentageIncome)
            setPercentIncome(percentageIncome);

            let percentageDifferencechurch = ((selectedYearChurch - previousYearChurch) / previousYearChurch) * 100;
            percentageDifferencechurch = Math.min(percentageDifferencechurch, 100);

            let resultchurch;
            if (percentageDifferencechurch > 0) {
              resultchurch = "increase";
            } else if (percentageDifferencechurch < 0) {
              resultchurch = "decrease";
            } else {
              resultchurch = "no change";
            }

            const percentageChurch = `${percentageDifferencechurch.toFixed(2)}%, ${resultchurch}`;
            setPercentChurch(percentageChurch);

            let percentageDifferencemember = ((selectedYearMember - previousYearMember) / previousYearMember) * 100;
            percentageDifferencemember = Math.min(percentageDifferencemember, 100);

            let resultmember;
            if (percentageDifferencemember > 0) {
              resultmember = "increase";
            } else if (percentageDifferencemember < 0) {
              resultmember = "decrease";
            } else {
              resultmember = "no change";
            }

            const percentageMember = `${percentageDifferencemember.toFixed(2)}%, ${resultmember}`;
            setPercentMember(percentageMember);

            const filteredCityData = cityData.filter(item => item.selectedYear === selectedYear);

            const convertAndSum = (array) => {
              const convertedArray = array.map((value) => {
                if (value === "Yes") {
                  return 1;
                } else {
                  return 0;
                }
              });

              return convertedArray.reduce((sum, value) => sum + value, 0);
            };

            const Ucn = filteredCityData.map((item) => item.situationalMapping);
            // const ucnSum = convertAndSum(Ucn);
            const ucnSum = Ucn[0] == 'Yes' ? 10 : 0;
            setUcn(ucnSum);

            console.log('Ucn 2 .... ', Ucn);

            let totalCityCount = 0;
            const valuesToCheck = ['SWPME', 'SWF', 'DNP'];
            // filteredCityData.forEach(city => {
            //   const vivaSupportReceived = city.vivaSupportReceived || [];
            //   let count = 0;

            //   vivaSupportReceived.forEach(value => {
            //     if (valuesToCheck.includes(value)) {
            //       count++;
            //     }
            //   });
            //   if (city.hasLongTermPartnership === "Yes") {
            //     count++;
            //   }
            //   if (city.hasConsultancyAgreement === "Yes") {
            //     count++;
            //   }
            //   totalCityCount += count;
            // })

            const longTermPartnership = filteredCityData.map((item) => item.hasLongTermPartnership)[0] == 'Yes' ? 4 : 0;
            const consultancyAgreement = filteredCityData.map((item) => item.hasConsultancyAgreement)[0] == 'Yes' ? 2 : 0;
            const valueSupport = filteredCityData.map((item) => item.vivaSupportReceived)[0];

            let valueSupportRMSum = valueSupport?.includes('RM') ? 2 : 0;

            let valueSupportOtherSum = 0;
            if(valueSupportRMSum <= 0) {
              if ( valueSupport?.length >= 2 ) {
                valueSupportOtherSum = 2;
              }
              else if( valueSupport?.length == 1 ) {
                valueSupportOtherSum = 1;
              }
            }

            totalCityCount = longTermPartnership + consultancyAgreement + valueSupportRMSum + valueSupportOtherSum;
            setBench5(totalCityCount);

            const visionSum = filteredCityData.map((item) => item.visionAndMission)[0] == 'Yes' ? 4 : 0;
            const descSum = filteredCityData.map((item) => item.promotionalDescription)[0] == 'Yes' ? 2 : 0;
            const disSum = filteredCityData.map((item) => item.distinctives)[0] == 'Yes' ? 2 : 0;
            const paSum = filteredCityData.map((item) => item.passionateAbout)[0] == 'Yes' ? 2 : 0;
            const totalSum = visionSum + descSum + disSum + paSum;
            setPurpose(totalSum);

            const splan = filteredCityData.map((item) => item.strategicPlan);
            const strategySum = splan == 'Yes' ? 10 : 0;
            setStrategy(strategySum);

            function Sum(array) {
              const convertedArray = array.map((value) => {
                if (value === "Yes") {
                  return 1;
                } else {
                  return 0;
                }
              });

              return convertedArray.reduce((sum, value) => sum + value, 0);
            }

            const b1 = filteredCityData.map((item) => item.hasNetworkCoordination)[0] == 'Yes' ? 3 : 0;
            const b4 = filteredCityData.map((item) => item.staffRolesAndObjectives)[0] == 'Yes' ? 3 : 0;
            const b5 = filteredCityData.map((item) => item.hasSteeringGroup)[0] == 'Yes' ? 2 : 0;
            const b7 = filteredCityData.map((item) => item.hasWorkingGroups)[0] == 'Yes' ? 2 : 0;



            const d1 = filteredCityData.map((item) => item.safeguardingChildProtectionPolicy)[0] == 'Yes' ? 2 : 0;
            const d2 = filteredCityData.map((item) => item.financePolicy)[0] == 'Yes' ? 2 : 0;
            const d3 = filteredCityData.map((item) => item.financeHealthCheckCompleted)[0] == 'Yes' ? 2 : 0;
            const d4 = filteredCityData.map((item) => item.governancePolicy)[0] == 'Yes' ? 2 : 0;
            const lr = filteredCityData.map((item) => item.legalRegistrationCompliance)[0];
            let d6 = 0;
            if(lr == 'Yes') {
              d6 = 2;
            }
            else if ( lr == 'Partially') {
              d6 = 1;
            }

            const e1 = filteredCityData.map((item) => item.hasDatabaseOfOrganizations)[0] == 'Yes' ? 5 : 0;
            const e2 = filteredCityData.map((item) => item.hasRecordOfMemberParticipation)[0] == 'Yes' ? 5 : 0;

            const e11 = filteredCityData.map((item) => item.hasSharedActivityDataSheet)[0] != 'Yes' && dataArray.length <= 0 ? 0 : 4;
            const activityFormsCount = dataArray.length >= 4 ? 3 : 0;
            let safe = 0;
            let thrive = 0;
            let learn = 0;

            dataArray.map((d) => {
              if(d.childOutcome == 'Safe') {
                safe = 1;
              }
              else if (d.childOutcome == 'Thriving') {
                thrive = 1;
              }
              else {
                learn = 1;
              }
            })

            const activityAcrossNetwork = safe + thrive + learn > 1 ? 3 : 0;

            const e12 = filteredCityData.map((item) => item.governmentLevelInitiatives)[0] >= 1 ? 2 : 0;
            const e13 = filteredCityData.map((item) => item.businessLeadersInitiatives)[0] >= 1 ? 2 : 0;
            const e14 = filteredCityData.map((item) => item.churchLeadersInitiatives)[0] >= 1 ? 2 : 0;
            const e15 = filteredCityData.map((item) => item.justiceSectorInitiatives)[0] >= 1 ? 2 : 0;
            const e16 = filteredCityData.map((item) => item.hasInfluencedPolicies) == 'Yes' ? 2 : 0;

            const f1Val = filteredCityData.map((item) => item.totalIncome)[0];
            let f1 = 0;
            if(f1Val >= 25000) {
              f1 = 2;
            }
            else if(f1Val < 25000 && f1Val >= 5000) {
              f1 = 1;
            }

            const f2Val = filteredCityData.map((item) => item.inCountryIncome)[0];
            let f2 = 0;
            if(f2Val >= 25000) {
              f2 = 2;
            }
            else if(f2Val < 25000 && f2Val >= 5000) {
              f2 = 1;
            }

            const f3Val = filteredCityData.map((item) => item.giftsInKind)[0];
            let f3 = 0;
            if(f3Val >= 25000) {
              f3 = 2;
            }
            else if(f3Val < 25000 && f3Val >= 5000) {
              f3 = 1;
            }

            const f4 = filteredCityData.map((item) => item.fundraisingStrategy)[0] == 'Yes' ? 2 : 0;
            const f5 = filteredCityData.map((item) => item.fundraisingPlan)[0] == 'Yes' ? 2 : 0;


            const total = b1 + b4 + b5 + b7;
            const total2 = d1 + d2 + d3 + d4 + d6;
            const total3 = e1 + e2;
            const total4 = e11 + activityFormsCount + activityAcrossNetwork;
            const total5 = e12 + e13 + e14 + e15 + e16;
            const total6 = f1 + f2 + f3 + f4 + f5;
            const totalscore = total + total2 + total3 + total4 + total5 + total6 + totalCityCount + ucnSum + totalSum + strategySum
            console.log("total score", totalscore)

            setStaff(total);
            setBench6(total2);
            setBench7(total3);
            setBench8(total4);
            setBench9(total5);
            setBench10(total6);
            setCity(cities);
            setScore(totalscore)

          }
        } catch (error) {
          console.error('Error fetching health data:', error);
        }

      }
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  function getPreviousYear(selectedYear) {
    const [startYear, endYear] = selectedYear.split('-');
    const previousStartYear = parseInt(startYear, 10) - 1;
    const previousEndYear = parseInt(endYear, 10) - 1;

    return `${previousStartYear}-${previousEndYear}`;
  }

  const handleYearChange = async (event) => {
    setSelectedYear(event.target.value);
    const selectedYear = event.target.value;

    const startYear = selectedYear.split('-')[0];
    const endYear = selectedYear.split('-')[1];

    const startDate = `${startYear}-0${startMonth}-01T00:00:00.000Z`;
    const endDate = `${parseInt(endYear)}-0${startMonth}-01T00:00:00.000Z`;

    console.log("startDate:", startDate);
    console.log("endDate:", endDate);

    console.log('formData', formData);

    try {
      const activityResponse = await axios.get(
        `/activity?limit=-1&startDate_gte=${startDate}&endDate_lte=${endDate}&network=${formData.network}&status=approved`,
        // `/activity?limit=50&skip=0&startDate_gte=${startDate}&endDate_lte=${endDate}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      let dataArray = [];

      if (activityResponse.status === 200) {
        console.log("response of activity data", activityResponse.data);
        const activityData = activityResponse.data;
        console.log('activity data', activityData);
        console.log("activity data", activityResponse.config.url);

        if (Array.isArray(activityData.data)) {

          console.log("activity data inside hbhsvgdc", activityData.data);
          dataArray = activityData.data;

          const numberOfActivities = dataArray.length;
          setName(numberOfActivities);

          const totalExpenditure = dataArray.reduce((sum, item) => sum + item.actualExpenditure, 0);
          console.log("Total Actual Expenditure:", totalExpenditure);
          setExpenditure(totalExpenditure);

          const Kindgifts = dataArray.reduce((sum, item) => sum + item.estimatedInKindGifts, 0);
          setGifts(Kindgifts);

          const volunteerdays = dataArray.reduce((sum, item) => sum + item.volunteerDays, 0);
          const volunteer = volunteerdays * 24;
          setHours(volunteer);

          const womenParticipated = dataArray.map((item) => item.womenParticipated);
          const menParticipated = dataArray.map((item) => item.menParticipated);

          const totalwomenParticipated = womenParticipated.reduce((sum, value) => sum + value, 0);
          const totalmenParticipated = menParticipated.reduce((sum, value) => sum + value, 0);
          const totalActivities = dataArray.length;

          const avgwomenParticipated = totalwomenParticipated / totalActivities;
          const avgmenParticipated = totalmenParticipated / totalActivities;

          setAverageWomenParticipated(avgwomenParticipated);
          setAverageMenParticipated(avgmenParticipated);

          const girlsParticipated = dataArray.map((item) => item.girlsParticipated);
          const boysParticipated = dataArray.map((item) => item.boysParticipated);

          const totalgirlsParticipated = girlsParticipated.reduce((sum, value) => sum + value, 0);
          const totalboysParticipated = boysParticipated.reduce((sum, value) => sum + value, 0);
          const totalAct = dataArray.length;

          const avggirlParticipated = totalgirlsParticipated / totalAct;
          const avgboyParticipated = totalboysParticipated / totalAct;

          setAverageGirlParticipated(avggirlParticipated);
          setAverageBoyParticipated(avgboyParticipated);

          const wdelivered = dataArray.map((item) => item.womenDelivered);
          const mdelivered = dataArray.map((item) => item.menDelivered);

          const totalwParticipated = wdelivered.reduce((sum, value) => sum + value, 0);
          const totalmParticipated = mdelivered.reduce((sum, value) => sum + value, 0);
          const total = dataArray.length;

          const avgwomdelivered = totalwParticipated / total;
          const avgmdelivered = totalmParticipated / total;

          setWomenDel(avgwomdelivered);
          setMenDel(avgmdelivered);

          const netmem = dataArray.map((item) => item.networkMembers);
          const nonmem = dataArray.map((item) => item.nonMembers);

          const totalmem = netmem.reduce((sum, value) => sum + value, 0);
          const totalnmem = nonmem.reduce((sum, value) => sum + value, 0);
          const sum = dataArray.length;

          const avgmem = totalmem / sum;
          const avgnmem = totalnmem / sum;

          setMembers(avgmem);
          setNonMembers(avgnmem);

          const bubbleData = dataArray.map(item => ({
            x: item.actualExpenditure,
            y: item.boysParticipated + item.girlsParticipated,
            pointRadius: item.actualExpenditure,
            label: item.name,
            childrenReached: item.boysParticipated + item.girlsParticipated,
            childOutcome: item.childOutcome,
            x: item.actualExpenditure,
          }));
          console.log("bubbledata", bubbleData);
          setBubbleChartData(bubbleData);

          const fundingSources = {};
          dataArray.forEach((item) => {
            if (selectedOutcome.includes(item.childOutcome)) {
              const fundingSource = item.fundingSource;
              if (!fundingSources[fundingSource]) {
                fundingSources[fundingSource] = {};
                selectedOutcome.forEach((outcome) => {
                  fundingSources[fundingSource][outcome] = 0;
                });
              }
              fundingSources[fundingSource][item.childOutcome] += item.actualExpenditure;
            }
          });

          setFundingSourceData(fundingSources);

          const childOutcomeCounts = {
            Safe: 0,
            Thriving: 0,
            Learning: 0,
          };
          dataArray.forEach((item) => {
            const { childOutcome } = item;
            console.log('childOutcome:', childOutcome);
            if (childOutcome === 'Safe') {
              childOutcomeCounts.Safe++;
            }
            if (childOutcome === 'Thriving') {
              childOutcomeCounts.Thriving++;
            }
            if (childOutcome === 'Learning') {
              childOutcomeCounts.Learning++;
            }
          });

          console.log('Final childOutcomeCounts:', childOutcomeCounts);
          setChild(childOutcomeCounts);

          const activityCounts = {};
          dataArray.forEach((item) => {
            for (const key in item) {
              if (key.endsWith('Activity') && item[key]) {
                const activityType = item[key] || 'Unknown';
                activityCounts[activityType] = (activityCounts[activityType] || 0) + 1;
              }
            }
          });

          const activityCountArray = Object.values(activityCounts);
          setActivityData(activityCountArray);
          setActivityCounts(activityCounts);

          let adultsInfluenceCount = 0;
          let childrenWithDisabilitiesCount = 0;
          let tActivities = 0;

          dataArray.forEach((item) => {
            if (item.adultsInfluence === 'Yes') {
              adultsInfluenceCount++;
            }
            if (item.childrenWithDisabilities === 'Yes') {
              childrenWithDisabilitiesCount++;
            }
            tActivities++;
          });

          const adultsInfluencePercentage = (adultsInfluenceCount / tActivities) * 100;
          const childrenWithDisabilitiesPercentage = (childrenWithDisabilitiesCount / tActivities) * 100;

          setAdultsInfluenceData({
            label: 'Adults Influence',
            percentage: adultsInfluencePercentage.toFixed(2),
          });

          setChildrenWithDisabilitiesData({
            label: 'Children with Disabilities',
            percentage: childrenWithDisabilitiesPercentage.toFixed(2),
          });
        } else {
          console.error('Error fetching activity data: Invalid response structure');
        }
      }

      console.log('Request URL:', activityResponse.config.url);
      const previousYear = getPreviousYear(selectedYear);
      console.log("previous year", previousYear);
      const years = [selectedYear, previousYear];

      try {
        const healthResponse = await axios.get(`/health?limit=-1&selectedYear_in=${years.join(',')}&network=${formData.network}&status=approved`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (healthResponse.status === 200) {
          console.log("helath api", healthResponse.config.url);
          const cityData = healthResponse.data.data;
          console.log("health data inside handle input ", cityData);

          const cities = cityData.map(item => item.city);
          console.log("cities", cities);

          const organizationMembersCountForYear2022 = cityData
            .filter(item => item.selectedYear === previousYear)
            .map(item => item.organizationMembersCount);

          const organizationMembersCountForYear2023 = cityData
            .filter(item => item.selectedYear === selectedYear)
            .map(item => item.organizationMembersCount);

          const totalMembers2022 = organizationMembersCountForYear2022.reduce((sum, value) => sum + value, 0);
          const totalMembers2023 = organizationMembersCountForYear2023.reduce((sum, value) => sum + value, 0);

          setMemberForYear2022(totalMembers2022);
          setMemberForYear2023(totalMembers2023);

          const incomeYear2022 = cityData
            .filter(item => item.selectedYear === previousYear)
            .map(item => item.totalIncome);

          const incomeYear2023 = cityData
            .filter(item => item.selectedYear === selectedYear)
            .map(item => item.totalIncome);

          const totalIncome2022 = incomeYear2022.reduce((sum, value) => sum + value, 0);
          const totalIncome2023 = incomeYear2023.reduce((sum, value) => sum + value, 0);

          setIncome2022(totalIncome2022);
          setIncome2023(totalIncome2023);

          const churchYear2022 = cityData
            .filter(item => item.selectedYear === previousYear)
            .map(item => item.churchMembersCount);

          const churchYear2023 = cityData
            .filter(item => item.selectedYear === selectedYear)
            .map(item => item.churchMembersCount);

          const totalChurch2022 = churchYear2022.reduce((sum, value) => sum + value, 0);
          const totalChurch2023 = churchYear2023.reduce((sum, value) => sum + value, 0);

          setChurch2022(totalChurch2022);
          setChurch2023(totalChurch2023);

          const childrenYear2022 = cityData
            .filter(item => item.selectedYear === previousYear)
            .map(item => item.childrenReachedCount);

          const childrenYear2023 = cityData
            .filter(item => item.selectedYear === selectedYear)
            .map(item => item.childrenReachedCount);

          const totalChildren2022 = childrenYear2022.reduce((sum, value) => sum + value, 0);
          const totalChildren2023 = childrenYear2023.reduce((sum, value) => sum + value, 0);


          setChildren2022(totalChildren2022);
          setChildren2023(totalChildren2023);

          const selectedYearData = cityData
            .filter(item => item.selectedYear === selectedYear);

          const previousYearData = cityData
            .filter(item => item.selectedYear === previousYear);

          const selectedYearChildren = selectedYearData.length > 0 ? selectedYearData.map(item => item.childrenReachedCount || 0) : [0];
          const previousYearChildren = previousYearData.length > 0 ? previousYearData.map(item => item.childrenReachedCount || 0) : [0];

          const selectedYearMember = selectedYearData.length > 0 ? selectedYearData.map(item => item.organizationMembersCount || 0) : [0];
          const previousYearMember = previousYearData.length > 0 ? previousYearData.map(item => item.organizationMembersCount || 0) : [0];

          const selectedYearIncome = selectedYearData.length > 0 ? selectedYearData.map(item => item.totalIncome || 0) : [0];
          const previousYearIncome = previousYearData.length > 0 ? previousYearData.map(item => item.totalIncome || 0) : [0];

          const selectedYearChurch = selectedYearData.length > 0 ? selectedYearData.map(item => item.churchMembersCount || 0) : [0];
          const previousYearChurch = previousYearData.length > 0 ? previousYearData.map(item => item.churchMembersCount || 0) : [0];

          let percentageDifference = ((selectedYearChildren - previousYearChildren) / previousYearChildren) * 100;
          percentageDifference = Math.min(percentageDifference, 100);

          let result;
          if (percentageDifference > 0) {
            result = "increase";
          } else if (percentageDifference < 0) {
            result = "decrease";
          } else {
            result = "no change";
          }

          const percentageInfoText = `${percentageDifference.toFixed(2)}%, ${result}`;
          setPercent(percentageInfoText);

          let percentageDifferenceincome = ((selectedYearIncome - previousYearIncome) / previousYearIncome) * 100;
          percentageDifferenceincome = Math.min(percentageDifferenceincome, 100);

          let resultincome;
          if (percentageDifferenceincome > 0) {
            resultincome = "increase";
          } else if (percentageDifferenceincome < 0) {
            resultincome = "decrease";
          } else {
            resultincome = "no change";
          }

          const percentageIncome = `${percentageDifferenceincome.toFixed(2)}%, ${resultincome}`;
          console.log("prcentage income", percentageIncome)
          setPercentIncome(percentageIncome);

          let percentageDifferencechurch = ((selectedYearChurch - previousYearChurch) / previousYearChurch) * 100;
          percentageDifferencechurch = Math.min(percentageDifferencechurch, 100);

          let resultchurch;
          if (percentageDifferencechurch > 0) {
            resultchurch = "increase";
          } else if (percentageDifferencechurch < 0) {
            resultchurch = "decrease";
          } else {
            resultchurch = "no change";
          }

          const percentageChurch = `${percentageDifferencechurch.toFixed(2)}%, ${resultchurch}`;
          setPercentChurch(percentageChurch);

          let percentageDifferencemember = ((selectedYearMember - previousYearMember) / previousYearMember) * 100;
          percentageDifferencemember = Math.min(percentageDifferencemember, 100);

          let resultmember;
          if (percentageDifferencemember > 0) {
            resultmember = "increase";
          } else if (percentageDifferencemember < 0) {
            resultmember = "decrease";
          } else {
            resultmember = "no change";
          }

          const percentageMember = `${percentageDifferencemember.toFixed(2)}%, ${resultmember}`;
          setPercentMember(percentageMember);

          const filteredCityData = cityData.filter(item => item.selectedYear === selectedYear);

          const convertAndSum = (array) => {
            const convertedArray = array.map((value) => {
              if (value === "Yes") {
                return 1;
              } else {
                return 0;
              }
            });

            return convertedArray.reduce((sum, value) => sum + value, 0);
          };

          const Ucn = filteredCityData.map((item) => item.situationalMapping);
          // const ucnSum = convertAndSum(Ucn);
          const ucnSum = Ucn[0] == 'Yes' ? 10 : 0;
          setUcn(ucnSum);

          console.log('Ucn 3....', ucnSum);

          let totalCityCount = 0;
          const valuesToCheck = ['RM', 'SWPME', 'SWF', 'DNP'];
          // filteredCityData.forEach(city => {
          //   const vivaSupportReceived = city.vivaSupportReceived || [];
          //   let count = 0;

          //   vivaSupportReceived.forEach(value => {
          //     if (valuesToCheck.includes(value)) {
          //       count++;
          //     }
          //   });
          //   if (city.hasLongTermPartnership === "Yes") {
          //     count++;
          //   }
          //   if (city.hasConsultancyAgreement === "Yes") {
          //     count++;
          //   }
          //   totalCityCount += count;
          // })
          
          const longTermPartnership = filteredCityData.map((item) => item.hasLongTermPartnership)[0] == 'Yes' ? 4 : 0;
          const consultancyAgreement = filteredCityData.map((item) => item.hasConsultancyAgreement)[0] == 'Yes' ? 4 : 0;
          const valueSupport = filteredCityData.map((item) => item.vivaSupportReceived)[0];

          let valueSupportRMSum = valueSupport?.includes('RM') ? 2 : 0;

          let valueSupportOtherSum = 0;
          if(valueSupportRMSum <= 0) {
            if ( valueSupport?.length >= 2 ) {
              valueSupportOtherSum = 2;
            }
            else if( valueSupport?.length == 1 ) {
              valueSupportOtherSum = 1;
            }
          }

          totalCityCount = longTermPartnership + consultancyAgreement + valueSupportRMSum + valueSupportOtherSum;
          setBench5(totalCityCount);
          // console.log("total totalCityCount", totalCityCount)


          const visionSum = filteredCityData.map((item) => item.visionAndMission)[0] == 'Yes' ? 4 : 0;
          const descSum = filteredCityData.map((item) => item.promotionalDescription)[0] == 'Yes' ? 2 : 0;
          const disSum = filteredCityData.map((item) => item.distinctives)[0] == 'Yes' ? 2 : 0;
          const paSum = filteredCityData.map((item) => item.passionateAbout)[0] == 'Yes' ? 2 : 0;
          const totalSum = visionSum + descSum + disSum + paSum;
          setPurpose(totalSum);

          const splan = filteredCityData.map((item) => item.strategicPlan);
          const strategySum = splan == 'Yes' ? 10 : 0;
          setStrategy(strategySum);

          function Sum(array) {
            const convertedArray = array.map((value) => {
              if (value === "Yes") {
                return 1;
              } else {
                return 0;
              }
            });

            return convertedArray.reduce((sum, value) => sum + value, 0);
          }

          const b1 = filteredCityData.map((item) => item.hasNetworkCoordination)[0] == 'Yes' ? 3 : 0;
          const b4 = filteredCityData.map((item) => item.staffRolesAndObjectives)[0] == 'Yes' ? 3 : 0;
          const b5 = filteredCityData.map((item) => item.hasSteeringGroup)[0] == 'Yes' ? 2 : 0;
          const b7 = filteredCityData.map((item) => item.hasWorkingGroups)[0] == 'Yes' ? 2 : 0;



          const d1 = filteredCityData.map((item) => item.safeguardingChildProtectionPolicy)[0] == 'Yes' ? 2 : 0;
          const d2 = filteredCityData.map((item) => item.financePolicy)[0] == 'Yes' ? 2 : 0;
          const d3 = filteredCityData.map((item) => item.financeHealthCheckCompleted)[0] == 'Yes' ? 2 : 0;
          const d4 = filteredCityData.map((item) => item.governancePolicy)[0] == 'Yes' ? 2 : 0;
          const lr = filteredCityData.map((item) => item.legalRegistrationCompliance)[0];
          let d6 = 0;
          if(lr == 'Yes') {
            d6 = 2;
          }
          else if ( lr == 'Partially') {
            d6 = 1;
          }

          const e1 = filteredCityData.map((item) => item.hasDatabaseOfOrganizations)[0] == 'Yes' ? 5 : 0;
          const e2 = filteredCityData.map((item) => item.hasRecordOfMemberParticipation)[0] == 'Yes' ? 5 : 0;

          const e11 = filteredCityData.map((item) => item.hasSharedActivityDataSheet)[0] != 'Yes' && dataArray.length <= 0 ? 0 : 4;
          const activityFormsCount = dataArray.length >= 4 ? 3 : 0;
          let safe = 0;
          let thrive = 0;
          let learn = 0;

          dataArray.map((d) => {
            if(d.childOutcome == 'Safe') {
              safe = 1;
            }
            else if (d.childOutcome == 'Thriving') {
              thrive = 1;
            }
            else {
              learn = 1;
            }
          })

          const activityAcrossNetwork = safe + thrive + learn > 1 ? 3 : 0;

          const e12 = filteredCityData.map((item) => item.governmentLevelInitiatives)[0] >= 1 ? 2 : 0;
          const e13 = filteredCityData.map((item) => item.businessLeadersInitiatives)[0] >= 1 ? 2 : 0;
          const e14 = filteredCityData.map((item) => item.churchLeadersInitiatives)[0] >= 1 ? 2 : 0;
          const e15 = filteredCityData.map((item) => item.justiceSectorInitiatives)[0] >= 1 ? 2 : 0;
          const e16 = filteredCityData.map((item) => item.hasInfluencedPolicies) == 'Yes' ? 2 : 0;

          const f1Val = filteredCityData.map((item) => item.totalIncome)[0];
          let f1 = 0;
          if(f1Val >= 25000) {
            f1 = 2;
          }
          else if(f1Val < 25000 && f1Val >= 5000) {
            f1 = 1;
          }

          const f2Val = filteredCityData.map((item) => item.inCountryIncome)[0];
          let f2 = 0;
          if(f2Val >= 25000) {
            f2 = 2;
          }
          else if(f2Val < 25000 && f2Val >= 5000) {
            f2 = 1;
          }

          const f3Val = filteredCityData.map((item) => item.giftsInKind)[0];
          let f3 = 0;
          if(f3Val >= 25000) {
            f3 = 2;
          }
          else if(f3Val < 25000 && f3Val >= 5000) {
            f3 = 1;
          }

          const f4 = filteredCityData.map((item) => item.fundraisingStrategy)[0] == 'Yes' ? 2 : 0;
          const f5 = filteredCityData.map((item) => item.fundraisingPlan)[0] == 'Yes' ? 2 : 0;

          const total = b1 + b4 + b5 + b7;
          console.log("total score", total)
          const total2 = d1 + d2 + d3 + d4 + d6;
          console.log("total2 score", total2)
          const total3 = e1 + e2;
          const total4 = e11 + activityFormsCount + activityAcrossNetwork;
          const total5 = e12 + e13 + e14 + e15 + e16;
          const total6 = f1 + f2 + f3 + f4 + f5;
          const totalscore = total + total2 + total3 + total4 + total5 + total6 + totalCityCount + ucnSum + totalSum + strategySum
          console.log("total score", totalscore)

          setStaff(total);
          setBench6(total2);
          setBench7(total3);
          setBench8(total4);
          setBench9(total5);
          setBench10(total6);
          setCity(cities);
          setScore(totalscore)

        }
      } catch (error) {
        console.error('Error fetching health data:', error);
      }
    } catch (error) {
      console.error('Error fetching activity data:', error);
    }
  };

  const handleFilterChange = async (event) => {
    console.log('Filter changed');
    const newSelectedFilter = event.target.value;
    console.log("newsed", newSelectedFilter)
    setSelectedFilter(newSelectedFilter);

    const startYear = selectedYear.split('-')[0];
    const endYear = selectedYear.split('-')[1];

    const startDate = `${startYear}-0${startMonth}-01T00:00:00.000Z`;
    const endDate = `${parseInt(endYear)}-0${startMonth}-01T00:00:00.000Z`;

    console.log("startDate:", startDate);
    console.log("endDate:", endDate);

    try {
      const activityResponse = await axios.get(
        // `/activity?limit=50&skip=0&startDate_gte=${startDate}&endDate_lte=${endDate}&network_contains=${formData.network}&childOutcome_in=${newSelectedFilter}&driver_in=${selectedDriver}&status=approved`,
        `/activity?limit=-1&startDate_gte=${startDate}&endDate_lte=${endDate}&network=${formData.network}&childOutcome_in=${newSelectedFilter}&driver_in=${selectedDriver}&status=approved`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      if (activityResponse.status === 200) {
        const activity = activityResponse.data;
        console.log('Request URL:', activityResponse.config.url);
        console.log("response of activity data", activityResponse.data);
        console.log("response of activity data data", activityResponse.data.data);
        console.log('activity data', activity);

        if (Array.isArray(activity.data)) {
          const dataArray = activity.data;

          const womenParticipated = dataArray.map((item) => item.womenParticipated);
          const menParticipated = dataArray.map((item) => item.menParticipated);

          const totalwomenParticipated = womenParticipated.reduce((sum, value) => sum + value, 0);
          const totalmenParticipated = menParticipated.reduce((sum, value) => sum + value, 0);
          const totalActivities = dataArray.length;

          const avgwomenParticipated = totalwomenParticipated / totalActivities;
          const avgmenParticipated = totalmenParticipated / totalActivities;

          setAverageWomenParticipated(avgwomenParticipated);
          setAverageMenParticipated(avgmenParticipated);

          const girlsParticipated = dataArray.map((item) => item.girlsParticipated);
          const boysParticipated = dataArray.map((item) => item.boysParticipated);

          const totalgirlsParticipated = girlsParticipated.reduce((sum, value) => sum + value, 0);
          const totalboysParticipated = boysParticipated.reduce((sum, value) => sum + value, 0);
          const totalAct = dataArray.length;

          const avggirlParticipated = totalgirlsParticipated / totalAct;
          const avgboyParticipated = totalboysParticipated / totalAct;

          setAverageGirlParticipated(avggirlParticipated);
          setAverageBoyParticipated(avgboyParticipated);

          const wdelivered = dataArray.map((item) => item.womenDelivered);
          const mdelivered = dataArray.map((item) => item.menDelivered);

          const totalwParticipated = wdelivered.reduce((sum, value) => sum + value, 0);
          const totalmParticipated = mdelivered.reduce((sum, value) => sum + value, 0);
          const total = dataArray.length;

          const avgwomdelivered = totalwParticipated / total;
          const avgmdelivered = totalmParticipated / total;

          setWomenDel(avgwomdelivered);
          setMenDel(avgmdelivered);

          const netmem = dataArray.map((item) => item.networkMembers);
          const nonmem = dataArray.map((item) => item.nonMembers);

          const totalmem = netmem.reduce((sum, value) => sum + value, 0);
          const totalnmem = nonmem.reduce((sum, value) => sum + value, 0);
          const sum = dataArray.length;

          const avgmem = totalmem / sum;
          const avgnmem = totalnmem / sum;

          setMembers(avgmem);
          setNonMembers(avgnmem);

          const bubbleData = dataArray.map(item => ({
            x: item.actualExpenditure,
            y: item.boysParticipated + item.girlsParticipated,
            pointRadius: item.actualExpenditure,
            label: item.name,
            childrenReached: item.boysParticipated + item.girlsParticipated,
            childOutcome: item.childOutcome,
            x: item.actualExpenditure,
          }));
          console.log("bubbledata", bubbleData);
          setBubbleChartData(bubbleData);

          const fundingSources = {};
          dataArray.forEach((item) => {
            if (selectedOutcome.includes(item.childOutcome)) {
              const fundingSource = item.fundingSource;
              if (!fundingSources[fundingSource]) {
                fundingSources[fundingSource] = {};
                selectedOutcome.forEach((outcome) => {
                  fundingSources[fundingSource][outcome] = 0;
                });
              }
              fundingSources[fundingSource][item.childOutcome] += item.actualExpenditure;
            }
          });

          setFundingSourceData(fundingSources);

          const childOutcomeCounts = {
            Safe: 0,
            Thriving: 0,
            Learning: 0,
          };
          dataArray.forEach((item) => {
            const { childOutcome } = item;
            console.log('childOutcome:', childOutcome);
            if (childOutcome === 'Safe') {
              childOutcomeCounts.Safe++;
            }
            if (childOutcome === 'Thriving') {
              childOutcomeCounts.Thriving++;
            }
            if (childOutcome === 'Learning') {
              childOutcomeCounts.Learning++;
            }
          });

          console.log('Final childOutcomeCounts:', childOutcomeCounts);
          setChild(childOutcomeCounts);

          const activityCounts = {};
          dataArray.forEach((item) => {
            for (const key in item) {
              if (key.endsWith('Activity') && item[key]) {
                const activityType = item[key] || 'Unknown';
                activityCounts[activityType] = (activityCounts[activityType] || 0) + 1;
              }
            }
          });
          const activityCountArray = Object.values(activityCounts);
          setActivityData(activityCountArray);
          setActivityCounts(activityCounts);

          let adultsInfluenceCount = 0;
          let childrenWithDisabilitiesCount = 0;
          let tActivities = 0;

          dataArray.forEach((item) => {
            if (item.adultsInfluence === 'Yes') {
              adultsInfluenceCount++;
            }
            if (item.childrenWithDisabilities === 'Yes') {
              childrenWithDisabilitiesCount++;
            }
            tActivities++;
          });

          const adultsInfluencePercentage = (adultsInfluenceCount / tActivities) * 100;
          const childrenWithDisabilitiesPercentage = (childrenWithDisabilitiesCount / tActivities) * 100;

          setAdultsInfluenceData({
            label: 'Adults Influence',
            percentage: adultsInfluencePercentage.toFixed(2),
          });

          setChildrenWithDisabilitiesData({
            label: 'Children with Disabilities',
            percentage: childrenWithDisabilitiesPercentage.toFixed(2),
          });

        }
      }

    } catch (error) {
      console.error('Error fetching activity data:', error);
    }
  };

  const handleDriverChange = async (event) => {
    const actdriver = event.target.value;
    console.log("newsed", actdriver)
    setSelectedDriver(actdriver)

    const startYear = selectedYear.split('-')[0];
    const endYear = selectedYear.split('-')[1];

    const startDate = `${startYear}-0${startMonth}-01T00:00:00.000Z`;
    const endDate = `${parseInt(endYear)}-0${startMonth}-01T00:00:00.000Z`;

    console.log("startDate:", startDate);
    console.log("endDate:", endDate);

    try {
      const activityResponse = await axios.get(
        // `/activity?limit=50&skip=0&startDate_gte=${startDate}&endDate_lte=${endDate}&network_contains=${formData.network}&driver_in=${actdriver}&status=approved`,
        `/activity?limit=-1&startDate_gte=${startDate}&endDate_lte=${endDate}&network=${formData.network}&childOutcome_in=${selectedFilter}&driver_in=${actdriver}&status=approved`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (activityResponse.status === 200) {
        console.log('Request URL:', activityResponse.config.url);
        console.log("response of activity data", activityResponse.data);
        console.log("response of activity data data", activityResponse.data.data);

        if (Array.isArray(activityResponse.data.data)) {
          const dataArray = activityResponse.data.data;

          const womenParticipated = dataArray.map((item) => item.womenParticipated);
          const menParticipated = dataArray.map((item) => item.menParticipated);
          const girlsParticipated = dataArray.map((item) => item.girlsParticipated);
          const boysParticipated = dataArray.map((item) => item.boysParticipated);
          const wdelivered = dataArray.map((item) => item.womenDelivered);
          const mdelivered = dataArray.map((item) => item.menDelivered);

          const totalwomenParticipated = womenParticipated.reduce((sum, value) => sum + value, 0);
          const totalmenParticipated = menParticipated.reduce((sum, value) => sum + value, 0);
          const totalActivities = dataArray.length;

          const avgwomenParticipated = totalwomenParticipated / totalActivities;
          const avgmenParticipated = totalmenParticipated / totalActivities;

          setAverageWomenParticipated(avgwomenParticipated);
          setAverageMenParticipated(avgmenParticipated);

          const totalgirlsParticipated = girlsParticipated.reduce((sum, value) => sum + value, 0);
          const totalboysParticipated = boysParticipated.reduce((sum, value) => sum + value, 0);

          const avggirlParticipated = totalgirlsParticipated / totalActivities;
          const avgboyParticipated = totalboysParticipated / totalActivities;

          setAverageGirlParticipated(avggirlParticipated);
          setAverageBoyParticipated(avgboyParticipated);

          const totalwParticipated = wdelivered.reduce((sum, value) => sum + value, 0);
          const totalmParticipated = mdelivered.reduce((sum, value) => sum + value, 0);

          const avgwomdelivered = totalwParticipated / totalActivities;
          const avgmdelivered = totalmParticipated / totalActivities;

          setWomenDel(avgwomdelivered);
          setMenDel(avgmdelivered);

          const netmem = dataArray.map((item) => item.networkMembers);
          const nonmem = dataArray.map((item) => item.nonMembers);

          const totalmem = netmem.reduce((sum, value) => sum + value, 0);
          const totalnmem = nonmem.reduce((sum, value) => sum + value, 0);
          const sum = dataArray.length;

          const avgmem = totalmem / sum;
          const avgnmem = totalnmem / sum;

          setMembers(avgmem);
          setNonMembers(avgnmem);

          const bubbleData = dataArray.map(item => ({
            x: item.actualExpenditure,
            y: item.boysParticipated + item.girlsParticipated,
            pointRadius: item.actualExpenditure,
            label: item.name,
            childrenReached: item.boysParticipated + item.girlsParticipated,
            childOutcome: item.childOutcome,
            x: item.actualExpenditure,
          }));
          console.log("bubbledata", bubbleData);
          setBubbleChartData(bubbleData);

          const fundingSources = {};
          dataArray.forEach((item) => {
            if (selectedOutcome.includes(item.childOutcome)) {
              const fundingSource = item.fundingSource;
              if (!fundingSources[fundingSource]) {
                fundingSources[fundingSource] = {};
                selectedOutcome.forEach((outcome) => {
                  fundingSources[fundingSource][outcome] = 0;
                });
              }
              fundingSources[fundingSource][item.childOutcome] += item.actualExpenditure;
            }
          });

          setFundingSourceData(fundingSources);

          const childOutcomeCounts = {
            Safe: 0,
            Thriving: 0,
            Learning: 0,
          };
          dataArray.forEach((item) => {
            const { childOutcome } = item;
            console.log('childOutcome:', childOutcome);
            if (childOutcome === 'Safe') {
              childOutcomeCounts.Safe++;
            }
            if (childOutcome === 'Thriving') {
              childOutcomeCounts.Thriving++;
            }
            if (childOutcome === 'Learning') {
              childOutcomeCounts.Learning++;
            }
          });

          console.log('Final childOutcomeCounts:', childOutcomeCounts);
          setChild(childOutcomeCounts);

          const activityCounts = {};
          dataArray.forEach((item) => {
            for (const key in item) {
              if (key.endsWith('Activity') && item[key]) {
                const activityType = item[key] || 'Unknown';
                activityCounts[activityType] = (activityCounts[activityType] || 0) + 1;
              }
            }
          });
          const activityCountArray = Object.values(activityCounts);
          setActivityData(activityCountArray);
          setActivityCounts(activityCounts);

          let adultsInfluenceCount = 0;
          let childrenWithDisabilitiesCount = 0;
          let tActivities = 0;

          dataArray.forEach((item) => {
            if (item.adultsInfluence === 'Yes') {
              adultsInfluenceCount++;
            }
            if (item.childrenWithDisabilities === 'Yes') {
              childrenWithDisabilitiesCount++;
            }
            tActivities++;
          });

          const adultsInfluencePercentage = (adultsInfluenceCount / tActivities) * 100;
          const childrenWithDisabilitiesPercentage = (childrenWithDisabilitiesCount / tActivities) * 100;

          setAdultsInfluenceData({
            label: 'Adults Influence',
            percentage: adultsInfluencePercentage.toFixed(2),
          });

          setChildrenWithDisabilitiesData({
            label: 'Children with Disabilities',
            percentage: childrenWithDisabilitiesPercentage.toFixed(2),
          });

        }
      }
    } catch (error) {
      console.error("Error fetching activity data", error);
    }
  }

  // showing data for year starting from 2022-23
  const generateYearOptions = () => {
    const options = [];
    for (let i = config.cfgSYear; i <= currentYear + 1; i++) {
      const startYear = i;
      const endYear = i + 1;
      options.push(`${startYear}-${endYear}`);
    }
    return options;
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid container mt={2} ml={8}>
        <Grid item xs={4}>
          <Typography style={{ marginBottom: '4px', marginLeft: '6px', fontWeight: 'bold' }} variant="title">
            {t("chart.sy")}
          </Typography>
          <Box marginTop={1}>
            <Select
              value={selectedYear}
              onChange={handleYearChange}
              size="small"
              style={{
                fontSize: '16px',
              }}
            >
              {generateYearOptions().map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography style={{ marginBottom: '8px', marginLeft: '6px', fontWeight: 'bold' }} variant="title">
            {t("chart.nn")}
          </Typography>
          <Box marginTop={1}>
            {user && user.network ? (
              <Select
                name="network"
                value={formData.network || (user.network.includes('All') ? networks[0].id : formData.network)}
                onChange={handleInputChange}
                fullWidth
                size="small"
                className="textfield"
              >
                <MenuItem value="">{t("chart.sn")}</MenuItem>
                {user.network.includes('All')
                  ? networks.map((network) => (
                    <MenuItem key={network.id} value={network.id}>
                      {network.name}
                    </MenuItem>
                  ))
                  : user.network.map((networkId) => {
                    const network = networks.find((item) => item.id === networkId);
                    return (
                      <MenuItem key={network.id} value={network.id}>
                        {network.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            ) : (
              <TextField
                name="network"
                value={formData.network || ""}
                onChange={handleInputChange}
                fullWidth
                size="small"
                style={{
                  margin: '5px',
                  fontSize: '16px',
                }}
                variant="outlined"
                placeholder={t("chart.nn")}
              />
            )}
          </Box>
        </Grid>

      </Grid>

      <div style={{ textAlign: 'center', margin: '10px' }}>
        <h1 style={{ textDecoration: 'underline', marginBottom: '2px' }}>{t("chart.nhcd")}</h1>
        <h3 style={{ fontSize: '50px', marginTop: '0', marginBottom: '2px' }}>{net}</h3>
        <h3 style={{ fontSize: '40px', marginTop: '0', marginBottom: '2px' }}>{city}</h3>
        <h3 style={{ fontSize: '40px', marginTop: '0', marginBottom: '10px' }}>{formData.country}</h3>
      </div>

      <Grid container justify="center">
        <Grid item xs={3} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ fontSize: '42px', marginTop: '30px', marginBottom: '0' }}>{children2023}</h1>
          <p style={{ marginTop: '-6px' }}>{t("chart.cr")}</p>
        </Grid>
        <Grid item xs={3} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ fontSize: '42px', marginTop: '30px', marginBottom: '0' }}>{church2023}</h1>
          <p style={{ marginTop: '-6px' }}>{t("chart.cn")}</p>
        </Grid>
        <Grid item xs={3} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ fontSize: '42px', marginTop: '30px', marginBottom: '0' }}>{memberForYear2023}</h1>
          <p style={{ marginTop: '-6px' }}>{t("chart.on")}</p>
        </Grid>
        <Grid item xs={3} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 style={{ fontSize: '42px', marginTop: '30px', marginBottom: '0' }}>{income2023}</h1>
          <p style={{ marginTop: '-6px' }}>{t("chart.ti")}</p>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4} md={3}>
          <Bar
            data={chartData1}
            options={chartOptions1}
            height={500}
            width={400}
            plugins={[ChartDataLabels]}
          />
        </Grid>

        <Grid item xs={4} md={3}>
          <Bar
            data={chartData2}
            options={chartOptions2}
            height={500}
            width={400}
            plugins={[ChartDataLabels]}
          />
        </Grid>

        <Grid item xs={4} md={3}>
          <Bar
            data={chartData3}
            options={chartOptions3}
            height={500}
            width={400}
            plugins={[ChartDataLabels]}
          />
        </Grid>


        <Grid item xs={4} md={3}>
          <Bar
            data={chartData4}
            options={chartOptions4}
            height={500}
            width={400}
            plugins={[ChartDataLabels]}
          />
        </Grid>

      </Grid>

      <Grid container alignItems="center" justify="center" style={{ marginBottom: '10px' }}>
        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            <h1 style={{ textDecoration: 'underline', marginBottom: '2px' }}>{t("chart.as")}</h1>
          </div>
        </Grid>
      </Grid>

      <Grid container ml={1}>
        <Grid item xs={6} md={6}>
          <Typography variant="title" style={{ marginBottom: '4px', fontWeight: 'bold', marginLeft: '50px' }}>
            {t("chart.fbd")}
          </Typography>
          <Box marginTop={1}>
            <Select
              multiple
              value={selectedDriver}
              onChange={handleDriverChange}
              style={{
                fontSize: '16px',
                minWidth: '200px',
                maxWidth: '200px',
                height: '43px',
                marginLeft: '50px'
              }}
            >
              <MenuItem value="Connecting">
                <Checkbox checked={selectedDriver.indexOf('Connecting') > -1} />
                {t("chart.Conct")}
              </MenuItem>
              <MenuItem value="Cap">
                <Checkbox checked={selectedDriver.indexOf('Cap') > -1} />
                {t("chart.Cap")}
              </MenuItem>
              <MenuItem value="Cbp">
                <Checkbox checked={selectedDriver.indexOf('Cbp') > -1} />
                {t("chart.Cbp")}
              </MenuItem>
              <MenuItem value="Cip">
                <Checkbox checked={selectedDriver.indexOf('Cip') > -1} />
                {t("chart.Cip")}
              </MenuItem>
              <MenuItem value="Cai">
                <Checkbox checked={selectedDriver.indexOf('Cai') > -1} />
                {t("chart.Cai")}
              </MenuItem>
              <MenuItem value="Cbi">
                <Checkbox checked={selectedDriver.indexOf('Cbi') > -1} />
                {t("chart.Cbi")}
              </MenuItem>
              <MenuItem value="Car">
                <Checkbox checked={selectedDriver.indexOf('Car') > -1} />
                {t("chart.Car")}
              </MenuItem>
            </Select>
          </Box>
        </Grid>

        <Grid item xs={6} md={6}>
          <Typography variant="title" style={{ marginBottom: '4px', fontWeight: 'bold', marginLeft: '200px' }}>
            {t("chart.fbc")}
          </Typography>
          <Box marginTop={1}>
            <Select
              multiple
              value={selectedFilter}
              onChange={handleFilterChange}
              style={{
                fontSize: '16px',
                minWidth: '200px',
                maxWidth: '200px',
                height: '43px',
                marginLeft: '200px'
              }}
            >
              <MenuItem value="Safe">
                <Checkbox checked={selectedFilter.indexOf('Safe') > -1} />
                {t("chart.S")}
              </MenuItem>
              <MenuItem value="Learning">
                <Checkbox checked={selectedFilter.indexOf('Learning') > -1} />
                {t("chart.L")}
              </MenuItem>
              <MenuItem value="Thriving">
                <Checkbox checked={selectedFilter.indexOf('Thriving') > -1} />
                {t("chart.T")}
              </MenuItem>
            </Select>
          </Box>
        </Grid>

      </Grid>

      <Grid container justify="center">
        <Grid item xs={3} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2 style={{ fontSize: '42px', marginTop: '30px', marginBottom: '0' }}>{name}</h2>
          <p style={{ marginTop: '-6px' }}>{t("chart.al")}</p>
        </Grid>
        <Grid item xs={3} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2 style={{ fontSize: '42px', marginTop: '30px', marginBottom: '0' }}>{expenditure}</h2>
          <p style={{ marginTop: '-6px' }}>{t("chart.ae")}</p>
        </Grid>
        <Grid item xs={3} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2 style={{ fontSize: '42px', marginTop: '30px', marginBottom: '0' }}>{hours}</h2>
          <p style={{ marginTop: '-6px' }}>{t("chart.vh")}</p>
        </Grid>
        <Grid item xs={3} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2 style={{ fontSize: '42px', marginTop: '30px', marginBottom: '0' }}>{gifts}</h2>
          <p style={{ marginTop: '-6px' }}>{t("chart.eg")}</p>
        </Grid>
      </Grid>

      <h2 style={{ marginLeft: '60px', textAlign: 'center', justifyContent: 'center' }}>{t("chart.ary")}</h2>
      <Grid container style={{ justifyContent: 'center'}}>
        <Grid >
          <Bar
            data={chartData12}
            options={chartOptions12}
            height={350}
            width={600}
          />
        </Grid>
      </Grid>

      <h2 style={{ marginLeft: '60px', fontSize: "24px", textAlign: 'center', justifyContent: 'center' }}>{t("chart.ap")}</h2>
      <Grid container>
        <Grid item xs={4} md={3}>
          <Bar
            data={chartData6}
            options={chartOptions6}
            height={500}
            width={400}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <Bar
            data={chartData5}
            options={chartOptions5}
            height={500}
            width={400}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <Bar
            data={chartData7}
            options={chartOptions7}

            height={500}
            width={400}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <Bar
            data={chartData8}
            options={chartOptions8}
            height={500}
            width={400}
          />
        </Grid>
      </Grid>
      <Grid>
        <Bar
          data={chartData11}
          options={chartOptions11}
          height={500}
          width={800}
        />
      </Grid>
      <Grid container spacing={2} mt={4}>
        <h2 style={{ marginLeft: '65px' }}>{t("chart.aich")}</h2>
        <Grid item xs={4} md={5} mr={8} ml={8} style={{ height: 300, width: 300 }}>
          <h3>{t("chart.aic")}</h3>
          <Pie data={adultsInfluenceChart} />
        </Grid>
        <Grid item xs={4} md={5} ml={5} style={{ height: 300, width: 300 }}>
          <h3>{t("chart.cwdi")}</h3>
          <Pie data={cwdChartData} />
        </Grid>
      </Grid>
      <br />
      <Grid container style={{ justifyContent: 'center', marginTop: '100px', marginLeft: '50px', marginBottom: '50px'}}>
      <Grid item xs={12} md={12}>
        <RadarChart
          ucn={ucn}
          purpose={purpose}
          strategy={strategy}
          bench5={bench5}
          staff={staff}
          bench6={bench6}
          bench7={bench7}
          bench8={bench8}
          bench9={bench9}
          bench10={bench10}
          score={score}
        />
        </Grid>
      </Grid>
      <Grid mt={4}>
        <h2 style={{ marginLeft: '-65px' }}>{t("chart.sofh")}</h2>
        <Bar
          data={chartData9}
          options={chartOptions9}
          height={500}
          width={800}
        />
      </Grid>
      <Grid>
        <h2 style={{ marginLeft: '-65px' }}>{t("chart.er")}</h2>
        <ScatterChart bubbleChartData={bubbleChartData} />
      </Grid>
    </Grid>
  );

};

export default BarChart8