import React, { useState, useContext } from 'react';
import { config, axios } from '../config/config';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { NetworkContext } from "../Context/NetworkContext";
import { RegionContext } from "../Context/RegionContext";
import { UserContext } from '../Components/UserContext';
import { ListItems, SecondaryListItems } from './listItems';
import Header2 from './Header2';
import ActivityList from './ActivityList';
import { useTranslation } from 'react-i18next'

const Activities = () => {
  const { token, updateToken } = useContext(UserContext);
  const [networks, setNetworks] = useState(() => fetchNetworkData());
  const [regions, setRegions] = useState(() => fetchRegionData());
  const { t } = useTranslation();

  async function fetchNetworkData() {
    try {
      const response = await axios.get('/network?limit=-1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setNetworks(response.data.data);
    } catch (error) {
      console.log('Error in fetching networks:', error);
    }
  }

  async function fetchRegionData() {
    try {
      const response = await axios.get('/regions?limit=-1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setRegions(response.data.data);
    } catch (error) {
      console.log('Error in fetching regions:', error);
    }
  }

  return (
    <>

      <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 999, marginTop: 0 }}>
        <div style={{ width: '20%' }}>
          <Header />
        </div>
        <div style={{ width: '82%', marginLeft: '18%', textAlign: 'right' }}>
        <Header2 title= {t("header.na")}/>
        </div>
      </div>

      <div style={{ marginTop: '80px', display: 'flex' }}>
        <div style={{ width: '18%', position: 'fixed' }}>
          <ListItems />
          <SecondaryListItems />
        </div>
        <div style={{ marginLeft: '18%', flex: 1, overflow: 'auto', height: 'calc(100vh - 80px)' }}>
          <NetworkContext.Provider value={networks}>
            <RegionContext.Provider value={regions}>
              {networks.length > 0 && regions.length > 0 ? <ActivityList /> : ''}
            </RegionContext.Provider>
          </NetworkContext.Provider>

        </div>
      </div>
      <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </>
  ) 
}

export default Activities;