import React, { useState, useEffect, useContext, useRef } from 'react';
import { config, axios } from '../config/config';
import { UserContext } from "./UserContext";
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const Header2 = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [name, setName] = useState("");
  const {t}=useTranslation();
  const { token, updateToken, updateUser } = useContext(UserContext);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const theme = useTheme();

  const handleOptionClick = (option) => {
    if (option === 'Logout') {
      localStorage.removeItem('token');
      updateToken("");
      setShowDropdown(false);
      navigate('/', { replace: true });
    } else if (option === 'My Profile') {
      setSelectedOption(option);
      setShowDropdown(false);
      navigate('/myprofile');
    } else {
      setSelectedOption(option);
      setShowDropdown(false);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('the response', response);
      console.log('response.data from the response', response.data);
      setName(response.data.firstName);
      updateUser(response.data);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserInfo();
    }
  }, [token]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!token) {
    return null;
  }

  const handleProfileClick = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', paddingLeft: "12px", justifyContent: 'flex-end', fontSize: '13px', backgroundColor: theme.palette.viva.main, color: 'white' }}>
      <div>
        <h1>{props.title}</h1>
      </div>
      <div style={{ marginLeft: 'auto', position: 'relative' }}>
        <button onClick={handleProfileClick} style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: "15px", marginRight: "20px" }}>
          <PersonIcon style={{ color: '#fff' }} />
          <span style={{ marginLeft: '8px', color: "white" }}>{name}</span>
        </button>
        {showDropdown && (
          <div ref={dropdownRef} style={{ position: 'absolute', top: '100%', right: 0, minWidth: '100px', backgroundColor: '#fff', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', zIndex: 1 }}>
            <ul style={{ listStyleType: 'none', margin: 0, padding: 0, color: "grey" }}>
              <li style={{ padding: '8px 16px', cursor: 'pointer' }} onClick={() => handleOptionClick('My Profile')}>{t("dropdown.mp")}</li>
              <li style={{ padding: '8px 16px', cursor: 'pointer' }} onClick={() => handleOptionClick('Logout')}>{t("dropdown.lo")}</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header2;


