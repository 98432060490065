import React, { useState, useEffect, useContext } from 'react';
import { config, axios } from '../config/config';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Box } from '@mui/material';
import "../assets/css/apii.css";
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { NetworkContext } from "../Context/NetworkContext";
import { RoleContext } from "../Context/RoleContext";
import { UserContext } from '../Components/UserContext';
import UserForm from './UserForm';
import { useNavigate } from 'react-router-dom';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

function UserList() {
  const [key, setKey] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState(null);
  const [id, setId] = useState(null);
  const {t}= useTranslation();

  const { token, updateToken } = useContext(UserContext);
  const networks = useContext(NetworkContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const roles = useContext(RoleContext);
  const navigate = useNavigate();
  const handleAddFormClick = (id) => {
    setDialogOpen(true);
    setId(id);
  };

  const handleDataUpdate = (updatedUserData) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.userId === updatedUserData.userId) {
          return {
            ...item,
            col1: updatedUserData.name,
            col2: updatedUserData.email,
            col3: updatedUserData.mobile,
            col4: updatedUserData.network,
            col5: updatedUserData.role,
            col6: updatedUserData.lang,
          };
        }
        return item;
      });

      return updatedData;
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setId(null);
  };

  async function fetchData() {
    try {
      const response = await axios.get('/users?limit=-1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.status === 200) {
        const responseData = response.data;
        console.log(responseData);

        if (Array.isArray(responseData.data)) {
          const mappedData = await Promise.all(responseData.data.map(async (item, index) => {
            console.log("in userlist", responseData.data);
            const userNetworks = item.network.map((nt) => {
              let ntw = networks.find(n => n._id === nt);
              if (ntw) {
                return ntw.name;
              }
            });
            const userId = item.userId;
            console.log("userID", userId);
            const role = roles.find(r => r._id === item.role);
            const networkName = userNetworks.length > 0 ? userNetworks.join(', ') : "N/A";

            return {
              id: index + 1,
              col1: item.firstName + ' ' + item.lastName,
              col2: item.email,
              col3: item.mobile,
              col4: networkName,
              col5: role.roleName,
              col6: item.lang,
              userId: userId
            };
          }));

          console.log("consoling mappeddata", mappedData);
          setData(mappedData);
          setFilteredData(mappedData);
          handleDataUpdate(mappedData);
          console.log("mapped data in handledataupdate", mappedData);
        }
      } else {
        console.error('Error fetching data: Invalid response structure');
      }
    } catch (error) {
      console.log("error fetching user data", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = data.filter((user) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return Object.values(user).some((value) => {
        if (typeof value === 'string') {
          const lowerCaseValue = value.toLowerCase();
          return lowerCaseValue.includes(lowerCaseSearchQuery);
        }
        return false;
      });
    });
    setFilteredData(filtered);
  }, [searchQuery, data]);


  console.log("consoling data", data);
  const columns = [
    {
      field: 'col1',
      headerName: t("usergrid.un"),
      width: 150,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const id = params.row.id;
        console.log("consoling userlist id", id);

        return (
          <>
            <Link to="#" onClick={() => handleAddFormClick(id)} style={{ textDecoration: 'none', color: 'blue' }}>
              {params.value}
            </Link>
          </>
        );
      },
    },
    { field: 'col2', headerName: t("userform.em"), width: 150, headerClassName: 'custom-header' },
    { field: 'col3', headerName: t("userform.mb"), width: 150, headerClassName: 'custom-header' },
    { field: 'col4', headerName: t("userform.nw"), width: 150, headerClassName: 'custom-header' },
    { field: 'col5', headerName: t("userform.rl"), width: 150, headerClassName: 'custom-header' },
    { field: 'col6', headerName: t("userform.lg"), width: 150, headerClassName: 'custom-header' },

    {
      field: 'actions',
      headerName: t("usergrid.ac"),
      sortable: false,
      headerClassName: 'custom-header',
      disableExport: true,
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            color="error"
            type='submit'
            onClick={() => handleDeleteRow(params.row.userId)}
            style={{ paddingLeft: '20px', paddingRight: '20px' }}
          >
          {t("usergrid.dl")}
          </Button>
        </>
      ),
    },
  ];


  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [transparentContent, setTransparentContent] = useState(false);

  const handleDeleteRow = async (userId) => {
    setOpenDialog(true);
    setDeleteUserId(userId);
    setTransparentContent(true);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ allColumns: true }}
          exportCsv
        />
      </GridToolbarContainer>
    );
  }

  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      try {
        await axios.delete(`/users/${deleteUserId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const updatedUserList = data.filter((user) => user.userId !== deleteUserId);
        setData(updatedUserList);
      } catch (error) {
        console.error('Error deleting row:', error);
      }
    }

    setOpenDialog(false);
    setDeleteUserId(null);
    setTransparentContent(false);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setDeleteUserId(null);
    setTransparentContent(false);
  };

  return (
    <>
    
      <Box sx={{ mt: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, mr: 1 }}>
          <TextField
            placeholder= {t("userform.su")}
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            variant="outlined"
            fullWidth
            size="small"
            sx={{ width: '60%' }}
          />
          <Button
            type='submit'
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => handleAddFormClick(null)}
          >
            {/* ADD USER */}
             {t("userform.add")}
          </Button> 
  
        </Box>

        {dialogOpen && <UserForm onClose={handleDialogClose} id={id} updateData={handleDataUpdate} onSuccess={fetchData} />}
        <div style={{ height: "calc(100vh - 200px)" }}>
          <DataGrid
            key={key}
            rows={filteredData}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </div>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>No</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  
}

export default UserList;