import React, { useState, useEffect, useContext } from 'react';
import { config, axios } from '../config/config';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Box,TextField } from '@mui/material';
import "../assets/css/apii.css";
import { Link } from 'react-router-dom';
import { UserContext } from '../Components/UserContext';
import RegionsForm from './RegionsForm';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

function RegionList() {
  const [key, setKey] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState(null);
  const [id, setId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const {t}= useTranslation();

useEffect(() => {
  fetchData();
  console.log("fetchdata", fetchData);
}, []);
  const { token, updateToken } = useContext(UserContext);
  const handleAddFormClick = (id) => {
    setDialogOpen(true);
    setId(id);
  };

  const handleDataUpdate = (updatedRegionData) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.userId === updatedRegionData.userId) {
          return {
            ...item,
            col1: updatedRegionData.name,
          };
        }
        return item;
      });
  
      return updatedData;
    });
  };
  

  const handleDialogClose = () => {
    setDialogOpen(false);
    setId(null);
  };

  async function fetchData() {
    try {
      const response = await axios.get('/regions?limit=-1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.status === 200) {
        const responseData = response.data;
        console.log(responseData);

        if (Array.isArray(responseData.data)) {
          const mappedData = await Promise.all(responseData.data.map(async (item, index) => {
            console.log("in userlist", responseData.data);

            const userId = item._id;
            console.log("userID", userId);


            return {
              id: item._id,
              col1: item.name,
              userId: userId
            };
          }));

          console.log("consoling mappeddata", mappedData);
          setData(mappedData);
          handleDataUpdate(mappedData);
        }
      } else {
        console.error('Error fetching data: Invalid response structure');
      }
    } catch (error) {
      console.log("error fetching user data", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = data.filter((region) => {
      const fullName = `${region.col1}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    });
    setFilteredData(filtered);
  }, [searchQuery, data]);
  

  const columns = [
    {
      field: 'col1',
      headerName: t("regData.rn"),
      width: 600,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const id = params.row.id;
        console.log("consoling userlist id", id);

        return (
          <>
            <Link to="#" onClick={() => handleAddFormClick(id)} style={{ textDecoration: 'none', color: 'blue' }}>
              {params.value}
            </Link>
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: t("regData.ac"),
      sortable: false,
      disableExport: true,
      headerClassName: 'custom-header',
      width: 200,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="error"
            type='submit'
            onClick={() => handleDeleteRow(params.row.userId)}
            style={{ paddingLeft: '20px', paddingRight: '20px' }}
          >
           {t("regData.dl")}
          </Button>
        </div>
      ),
    },

  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [transparentContent, setTransparentContent] = useState(false);

  const handleDeleteRow = async (userId) => {
    setOpenDialog(true);
    setDeleteUserId(userId);
    setTransparentContent(true);
  };
  
  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      try {
        await axios.delete(`/regions/${deleteUserId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        const updatedUserList = data.filter((user) => user.userId !== deleteUserId);
        setData(updatedUserList);
      } catch (error) {
        console.error('Error deleting row:', error);
      }
    }
    
    setOpenDialog(false);
    setDeleteUserId(null);
    setTransparentContent(false);
  };
  
  const handleCancelDelete = () => {
    setOpenDialog(false);
    setDeleteUserId(null);
    setTransparentContent(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ allColumns: true }}
          exportCsv
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
    <Box sx={{ mt: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, mr: 1 }}>
      <TextField
    placeholder={t("regForm.sr")}
    value={searchQuery}
    onChange={(event) => setSearchQuery(event.target.value)}
    variant="outlined"
    fullWidth
    size="small"
    sx={{ width: '60%' }}
  />
        <Button type='submit' disableElevation variant="contained" color="primary" onClick={() => handleAddFormClick(null)}>  {t("regForm.ad")}</Button>
      </Box>
      {dialogOpen && <RegionsForm onClose={handleDialogClose} id={id} updateData={handleDataUpdate} onSuccess={fetchData} />}
      <div style={transparentContent ? { opacity: 0.5 } : {}}>
        <div style={{ height: "calc(100vh - 200px)"}}>
          <DataGrid key={key} rows={filteredData} columns={columns} 
                 slots={{
                  toolbar: CustomToolbar,
                }}
          />
        </div>
      </div>
    </Box>
    <Dialog
      open={openDialog}
      onClose={handleCancelDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this region?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelDelete}>No</Button>
        <Button onClick={handleConfirmDelete} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  </>

  );
}

export default RegionList
































