import React, { useState, useEffect, useContext } from 'react';
import { config, axios } from '../config/config';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Box, Grid, Select, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import "../assets/css/apii.css";
import { Link } from 'react-router-dom';
import { NetworkContext } from "../Context/NetworkContext";
import { RoleContext } from "../Context/RoleContext";
import { UserContext } from '../Components/UserContext';
import { RegionContext } from '../Context/RegionContext';
import ActivityForm from './ActivityForm';
import { useTranslation } from 'react-i18next';
import statuses from './statuses.json';


function ActivityList() {
  const [key, setKey] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activityName, setActivityName] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [regionName, setRegionName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [personName, setPersonName] = useState('');
  const [status, setStatus] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  let selRegions = [];

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleActivityNameChange = (event) => {
    setActivityName(event.target.value);
  };
  const handleNetworkNameChange = (event) => {
    setNetworkName(event.target.value);
  };
  const handleRegionNameChange = (event) => {
    setRegionName(event.target.value);
  };
  const handleCountryNameChange = (event) => {
    setCountryName(event.target.value);
  };
  const handlePersonNameChange = (event) => {
    setPersonName(event.target.value);
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { token, updateToken, user } = useContext(UserContext);
  const networks = useContext(NetworkContext);
  const regions = useContext(RegionContext);
  const roles = useContext(RoleContext);

  const handleAddFormClick = (id) => {
    setDialogOpen(true);
    setId(id);
  };

  console.log('user', user);
  const handleDataUpdate = (updatedActivityData) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.userId === updatedActivityData.userId) {
          return {
            ...item,
            col1: updatedActivityData.startDate,
            col2: updatedActivityData.endDate,
            col3: updatedActivityData.name,
            col4: updatedActivityData.networkName,
            col5: updatedActivityData.region,
            col6: updatedActivityData.country,
            col7: updatedActivityData.person,
            col8: t(updatedActivityData.status).toUpperCase()
          };
        }
        return item;
      });

      return updatedData;
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setId(null);
  };
  const [id, setId] = useState("");

  async function fetchData() {
    try {
      const response = await axios.get('/activity?limit=-1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.status === 200) {
        const responseData = response.data;
        console.log('responseData', responseData);

        if (Array.isArray(responseData.data)) {
          const mappedData = await Promise.all(responseData.data.map(async (item, index) => {
            console.log("in activitylist", responseData.data);

            let userNetworks = [];

            if (typeof item.network === 'string') {
              const networkData = networks.find(n => n._id == item.network);
              if (networkData) {
                userNetworks.push(networkData.name);
              }
            }

            const userId = item._id;
            console.log("userID", userId);

            const networkName = userNetworks.length > 0 ? userNetworks.join(', ') : "N/A";

            const startDateStr = item.startDate;
            const startDate = new Date(startDateStr);

            const day = startDate.getUTCDate();
            const monthIndex = startDate.getUTCMonth();
            const year = startDate.getUTCFullYear();

            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const monthName = monthNames[monthIndex];

            const formattedStartDate = `${day}-${monthName}-${year}`;

            console.log('formattedStartDate 1', formattedStartDate);

            const endDateStr = item.endDate;
            const endDate = new Date(endDateStr);

            const days = endDate.getUTCDate();
            const monthvalue = endDate.getUTCMonth();
            const years = endDate.getUTCFullYear();

            const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const monthname = month[monthvalue];

            const formattedEndDate = `${days}-${monthname}-${years}`;

            console.log('formattedStartDate 2', formattedStartDate);

            let childActivity = "";
            switch (item.childOutcome) {
              case "Safe":
                childActivity = t(`activityform.${item.safeActivity}`);
                break;
              case "Thriving":
                childActivity = t(`activityform.${item.thrivingActivity}`);
                break;
              case "Learning":
                childActivity = t(`activityform.${item.learningActivity}`);
                break;
            }

            return {
              id: item._id,
              col1: formattedStartDate,
              col2: formattedEndDate,
              col3: item.name,
              col4: networkName,
              col5: item.region,
              col6: item.country,
              col7: item.person,
              col8: t(item.status).toUpperCase(),
              col9: item.actualExpenditure,
              col10: item.city,
              col11: item.coordinatorname,
              col12: item.consultantname,
              col13: item.childOutcome,
              col14: childActivity,
              col15: t(`activityform.${item.driver}`),
              col16: item.fundingSource,
              col17: item.estimatedInKindGifts,
              col18: item.volunteerDays,
              col19: item.networkMembers,
              col20: item.nonMembers,
              col21: item.girlsParticipated,
              col22: item.boysParticipated,
              col23: item.womenParticipated,
              col24: item.menParticipated,
              col25: item.womenDelivered,
              col26: item.menDelivered,
              col27: item.adultsInfluence,
              col28: item.childrenWithDisabilities,
              col29: item.bestStory,
              userId: userId
            };
          }));

          console.log("consoling mappeddata", mappedData);
          setData(mappedData);
        }
      } else {
        console.error('Error fetching data: Invalid response structure');
      }
    } catch (error) {
      console.log("error fetching user data", error);
    }
  }

  async function fetchNetworkData(networkId) {
    const network = networks.find(n => n._id === networkId);
    return network;

    console.log('network ----->', network);
  }

  const handleFilter = () => {
    let queryString = '/activity?limit=-1&';
    if (startDate) {
      const formattedStartDate = new Date(startDate);
      const isoFormattedStartDate = formattedStartDate.toISOString();
      queryString += `startDate_gte=${isoFormattedStartDate}&`;
    }

    if (endDate) {
      const formattedEndDate = new Date(endDate);
      const isoFormattedEndDate = formattedEndDate.toISOString();
      queryString += `endDate_lte=${isoFormattedEndDate}&`;
    }
    if (activityName) {
      queryString += `name_contains=${activityName}&`;
    }
    if (networkName) {
      queryString += `network_contains=${networkName}&`;
    }
    if (regionName) {
      console.log("region naem in quert", regionName);
      queryString += `region_contains=${regionName}&`;
    }
    if (countryName) {
      queryString += `country_contains=${countryName}&`;
    }
    if (personName) {
      queryString += `person_contains=${personName}&`;
    }
    // TS: Will work for all strings of all languages defined. But it has to be the full string
    if (status) {
      console.log('status key', statuses[status]);
      queryString += `status_contains=${statuses[status]}&`;
    }

    console.log('GET request URL:', queryString);

    axios.get(queryString, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          if (Array.isArray(responseData.data)) {
            const mappedData = responseData.data.map(async (item, index) => {
              let userNetworks = [];

              const startDateStr = item.startDate;
              const startDate = new Date(startDateStr);

              const day = startDate.getUTCDate();
              const monthIndex = startDate.getUTCMonth();
              const year = startDate.getUTCFullYear();

              const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
              const monthName = monthNames[monthIndex];

              const formattedStartDate = `${day}-${monthName}-${year}`;

              console.log('formattedStartDate 3', formattedStartDate);

              const endDateStr = item.endDate;
              const endDate = new Date(endDateStr);

              const days = endDate.getUTCDate();
              const monthvalue = endDate.getUTCMonth();
              const years = endDate.getUTCFullYear();

              const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
              const monthname = month[monthvalue];

              const formattedEndDate = `${days}-${monthname}-${years}`;
              if (typeof item.network === 'string') {
                const networkData = await fetchNetworkData(item.network);
                if (networkData) {
                  userNetworks.push(networkData.name);
                }
              }

              return {
                id: item._id,
                // col1: item.startDate,
                col1: formattedStartDate,
                col2: formattedEndDate,
                col3: item.name,
                col4: userNetworks.length > 0 ? userNetworks.join(', ') : "N/A",
                col5: item.region,
                col6: item.country,
                col7: item.person,
                col8: t(item.status).toUpperCase(),
                userId: item._id,
              };
            });
            Promise.all(mappedData).then((formattedData) => {
              setData(formattedData);
              setFilteredData(formattedData);
            });
          } else {
            console.error('Error fetching data: Invalid response structure');
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
  }, [filteredData, startDate, endDate, activityName, networkName, regionName, countryName, personName, status]);


  async function fetchNetworkData(networkId) {
    try {
      const response = await axios.get(`/network/${networkId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('Error fetching network data:', response);
        return null;
      }
    } catch (error) {
      console.error('Error fetching network data:', error);
      return null;
    }
  }

  console.log("consoling data", data);
  const columns = [
    { field: 'col1', headerName: t("actgrid.sd"), width: 120, headerClassName: 'custom-header' },
    { field: 'col2', headerName: t("actgrid.ed"), width: 120, headerClassName: 'custom-header' },
    {
      field: 'col3',
      headerName: t("actgrid.an"),
      width: 200,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const id = params.row.id;
        console.log('params', params);
        return (
          <Link to="#" onClick={() => handleAddFormClick(id)} style={{ textDecoration: 'none', color: 'blue' }}>
            {params.value}
          </Link>

        );
      },
    },
    { field: 'col4', headerName: t("actgrid.nw"), width: 200, headerClassName: 'custom-header' },
    { field: 'col5', headerName: t("actgrid.rg"), width: 100, headerClassName: 'custom-header' },
    { field: 'col6', headerName: t("actgrid.con"), width: 100, headerClassName: 'custom-header' },
    { field: 'col7', headerName: t("actgrid.pr"), width: 100, headerClassName: 'custom-header' },
    { field: 'col8', headerName: t("actgrid.st"), width: 100, headerClassName: 'custom-header' },
    { field: 'col9', headerName: t("actgrid.ae"), width: 100, headerClassName: 'custom-header' },
    { field: 'col10', headerName: t("actgrid.ct"), width: 100, headerClassName: 'custom-header' },
    { field: 'col11', headerName: t("actgrid.cn"), width: 100, headerClassName: 'custom-header' },
    { field: 'col12', headerName: t("actgrid.csn"), width: 100, headerClassName: 'custom-header' },
    { field: 'col13', headerName: t("actgrid.co"), width: 100, headerClassName: 'custom-header' },
    { field: 'col14', headerName: t("actgrid.ad"), width: 100, headerClassName: 'custom-header' },
    { field: 'col15', headerName: t("actgrid.dr"), width: 100, headerClassName: 'custom-header' },
    { field: 'col16', headerName: t("actgrid.fs"), width: 100, headerClassName: 'custom-header' },
    { field: 'col17', headerName: t("actgrid.kg"), width: 100, headerClassName: 'custom-header' },
    { field: 'col18', headerName: t("actgrid.vd"), width: 100, headerClassName: 'custom-header' },
    { field: 'col19', headerName: t("actgrid.nm"), width: 100, headerClassName: 'custom-header' },
    { field: 'col20', headerName: t("actgrid.nom"), width: 100, headerClassName: 'custom-header' },
    { field: 'col21', headerName: t("actgrid.gp"), width: 100, headerClassName: 'custom-header' },
    { field: 'col22', headerName: t("actgrid.bp"), width: 100, headerClassName: 'custom-header' },
    { field: 'col23', headerName: t("actgrid.wp"), width: 100, headerClassName: 'custom-header' },
    { field: 'col24', headerName: t("actgrid.mp"), width: 100, headerClassName: 'custom-header' },
    { field: 'col25', headerName: t("actgrid.wd"), width: 100, headerClassName: 'custom-header' },
    { field: 'col26', headerName: t("actgrid.md"), width: 100, headerClassName: 'custom-header' },
    { field: 'col27', headerName: t("actgrid.ai"), width: 100, headerClassName: 'custom-header' },
    { field: 'col28', headerName: t("actgrid.dc"), width: 100, headerClassName: 'custom-header' },
    { field: 'col29', headerName: t("actgrid.bs"), width: 100, headerClassName: 'custom-header' },
    {
      field: 'actions',
      headerName: t("actgrid.ac"),
      sortable: false,
      disableExport: true,
      headerClassName: 'custom-header',
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            color="error"
            type='submit'
            onClick={() => handleDeleteRow(params.row.userId)}
            style={{ paddingLeft: '20px', paddingRight: '20px' }}
          >
            {/* Delete */}
            {t("actgrid.dl")}
          </Button>
        </>
      ),
    },
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [transparentContent, setTransparentContent] = useState(false);

  const handleDeleteRow = async (userId) => {
    setOpenDialog(true);
    setDeleteUserId(userId);
    setTransparentContent(true);
  };

  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      try {
        await axios.delete(`/activity/${deleteUserId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const updatedUserList = data.filter((user) => user.userId !== deleteUserId);
        setData(updatedUserList);
      } catch (error) {
        console.error('Error deleting row:', error);
      }
    }

    setOpenDialog(false);
    setDeleteUserId(null);
    setTransparentContent(false);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setDeleteUserId(null);
    setTransparentContent(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ allColumns: true, fileName: "networkactivities", utf8WithBom: true }}
          exportCsv />
      </GridToolbarContainer>
    );
  }

  console.log("filred data", filteredData);
  return (
    <>
      <Box sx={{ mt: 1 }}>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="startDate"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                size="small"
              />
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="endDate"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                size="small"
              />
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="activity"
                value={activityName}
                onChange={handleActivityNameChange}
                placeholder={t("actfilter.an")}
                size="small"
                className="textfield"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="status"
                value={status}
                onChange={handleStatusChange}
                placeholder={t("actfilter.st")}
                size="small"
                className="textfield"
              />
            </Box>
          </Grid>

          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              sx={{ marginTop: '5px', marginRight: '20px', marginBottom: '2px', justifyContent: 'flex-end' }}
              type="submit"
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => handleAddFormClick(null)}
            >
              {/* Add Network Activity */}
              {t("activityform.AddButton")}
            </Button>
          </Grid>

        </Grid>

        <Grid container spacing={2} mb={1}>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <Select
                name="network"
                value={networkName}
                onChange={handleNetworkNameChange}
                size="small"
                fullWidth
                className="textfield"
                displayEmpty
              >
                <MenuItem value="" style={{ color: 'gray' }}>
                  {t("actfilter.nn")}
                </MenuItem>
                {user.network.includes('All')
                  ? networks.map((network) => (
                    <MenuItem key={network.id} value={network.id}>
                      {network.name}
                    </MenuItem>
                  ))
                  : user.network.map((networkId) => {
                    const network = networks.find((item) => item.id === networkId);
                    console.log('network', network, networkId);
                    if(network){
                      return (
                        <MenuItem key={network.id} value={network.id}>
                          {network.name}
                        </MenuItem>
                      );                      
                    }
                  })}
              </Select>

            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <Select
                name="region"
                value={regionName}
                onChange={handleRegionNameChange}
                placeholder="Region Name.."
                size="small"
                fullWidth
                className="textfield"
                displayEmpty
              >
                <MenuItem value="" style={{ color: 'gray' }}>
                  {t("actfilter.rn")}
                </MenuItem>
                {user.network.includes('All')
                  ? regions.map((region) => (
                    <MenuItem key={region.name} value={region.name}>
                      {region.name}
                    </MenuItem>
                  ))
                 : 
                    user.network.map((networkId) => {
                    const network = networks.find((item) => item.id === networkId);
                    if(network && selRegions.indexOf(network.regionId) < 0) {
                      selRegions.push(network.regionId);
                      const region = regions.find((r) => r.regionId === network.regionId);
                      if(region) {
                        return (
                          <MenuItem key={region.name} value={region.name}>
                            {region.name}
                          </MenuItem>
                        );
                      }
                    }
                  })
               }
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="country"
                value={countryName}
                onChange={handleCountryNameChange}
                placeholder={t("actfilter.cn")}
                size="small"
                fullWidth
                className="textfield"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box marginTop={1}>
              <TextField
                name="person"
                value={personName}
                onChange={handlePersonNameChange}
                placeholder={t("actfilter.pn")}
                size="small"
                className="textfield"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>

            <Button sx={{ marginTop: '9px', width: '120px' }}
              variant="contained" color="primary" disableElevation onClick={handleFilter}>
              {/* Filter */}
              {t("activityform.Filter")}
            </Button>
          </Grid>

        </Grid>
        {dialogOpen && <ActivityForm onClose={handleDialogClose} id={id} updateData={handleDataUpdate} onSuccess={fetchData} />}
        <div style={{ height: "calc(100vh - 250px)" }}>
          {/* <DataGrid key={key} rows={filteredData.length > 0 ? filteredData : data} columns={columns} components={{ Toolbar: GridToolbarExport }}/> */}
          <DataGrid
            key={key}
            rows={filteredData.length > 0 ? filteredData : data}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  col9: false,
                  col10: false, col11: false, col12: false, col13: false, col14: false, col15: false, col16: false, col17: false, col18: false,
                  col19: false, col20: false, col21: false, col22: false, col23: false, col24: false, col25: false, col26: false, col27: false,
                  col28: false, col29: false
                },
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </div>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this activity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>No</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ActivityList