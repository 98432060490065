import React, { useContext } from "react";
import { Route, Navigate } from "react-router-dom";
import { UserContext } from "./UserContext";

  export const PrivateRoute = ({ children, isMobile }) => {
    const { token } = useContext(UserContext);
    if (isMobile && isMobile === true) {
      return <Navigate to="/m" />;
    }
    if (!token) {
      return <Navigate to="/" />;
    }
  
    return children;
  };